<template>
<Modal @close="$emit('modalToggle','')">
    <template #header>
        <div>{{data.type=='SELL'?'فروش':'خرید'}} </div>
    </template>
    <template #body>
        <p>سفارش شما به شرح زیر میباشد </p>
        <div class="factor-container">
            <div>فاکتور</div>
            <div class="factor">
                <div><img class="icon" :src="require('../../assets/coins/'+data.destinationCoin+'.png')"/> <div class="crypto-amount"> {{data.amount}} </div>{{$coins[data.destinationCoin].code}}</div>
                <div>قیمت واحد <span class="value">{{$toLocal(data.unitPrice,$decimal[data.destinationCoin])}}</span>{{tradeTo=='TOMAN'?'تومان':'تتر'}}</div>
                <div>مبلغ پرداختی<div class="value">{{$toLocal(totalPrice)}}</div>{{tradeTo=='TOMAN'?'تومان':'تتر'}}</div>
            </div>
        </div>
    
        <button @click="accept()" class="modal-confirm-btn">ثبت سفارش {{data.type=='SELL'?'فروش':'خرید'}}</button>
    </template>
</Modal>
  
    
</template>

<script>
import {
    tradeTo,
    tradeFrom,
} from "@/library/reuseableFunction";
import Modal from './Modal'
export default {
  name: 'Buy',
  components:{
      Modal
  },
  props:['data','limit'],
  data(){
    return{
    }
  },
  computed:{
      'totalPrice':function(){
          return this.$S2N(this.data.unitPrice) * this.$S2N(this.data.amount)
      },
      tradeTo,
      tradeFrom
  },
  methods:{
            async accept(){
                this.loading = true;
                let orderDetail;
                let marketType = this.tradeFrom + '_' + this.tradeTo
                if (this.data.isLimit) {
                    orderDetail = {
                        marketType: marketType,
                        amount: this.$S2N(this.data.amount),
                        orderType: 'LIMITED_'+this.data.type,
                        expiresAt: this.date || null,
                        unitPrice: this.$S2N(this.data.unitPrice),
                        wholePrice: this.$S2N(this.totalPrice),
                    }
                } else {
                    orderDetail = {
                        marketType: marketType,
                        amount: this.$S2N(this.data.amount),
                        orderType: 'MARKET_'+this.data.type,
                        
                    }
                    if(this.data.date){
                        orderDetail.date=this.data.date
                    }
                }
                try {
                    await this.$axios.post(`/orders/${this.data.type.toLowerCase()}`, orderDetail)
                    this.loading = false
                    this.$error('', 'سفارش با موفقیت ثبت شد', 'success')
                    this.$emit('modalToggle','SUCCESS')
                    this.$emit('confirm')
                } catch (error) {
                    this.loading = false
                    this.$emit('modalToggle','')
                    if (error.response.data.message === "the amount of order is bigger than market liquidity") {
                        await this.$error('', 'حجم درخواستی بیش از مقدار بازار است.')
                    }
                }
            },
  }
}
</script>
<style scoped>
.modal-title{
    color: #143B74;
    margin-right: 12px;
}

.factor > div{
    display: flex;
    align-items: center;
    column-gap: 6px;
}
.icon{
    height: 30px;
    width: 30px;
}
.factor{
    padding: 9px 6px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 8px;
    white-space: nowrap;
    justify-content: space-between;
    background: rgba(216, 211, 242, 0.2);
    border-radius: 4px;
    font-size: 13px;
    color: var(--gray3);
}
.number{
font-weight: bold;
}

.crypto-amount{
    color: var(--green);
}
.copy{
    display: flex;
    align-items: center;
    background: #DFF4F4;
    height: 100%;
    justify-content: center;
    border-radius: 4px 0px 0px 4px;
    padding: 0px 8px;
}

.toggle-comp{
    flex-grow: 1;
    max-width: 194px;
}
.value{
    color: var(--gray4);
    font-weight: bold;
}

.crypto-network{
    display: flex;
    align-items: center;
    font-size: 12px;
    column-gap: 20px;
}
.wallet-address{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    background: #F7F7F7;
    font-size: 12px;
border-radius: 4px;
}

.address-label{
    color: var(--gray5);
}
.address-value{
    color: var(--gray4);
    font-weight: bold;
}

.alert{
    font-size: 14px;
    color: black;
    margin-top: 14px;
}
.description{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    column-gap: 35px;
    font-size: 14px;

}

::v-deep .modal-card{
    max-width: 602px;
}
</style>