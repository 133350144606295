<template>
  <div class="footer">
      <div class="contacts">
          <div class="logo">
          <img  src='../../../assets/icons/bodex.svg'/>

          </div>
          <div class="apps-icon">
              <img src='../../../assets/icons/telegram.svg'/>
              <img src='../../../assets/icons/instagram.svg'/>
              <img src='../../../assets/icons/twiter.svg'/>
              <img src='../../../assets/icons/whatsapp.svg'/>
          </div>

      </div>

      <div class="link-menus">
      <div class="menu">
          <div class="head">راهنما</div>
          <div class="link" @click="$router.push(link.path)" v-for="(link,index) in guideLinks" :key='index'>{{link.label}}</div>

      </div>
      <div class="menu">
          <div class="head">دسترسی سریع</div>
          <div class='link' @click="$router.push(link.path)" v-for="(link,index) in accessLinks" :key='index'>{{link.label}}</div>

      </div>
      <div class="menu">
          <div class="head">تماس با ما</div>
          <div class='link'>{{info.address}}</div>
          <div class='link'>{{info.number}}</div>
          <div class='link'>{{info.email}}</div>

      </div>
      </div>
   

  </div>
    
</template>

<script>
export default {
  name: 'HelloWorld',
  data(){
    return{
        accessLinks:[
            {label:'اخبار',path:''},
            {label:'آموزش',path:''},
            {label:'کارمزد',path:'Commissions'},
            {label:'درباره ما',path:'AboutUs'},

        ],  
         guideLinks:[
            {label:'راهنمای استفاده',path:'FAQ'},
            {label:'سولات متداول',path:'FAQ'},
            {label:'قوانین و مقررات ',path:'Terms'},
            {label:'پشتیبانی',path:''},

        ],
        info:{}
      
    }
  },
  methods:{
          async getInfo() {
            const res = await this.$axios.get('/exchange-info')
            this.info = res.baseDTO || {
                address: '',
                appAndroid: '',
                appIOS: '',
                email: '',
                instagram: '',
                linkedin: '',
                number: '',
                telegram: '',
                twitter: ''
            }
        },

  },
  mounted(){
      this.getInfo()
  }
}
</script>
<style scoped>
.footer{
    display: flex;
    flex-wrap: wrap;
    column-gap: 70px;
    row-gap: 30px;
    min-width: 256px;
    background: linear-gradient(183.05deg, #123B77 -1.99%, #223459 31.28%, #00171F 105.75%);
    width: 100%;
    padding: 39px;

}

.logo{
    display: flex;
    justify-content: center;
   flex-grow: 1;
}

.logo > img{
    width: 100%;
    max-width: 400px;
    height: auto;
}
.link-menus{
    display: flex;
    flex-wrap: wrap;
    flex-grow: 6;
    column-gap: 20px;
    row-gap: 20px;
    justify-content: space-between;
    font-size: clamp(12px,1.4vw,14px);

}
.head{
    font-weight: bold;
    color: white;
}
.contacts{
    display: flex;
    row-gap: 30px;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
}
.link{
    color: #F2F2F2;
    cursor: pointer;
}
.menu{
    display: flex;
    flex-direction: column;
    row-gap: 12px;

}

.apps-icon{
    display: flex;
    justify-content: space-between;
}
</style>