<template>
<div class="filter-container">
    <div class="crypto-search">
        <div class="crypto-filter" @click="dropShow=!dropShow">
            <label class="label">رمزارز</label>
            <input placeholder="جستجو ..." :value="cryptoName " :disabled="true" @input="(e)=>regex=e.target.value" />
            <img :class="{'drop-open':dropShow}" src='../../assets/icons/arrowdown.svg' />
        </div>
        <div v-if="dropShow" class="line" />

        <div name="slide" class="dropdown" v-if="dropShow">
            <div class="row" v-for="(crypto,index) in getCrypto()" :key="index" @click="select(crypto)">
                <img class='icon' :src="require('../../assets/coins/'+crypto.destCoinSymbol+'.png')" />
                <div>{{$coins[crypto.destCoinSymbol].persianName}}</div>
                <div  class="buy-price"> {{$toLocal(crypto.buyPrice,0)}} تومان</div>
                <div class="sell-price"> {{$toLocal(crypto.sellPrice,0)}} تومان</div>
            </div>
        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'CryptoDropdown',
    props: ['cryptos'],
    data() {
        return {
            dropShow: false,
            cryptoName: 'بیتکوین',

            regex: "",
        }
    },
    methods: {
        select(crypto) {
            this.dropShow = false;
            this.$emit('chooseCrypto', crypto)
            console.log(crypto)
            this.cryptoName = this.$coins[crypto.destCoinSymbol].persianName
        },

        getCrypto() {
            return this.cryptos.filter((crypto) => {
                if ((this.$coins[crypto.destCoinSymbol].persianName.includes(this.regex) || this.$coins[crypto.destCoinSymbol].code.includes(this.regex.toUpperCase()) || crypto.destCoinSymbol.includes(this.regex.toUpperCase()))) {
                    return crypto
                }
            })

        }

    },
    mounted() {
        this.alive = true
    },
}
</script>

<style scoped>
.filter-container {
    position: relative;
    width: 100%;
    height: 40px;
}

.icon {
    width: 24px;
    height: 24px;
}

.row {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 5fr 5fr 5fr;
    padding: 8px 8px 8px 0px;
    column-gap: 4px;
    font-size: clamp(11px, 1.2vw, 14px);
    cursor: pointer;
    /* column-gap: 16px; */

    /* padding: 7px 20px 7px 16px; */
}

.sell-price {
    color: #CA2F4B;
}

.buy-price {
    color: #03C70B;
}

.drop-open {
    transform: rotate(180deg);
}

.dropdown {
    transition: height .4s ease-in-out;
    /* border:1px solid #D1E4E4; */
    /* position: absolute; */
    border-radius: 0px 0px 8px 8px;
    background: #FAFCFF;
    padding-bottom: 25px;
    max-height: 206px;
    overflow: auto;

}

.crypto-search {
    background: #FAFCFF;
    border-radius: 8px;
    padding: 0px 16px;
    width: 100%;
    z-index: 5;

    position: absolute;

    border: 1px solid #D1E4E4;

}

.label {
    color: var(--gray3);
    font-size: clamp(12px, 1.1vw, 14px);
    padding-left: 3px;
}

.crypto-filter {
    display: flex;
    align-items: center;
    cursor: pointer;

}

.line {
    width: 100%;
    border-radius: 2px;
    height: 2px;
    background: #D1E4E4;

}

input {
    background: #FAFCFF;
    font-weight: bold;
    font-size: clamp(15px, 1.6vw, 18px);
    height: 40px;
    flex-grow: 1;
}

.slide-enter,
.slide-leave-to {
    height: 0px;
}
</style>
