<template>
<div>
    <div class="page-header">سوالات متداول</div>
    <div class="questions">
        <div v-for="(question,index) in questions" :key="index" class="question" @click="openedIndex=(openedIndex==index)?null:index" >
            <div class="question-row" >
                <span>{{question.question}}</span>
                <img :class="{'drop-open':openedIndex==index}" src='../../assets/icons/arrowdown.svg'/>
            </div>
            <div v-if="openedIndex==index" class="answer">
                <div>{{question.answer}}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'FAQ',
  data(){
    return{
        openedIndex:null,
        questions:[]
     
      
    }
  },
  mounted(){
      this.getFaq()
      console.log(this.questions)

  },
  methods:{
     async getFaq(){
            this.questions=await this.$axios.get('faq').then(response => this.questions=response)
            
      }
  
  }
}
</script>
<style scoped>

.question{
background: #FCFCFC;
box-shadow: 2px 2px 9px rgba(186, 185, 185, 0.27);
border-radius: 6px;
width: 100%;
}

.question:hover{
    background: #E9EBFF;

}
.questions{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.answer{
    font-size: 14px;
   padding: 0px clamp(18px,2.1vw,24px) 10px clamp(18px,2.1vw,24px);
   color: #504B4B;
}

.drop-open{
    transform: rotate(180deg);
}

.question-row{
    display: flex;
    justify-content: space-between;
    padding: clamp(18px,2.1vw,24px);
    font-size:14px;
    color: #222222;
}
</style>