<template>
  <div class="home">
    <PageHeader/>
    <BodaxNews/>
    <Markets/>
    <Details/>
    <BodexLearning/>
    <Advantages/>
    <FAQ/>

  </div>
    
</template>

<script>
import PageHeader from './PageHeader'
import BodaxNews from './BodaxNews'
import Details from './Details'
import BodexLearning from './BodexLearning'
import Advantages from './Advantages'
import FAQ from './FAQ'
import Markets from './Markets'
export default {
  name: 'HomePage',
  components:{
    PageHeader,
    BodaxNews,
    Details,
    BodexLearning,
    Advantages,
    FAQ,
    Markets
  },
  data(){
    return{
      
    }
  }
}
</script>
<style scoped>

.home{
  padding-top: 144px;
}
</style>