<template>
  <div>
      <div class="page-title">تاریخچه سفارشات</div>
        <div class="filters">
      <div class="toggle-comp">
        <ToggleComp  @choose="(choice)=>{tableType=choice}" :items="[{label:'سفارش سریع',value:'EXCHANGE'},{label:'سفارشات بازار',value:'P2P'}]"/>
      </div>

      <FilterDrop @choose="(choice)=>tradeType=choice.key" :items="[{key:'',label:'خرید و فروش'},{key:'SELL',label:'فروش'},{key:'BUY',label:'خرید'}]"/>


      </div>
    <div class="table-container">
        <div class="local-loading" v-if="loading">
            <div class="super-spinner"/>
        </div> 
    <perfect-scrollbar @ps-y-reach-end="check" ref="scroll">
     <table>
            <tr class="header">
                <td class="resp-1">زمان</td>
                <td>نوع ارز</td>
                <td>نوع سفارش</td>
                <td class="resp-2">قیمت واحد</td>
                <td>مقدار</td>
                <td>قیمت کل</td>
                <td class="resp-1" v-if="tableType=='EXCHANGE'">شناسه  تراکنش</td>
                <td class="resp-2" v-if="tableType=='P2P'"> انجام شده</td>
                <td class="resp-1">وضعیت</td>

            </tr> 
            <tr class="row" v-for="(order,index) in orders" :key='index'>
                <td class="resp-1">{{$G2J(order.createdAtDateTime)}}</td>
                <td>{{$coins[order.marketType.split('_')[0]].persianName}}</td>
                <td :class="{red:order.orderType.includes('SELL'),green:order.orderType.includes('BUY')}">{{order.orderType.includes('BUY')?'خرید':'فروش'}}{{tradeTypeDict(order.orderType)}}</td>
                <td class="resp-2">{{$toLocal(order.unitPrice,0)}}&nbsp;&nbsp;{{$coins[order.marketType.split('_')[1]].persianName}}</td>
                <td > <span dir='ltr'>{{order.amount}}&nbsp;{{order.tokenType}}</span></td>
                <td>{{$toLocal(order.wholePrice,0)}}&nbsp;&nbsp;{{$coins[order.marketType.split('_')[1]].persianName}}</td>
                <td class="resp-1" v-if="tableType=='EXCHANGE'">{{order.hashId}}</td>
                <td class="resp-2" v-if="tableType=='P2P'">{{order.executedPercent}}</td>
                <td class="resp-1"  :style="{color:statusColor(order.orderStatus)}">{{statusLabel(order.orderStatus)}}</td>
            </tr>
        </table>  
    </perfect-scrollbar>
    </div> 
  </div>
    
</template>

<script>
import FilterDrop from './FilterDrop'
import {
    statusLabel,statusColor

} from "@/library/reuseableFunction";
export default {
  name: 'Orders',
   components:{   
         FilterDrop,
  },
  
  data(){
    return{
       dataSet:[],
       tableType:"EXCHANGE",      
       tradeType:"",
       lastPage:1,
       page:0,
       loading:false

      
    }
  },
  computed:{
    orders(){
        if(this.dataSet)
             return this.dataSet.filter(data=>data.orderType.includes(this.tradeType) &&
            (data.orderType.includes('EXCHANGE'))===(this.tableType=='EXCHANGE'))
        return []
    },
    statusLabel,statusColor

  },
  
   methods:{
      tradeTypeDict(type){
        return  type.includes('EXCHANGE')?'':type.includes('MARKET')?'-بازار':'-حد'
    }, 
       async check() {
            if (this.page<=this.lastPage){
                this.page ++
                this.loading = true
                const res = await this.$axios.get('/orders', {
                    params: {
                        // size: 5,
                        // page: this.page,
                        type:'EXCHANGE'
                    }
                })
                this.lastPage = res.totalPages
                this.dataSet = [...this.dataSet, ...res.content]
                this.dataSet=this.dataSet.filter(item=>item.orderStatus!='IS_OPEN')
                }
                this.loading=false
                } ,
       
    filterItems(){
      return this.dataSet.filter(data=>data.orderType.includes(this.tradeType) &&  
      (data.orderType.includes('EXCHANGE'))===(this.tableType=='EXCHANGE'))
      
    }
            },
  
  
        mounted(){
            this.check()
        }
 
}
</script>
<style scoped>

.local-loading{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.4);
    z-index: 1;
}
.super-spinner {
    box-sizing: border-box;
    position: relative;
    margin: auto;
    width: 90px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 10px solid transparent;
    border-top-color: #FFE976;
    border-bottom-color: #AF9A28;
    transform: rotateZ(-45deg);
    animation: super-spinner 5s linear infinite;
    z-index: 2;
}

.super-spinner::after {
    box-sizing: border-box;
    content: "";
    position: absolute;
    inset: -10px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 10px solid transparent;
    border-right-color: #FFF3B6;
    border-left-color: #857000;
    animation: super-spinner 5s linear infinite;
}


@keyframes super-spinner {
    0% {
        transform: rotateZ(0deg);
    }

    25% {
        transform: rotateZ(360deg);
    }

    75% {
        transform: rotateZ(720deg);
    }

    100% {
        transform: rotateZ(1080deg);
    }
}
.toggle-comp{
  max-width: 380px;
  flex-grow: 1;
  margin-top: 12px;

}
.table-container{
    height: 200px;
    width: 100%;
    position: relative;
    display: flex;
    overflow-y:scroll;
}
.filters{
 display: flex;
 align-items: center;
 justify-content: space-between;
}


.footer-table{
    padding-bottom: 50px;
}
.cancel-btn{
    color: red;
    cursor: pointer;

}
table{
    width: 100%;
}
.row{
    font-size: clamp(12px,1.3vw,14px);
    color: #828282;

}

.red{
    color:#CA2F4B
}
.header{
    position: sticky;
    top: 0;
    height: 40px; 
    font-weight: bold;
    font-size: clamp(12px,1.3vw,14px);
    color: #828282;
    background: white;

}
.green{
    color: #03C70B;
}
@media(max-width:1000px){
    .resp-1{
        display: none;
    }
}
@media(max-width:550px){
    .resp-2{
        display: none;
    }
}

@media(max-width:400px){
    .resp-3{
        display: none;
    }
}
</style>