<template>
    <div class="trade-comp" >
        <div class="toggle-comp">
              <ToggleComp  @choose="(choice)=>{marketType=choice}" :items="[{label:'حد',value:'LIMIT'},{label:'بازار',value:'MARKET'}]"/> </div>
        <div class="trade-section"  :key="marketType">
            <TradeComp :isBuy="true" :shopInfo="shopInfo"  :tableContents="tableContentsSell" :crypto="crypto" :marketType="marketType" :wallets="wallets" />
            <TradeComp  :isBuy="false"  :shopInfo="shopInfo"  :tableContents="tableContentsBuy" :crypto="crypto" :marketType="marketType" :wallets="wallets"  />
        </div>    

    </div>
  
    
</template>

<script>
import TradeComp from './TradeComp'
import {
    Loop,
    tradeTo,
    tradeFrom
} from "@/library/reuseableFunction";

export default {
  name: 'BuySellSection',
  components:{
      TradeComp
  },
  props:{
      crypto:Object,
    
  },
  computed:{
     tradeTo,
     tradeFrom
    
  },
  methods:{
        
 
      filterOrders(filter){
           return this.orders.filter(order => {
               if(order.tradeType==filter)
                  return order
           })
      },
         async getWallet() {
            this.state.loading = true
             const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=P2P')
            if (res) {
                this.wallets = res
            }
            this.loading = false
        },

         async getMarketDepth() {

            const [res, ] = await this.$http.get('/orders/order-book', {
                params: {
                    marketType: this.tradeFrom + '_' + this.tradeTo
                }
            })
        

            if (res) {
                this.tableContentsSell = res.sellOrderBook.slice(0, 11).sort((a , b) =>{a.unitPrice > b.unitPrice})
                this.tableContentsBuy = res.buyOrderBook.slice(0, 11).sort((a , b) =>{return a.unitPrice > b.unitPrice})
                this.shopInfo.minSell = res.sellOrderBook.length ? res.sellOrderBook[0].unitPrice : 0
                this.shopInfo.maxBuy = res.buyOrderBook.length ? res.buyOrderBook[0].unitPrice : 0
            }
            this.loading = false
        },
        isLoaded(){
            return this.wallets && this.crypto && this.tableContentsBuy && this.tableContentsSell
        }
},
  data(){
    return{
      marketType:"LIMIT",
      baseCurrencyDict:{"TOMAN":"تومان","TETHER":"تتر"},
      tableContentsBuy:[],
      tableContentsSell:[],
      shopInfo:{minSell:0,maxBuy:0},
      wallets:{},

    }
  },
  
  mounted(){
      this.getWallet()
         this.loading = true
        if(this.crypto){
            if (this.state.loop) {
                this.loop = new Loop(this.getMarketDepth, this.state.time, 'marketDepth:' + this.tradeFrom + '_' + this.tradeTo)
                this.loop.start()
            } else {
                this.getMarketDepth()
            }
        }
        
  },
  beforeDestroy(){
      this.loop.stop()
  }
}
</script>
<style scoped>
.table-title{
    color: var(--gray2);
    font-weight: bold;
    font-size: 14px;
    padding: 40px 0px 12px 0px;
}

.error{
    font-size: clamp(12px,1.3vw,14px);
    color: red;
}

.table-header>div:last-child , .row-grid-container> div:last-child{
    text-align: left;
}
.table-header>div:nth-child(2) ,.row-grid-container > div:nth-child(2) {
    text-align: center;
}
.table-card{
    flex-grow: 1;
}
.tables{
    display: flex;
    column-gap: 48px;
    flex-wrap: wrap;
    justify-content: space-between;
}


.table-header{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: 12px;
    color:var(--gray3)
}

.row-grid-container> div:nth-child(2) {
    font-weight: bold;
}

.table-row{
    position: relative;
    margin-top: 1px;
    display: flex;

    /* padding: 1px 0px; */
    /* display: flex; */
    align-items: center;
    height: 24px;
    font-size: 12px;
    color: var(--gray2);
  
}

.row-grid-container{
      display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    flex-grow: 1;
    padding:0px 4px;
    /* border:1px solid; */

}

.row-grid-container > div{
       z-index: 1;
}

.table-fill-red{
      background: rgba(250, 50, 86, 0.23);
box-shadow: inset 13.6px -13.6px 13.6px rgba(222, 170, 179, 0.1), inset -13.6px 13.6px 13.6px rgba(255, 255, 255, 0.1);
backdrop-filter: blur(27.2px);
border-radius: 4px;
height: 100%;
position: absolute;
    z-index: 0;

left: 0px;
}

.table-fill-green{
    height: 24px;
    background: rgba(3, 199, 11, 0.3);

box-shadow: inset 13.6px -13.6px 13.6px rgba(222, 170, 179, 0.1), inset -13.6px 13.6px 13.6px rgba(255, 255, 255, 0.1);
backdrop-filter: blur(27.2px);
position: absolute;
    z-index: 0;
border-radius: 4px;

height: 100%;


right: 0px;
}

.depsoit-slider{
    display:flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap:10px;
    width: 100%;
    justify-content: space-between;
}
.trade-section{
    display: flex;
    column-gap: clamp(15px,2.7vw,48px);;
    row-gap:60px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.trade-section > div{
    flex: 1;
    flex-basis: 280px;
}

.trade-card{
    flex: 1;
    display: flex;
    padding-top: 12px;
    
    flex-direction: column;
    row-gap: 10px;
}

.toggle-comp{
    max-width: 247px;

}

.trade-comp{
    padding: 16px 24px;

}


.depsoit{
    font-size:12px;
    column-gap: 3px;
    display: flex;
    color: var(--gray3);
}

.depsoit-value{
    color: var(--primary);
    font-size: 14px;
    font-weight: bold;
}

.limit{
    display: flex;
    column-gap: 3px;
    font-size:12px;
    color: var(--gray3);
}

.limit-value{
     color: var(--gray6);
    font-size: 14px;
    font-weight: bold;
}


.sum{
    color: var(--gray2);
    font-size: 14px;
    background: #EDF1ED;
    padding: 8px 16px;
    font-weight: bold;
}

.btn{
    width: 100%;
    height: 40px;
    color: white;
    border-radius: 4px;
}

.green{
    background: var(--green);
}
.red{
    background: var(--red);
}
.sum-commission{
    font-size: clamp(10px,1.1vw,12px);
    font-weight: normal;
    margin-top: 8px;
}
.sum-commission-value{
    color: var(--gray4);
    margin-right: 8px;
}   
</style>