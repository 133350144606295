<template>
<div>
    <div class="page-header">اخبار بدکس</div>
    <div class="carousel">

        <hooper class="carousel" :infiniteScroll="true" :progress="true" :autoPlay="true" :playSpeed="1500" :rtl="true" :settings="hooperSettings">
            <slide v-for="(post,index) in news " :key='index'>
                <div class="container">
                    <div class="post">
                        <div class="title">{{post.title}}</div>
                        <div class="category">{{post.category}}</div>
                        <img class="bg" src='../../../assets/public/news-post.png' />
                    </div>
                </div>
            </slide>
        </hooper>

    </div>
</div>
</template>

<script>
import {
    Hooper,
    Slide
} from 'hooper';
import 'hooper/dist/hooper.css';
export default {
    name: 'BodaxNews',
    components: {
        Hooper,
        Slide
    },
    data() {
        return {

            news: [{
                    title: 'رشد بیت کوین در اکتبر ',
                    category: 'رمز ارزها'
                },
                {
                    title: 'رشد بیت کوین در اکتبر ',
                    category: 'رمز ارزها'
                },
                {
                    title: 'رشد بیت کوین در اکتبر ',
                    category: 'رمز ارزها'
                },
                {
                    title: 'رشد بیت کوین در اکتبر ',
                    category: 'رمز ارزها'
                },
                {
                    title: 'رشد بیت کوین در اکتبر ',
                    category: 'رمز ارزها'
                },
            ],

            hooperSettings: {
                itemsToShow: 2,
                centerMode: false,

                breakpoints: {
                    200: {
                        centerMode: true,
                        itemsToShow: 1
                    },
                    600: {
                        centerMode: true,

                        itemsToShow: 2
                    },
                    890: {
                        itemsToShow: 3
                    },
                    1230: {

                        itemsToShow: 4,

                    },

                }
            }
        }
    }
}
</script>

<style scoped>
.post {
    background: #D2B4C2;
    border-radius: 8px;
    overflow: hidden;
    height: 168px;
    position: relative;
    width: 100%;

}

.title {
    color: white;
    background: rgba(210, 180, 194, 0.1);
    opacity: 0.5;
    box-shadow: inset 8.06667px -8.06667px 8.06667px rgba(160, 137, 147, 0.1), inset -8.06667px 8.06667px 8.06667px rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(16.1333px);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 144px;
    width: 296px;
    margin-right: -50px;
    margin-top: -30px;
    border-radius: 50%;
    z-index: 4;
    font-size: clamp(14px, 1.5, 18px);

}

.category {
    position: absolute;
    top: 8px;
    left: 8px;
    background: rgba(247, 175, 253, 0.6);
    box-shadow: inset 4.1px -4.1px 4.1px rgba(164, 160, 184, 0.1), inset -4.1px 4.1px 4.1px rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8.2px);
    border-radius: 31px;
    color: #143B74;
    font-size: 12px;
    width: 64px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    padding: 0px 8px;
}

.carousel {
    display: flex;
    width: 100%;
}

.bg {
    position: absolute;
    bottom: 0px;
    left: 0px;
}
</style>
