<template>
<div class="container" @click="disabled?'':$emit('click')" :class="{disabled:disabled}">
          <div class="btn" :style="{color:color}" >
            <slot></slot>
            <div :style="{background:color}" class='line'/>
            </div>

</div>
</template>

<script>
export default {
  name: 'UnderlinedBtn',
  props:{
      color:String,
      disabled:Boolean
  }
}
</script>
<style scoped>

.btn{
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    flex-direction: column;
    font-size: clamp(13px,1.4vw,16px);
}
.line{
    height:1.8px ;
    width: 0px;
    transition: width 0.2s;
}
  .btn:hover .line{
    width: 100%;
  }

  .container{
      display: flex;
      justify-content: center;
  }
  .disabled{
    opacity: 0.5;
  }
 
</style>