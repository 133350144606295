<template>
<div>
    <div class="settings-page">
        <ChangePassword/>
        <VerifyMethod/>
    </div>
    <div>
        <div class="section-title"> تاریخچه ورود به سایت </div>
        
    <Table   :dataSet="dataSet"
    :columns="[{name:'loginRequestedDateTime',label:'زمان'},headers.ipAddress,headers.deviceType,headers.osType,headers.browser,{label:'وضعیت',name:'isSuccessful'}] "/>
    </div>
</div>

</template>

<script  >
import ChangePassword from './ChangePassword'
import VerifyMethod from './VerifyMethod'
import variables from '../../../library/variables.json'
import Table from '../History/Table'
export default {
  name: 'Settings',
  components:{
      ChangePassword,VerifyMethod,Table
  },
  methods:{ 
  
   
     async check() {
                // console.log(this.page,this.lastPage);
                // if (this.page<=this.lastPage){ //for scroll loading
                    // this.page ++ //for scroll loading
                    const r = await this.$axios("/users/account?imageNeeded=false")
                     this.verifyMethod = r.baseDTO.towStepAuthenticationType
                     console.log(this.verifyMethod)
                    this.state.loading = true
                    const res = await this.$axios.get('/users/login-history', {
                        params:{
                            size : 10,
                            page : this.page
                        }
                    })
                    this.lastPage = res.totalPages
                    this.dataSet = [...this.dataSet, ...res.content]
                    this.dataSet.map(data=>{
                    if( data.isSuccessful==true){
                       data.isSuccessful='موفق'
                    }
                    else{
                       data.isSuccessful='ناموفق'
                    }
                    data.deviceType=this.deviceDict[data.deviceType]
                    data.loginRequestedDateTime=this.$G2J(data.loginRequestedDateTime)
                    })
                   
                    
                // }
            },
            load() {
                if(this.page<=this.lastPage){
                    setTimeout(() => {
                    this.check()
                }, 1000)}
            }
  },
   mounted() {
            this.check()
        },
    
  data(){
    return{
         deviceDict:{"MOBILE":"موبایل","DESKTOP":"دسکتاپ"},
         dataSet:[],
         headers:variables.headers,
    
    }
  },
 
}
</script>
<style lang="scss" scoped>


.settings-page{
    display: flex;
    flex-wrap: wrap;
   
    padding-bottom: 50px;
    column-gap: 50px;
.statics{
    max-width: 830px;
    padding-right: 24px;
    font-size: clamp(12px,1.3vw,16px);
  
}    


}


</style>