<template>
<div class="fast-order">
    <TradeComp :cryptos="cryptos" :coin="crypto" :tomanBalance="tomanBalance"/>
    <CoinsTable :cryptos="cryptos" @selectCoin="value=>{crypto=value;setCrypto()}" />
</div>
</template>

<script>
import TradeComp from './TradeComp'
import CoinsTable from './CoinsTable'
export default {
    name: 'FastOrder',
    components: {
        TradeComp,
        CoinsTable
    },
    computed: {
        tomanBalance() {
            if (this.wallets['TOMAN']) {
                 const temp = this.wallets['TOMAN']
                  return temp.credit
            }
            return 0
        },
    },
    data() {
        return {
            cryptos: [],
            crypto: {},
            wallets:{},
            coinsDetails:[]

        }
    },
    methods: {
        async getCoinsPrice() {
            await this.$axios.get('coins/exchange-price/usd', {
                params: {
                    includeWeeklyChange: false
                }
            }).then(response => this.cryptos = response)
            if (this.cryptos)
                this.crypto = this.cryptos[0]
        },
        async getWallets() {
            this.state.loading = true
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE')
            if (res) {
                this.wallets = res
            }
            this.loading = false
        },
        async getCoinsDetails() {
            this.state.loading = true
            const res = await this.$axios.get('/coins')
            this.coinsDetails = res.content
            if (this.cryptos) {
                this.crypto = this.cryptos[0]
                if (this.crypto &&  this.coinsDetails)
                    this.setCrypto()
            }
        },

        setCrypto() {
            this.crypto.minAmountExchange = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).minAmountExchange
            this.crypto.maxAmountExchange = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).maxAmountExchange
            if(this.state.userInfo.authorized)
                 this.crypto.credit = this.wallets[this.crypto.destCoinSymbol].credit
            this.crypto.nets = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).tokenTypeDetails
            this.net = this.crypto.nets[0].tokenType
            this.crypto.exchangeFee = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).exchangeFee
        },
    },
    async mounted() {
        await this.getCoinsPrice()
        if(this.state.userInfo.authorized)
             await this.getWallets()
        await this.getCoinsDetails()
    }
}
</script>

<style scoped>
.fast-order {
    display: flex;
    padding-top: clamp(110px, 12vw, 130px);
    flex-wrap: wrap;
    column-gap: 30px;

}
</style>
