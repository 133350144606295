<template>
<div class="dropdown-container">
    <div class="dropdown-comp" @click="dropOpen=!dropOpen" >
        <div class="label">
            <div>{{net.tokenType}}</div>
            <img v-if="items.length>1" :class="{'drop-icon':true,'drop-open':dropOpen}" src='../../assets/icons/arrowdown.svg'/>
        </div>
        <div class="drop-down" :style="{height:dropOpen?(items.length-1)*20+'px':0}">
            <div class="option" v-for='(item,index) in items.filter(network => net.tokenType!=network.tokenType)' 
                :key="index" @click="select(index)">
                {{item.tokenType}}
            </div>
        </div>
    </div>
</div>
    
</template>

<script>
export default {
  name: 'NetworkDropdown',
  props:['items'],
  computed:{
      
  },
  data(){
      return {
          net:this.items[0],
          dropOpen:false
      }
  }
    ,methods:{
        select(index){
          this.net=this.items[index]
          this.$emit('select',this.net)
        }
    }
}
</script>
<style scoped>
.label{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0  6px;

}
.option{
     padding:0  6px;

}
.option:hover{
    background: rgb(194, 194, 229);
}
.dropdown-comp{
    flex-grow: 1;
    position: absolute;
    width: 100%;
    border: 1px solid gray;
    font-size: clamp(13px,1.4vw,15px);
    border-radius: 5px;
    background: white;
    cursor: pointer;
    z-index: 5;
}

.dropdown-container{
    height:20px;
    position: relative;
    display: flex;
    flex-grow: 1;

}
.drop-icon{
    width: 20px;
}
.drop-down{
    overflow-y: hidden;
    transition: height 1s;
}
.drop-open{
    transform: rotate(180deg);
}
</style>
