<template>
  <div class="side-market">
    <table >
      <tr class="table-header">
          <td>رمزارز</td>
          <td>قیمت({{tradeTo=='TETHER'?'تتر':'تومان'}})</td>
          <td>تقییرات</td>
      </tr>
      <tbody>
        <tr v-for="(coin,index) in cryptos" :key="index" class="table-row coins-table-row">
          <td class="symbol">({{coin.relatedCoin}})</td>
          <td class="price">{{$toLocal(coin.lastPrice,0)}}</td>
          <td  :class="{change:true,red:coin.last24HourChange<0,green:coin.last24HourChange>0}">
            <span v-if="coin.last24HourChange>0">+</span><span v-if="coin.last24HourChange<0">-</span>{{$toLocal($S2N(Math.abs(coin.last24HourChange)),2)}}%</td>

        </tr>
      </tbody>
  </table>
  <div>آخرین معاملات</div>
  <table >
      <tr class="table-header">
          <td>مقدار ({{$coins[tradeFrom].code}})</td>
          <td>قیمت واحد ({{baseCurrencyDict[tradeTo]}})</td>
          <td>زمان</td>
      </tr>
      <tbody>
        <tr v-for="(trade,index) in lastTrades" :key="index" class="table-row trades-table-row">
          <td :class="{red:trade.orderType.includes('SELL'),green:trade.orderType.includes('BUY')}">{{$toLocal(trade.amount)}}</td>
          <td>{{$toLocal(trade.unitPrice,0)}}</td>
          <td>{{$G2J(trade.createdAtDateTime)}}</td>
        </tr>
      </tbody>
  </table>
  </div>
    
</template>

<script>
// import axios from 'axios'
import {
    tradeTo,
    tradeFrom,
} from "@/library/reuseableFunction";
export default {
  name: 'SideMarket',
  props:{
      
      cryptos:Array,
  },
  //  watch: {
  //           'sourceCoin'() {
  //               this.setCryptos();
  //           }
  //       },
  data(){
    return{
      loading: false,
      alive: true,
      lastTrades:[],
      baseCurrencyDict:{"TOMAN":"تومان","TETHER":"تتر"},

    }
  },
  computed:{
     tradeTo,
    tradeFrom,
  },
  mounted(){
    // this.getCryptos()
    // this.setCryptos()
       this.alive = true
        if (this.state.loop) {
            this.loop()
        } else {
            this.getLastTrades()
        }
  },
   beforeDestroy(){
            this.alive=false
        },
  methods:{
        async getLastTrades() {
            const res = await this.$axios.get('/trades/last-trades?type=P2P', {
                params: {
                    marketType: this.tradeFrom + '_' +this.tradeTo  ,
                    size: 18
                }
            })
            this.lastTrades = res.content
            this.loading = false
        },
        async loop() {
            // this.state.loading = true
            this.loading = true

            while (this.alive) {
                await this.getLastTrades()
                this.loading = false
                await this.$sleep(5000)
            }
        },
       
      //  async getCryptos() {
      //           let res = await axios.get('https://vistadev.xyz/api/markets/',).then(response=>res=response.data.content)                   
      //           if (res) {
      //               this.cryptos = res
      //           }
      //           this.setCryptos()
      //       },
      //       setCryptos(){
              
      //           this.filteredCryptos=this.cryptos.filter(item=>{
      //               if (item.sourceCoin==this.sourceCoin)
      //                       return item
      //               })
      //                console.log(this.filteredCryptos)

      //       },

  }
}
</script>
<style scoped>
.side-market{
    max-width: 312px;
   flex-grow: 1;
}

table{
    width: 100%;
    font-size: clamp(14px,1.5vw,16px);
}

.coins-table-row > td{
  padding: 8px 0px;

}

.coins-table-row:first-child > td{
  padding-top: 13px;
}

.trades-table-row > td{
  padding: 4px 0px;
}

td:last-child{
    text-align: left;
}

.red{
    color: var(--red);
}
.green{
    color: var(--green);
}

.change{
    direction: ltr;
}

.table-header{
    color: var(--gray3);
    font-size: 14px;
}

.symbol{
    color: var(--primary);
}

.price{
    color:#5A5A5A;
    font-size: 14px;
}
</style>