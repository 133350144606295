<template>
<Modal @close="$emit('modalToggle','')">
    <template #header>
        <div v-if='data.destinationWallet'>خرید به کیف پول دیگر</div>
        <div v-else> خرید به کیف پول بدکس</div>
    </template>
    <template #body>
        <div class="description">
                <div>سفارش شما به شرح زیر است در صورت اطمینان,سفارش خود را ثبت کنید </div>
        </div>
        <div class="factor-container">
            <div>فاکتور</div>
            <div class="factor">
                <div><img class="icon" :src="require('../../assets/coins/'+data.destinationCoin+'.png')"/> <div class="crypto-amount">{{data.amount}} </div>{{$coins[data.destinationCoin].code}}</div>
                <div>قیمت واحد <span class="value">{{$toLocal(data.unitPrice,0)}}</span>{{$coins[data.baseCoin].persianName}}</div>
                <div>مبلغ پرداختی<div class="value">{{$toLocal(data.totalPrice,0)}}</div>{{$coins[data.baseCoin].persianName}}</div>
            </div>
        </div>
        <div v-if="data.destinationWallet" class="alert">لطفا توجه فرمایید که کیف پول مبدا، شبکه ی انتخابی شما را پشتیبانی کند.</div>
        <div v-if="data.destinationWallet" class="crypto-network">
            <span>شبکه انتقال ارز</span>
            <div>
                {{data.tokenType}}
            </div>
            <!-- <div class="toggle-comp">
                <ToggleComp @choose="(choice)=>{cryptoNetwork=choice}" :items="[{label:'TRC20',value:'TRC20'},{label:'ERC20',value:'ERC20'}]"/>   

            </div> -->
        </div>
        <LabelInput v-if="data.destinationWallet" :value='data.destinationWallet' :disabled="true" label="آدرس کیف پول"/>
        <button @click="$emit('confirm')" class="modal-confirm-btn">ثبت سفارش خرید</button>
    </template>
</Modal>
  
    
</template>

<script>
import Modal from './Modal'
export default {
  name: 'Buy',
  components:{
      Modal
  },
  props:['data'],
  data(){
    return{
        crypto:'',
        
      
    }
  },
  methods:{
         async getCoinsPrice() {
                 await this.$axios.get('coins/exchange-price/usd',{
                      params:{
                        includeWeeklyChange:false
                      }
                    }).then(response=>this.cryptos=response)  

              

            },
  }
}
</script>
<style scoped>
.modal-title{
    color: #143B74;
    margin-right: 12px;
}

.factor > div{
    display: flex;
    align-items: center;
    column-gap: 6px;
}
.icon{
    height: 30px;
    width: 30px;
}
.factor{
    padding: 9px 6px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 8px;
    white-space: nowrap;
    justify-content: space-between;
    background: rgba(216, 211, 242, 0.2);
    border-radius: 4px;
    font-size: 13px;
    color: var(--gray3);
}
.number{
font-weight: bold;
}

.crypto-amount{
    color: var(--green);
}
.copy{
    display: flex;
    align-items: center;
    background: #DFF4F4;
    height: 100%;
    justify-content: center;
    border-radius: 4px 0px 0px 4px;
    padding: 0px 8px;
}

.toggle-comp{
    flex-grow: 1;
    max-width: 194px;
}
.value{
    color: var(--gray4);
    font-weight: bold;
}

.crypto-network{
    display: flex;
    align-items: center;
    font-size: 12px;
    column-gap: 20px;
}
.wallet-address{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    background: #F7F7F7;
    font-size: 12px;
border-radius: 4px;
}

.address-label{
    color: var(--gray5);
}
.address-value{
    color: var(--gray4);
    font-weight: bold;
}

.alert{
    font-size: 14px;
    color: black;
    margin-top: 14px;
}
.description{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    column-gap: 35px;
    font-size: 14px;

}

::v-deep .modal-card{
    max-width: 602px;
}
</style>