<template>
    
    <div class="footer-table">
        <div  class="toggle-comp">
            <ToggleComp  @choose="(choice)=>{isActive=choice}" :items="[{label:'سفارشات باز',value:true},{label:'تاریخچه سفارشات',value:false}]"/>
        </div>
        <!-- <Table v-if="ordersType=='ActiveOrders'"   :dataSet="dataSet.filter(order=>order.orderStatus === 'IS_OPEN')"
            :columns="[headers.createdAtDateTime,headers.tradeType,headers.unitPrice,{'label':'مقدار('+tradeFrom+')','name':'amount'},headers.totalPrice,headers.commission,headers.fill,headers.action] "/> -->
        <table>
            <tr>
                <td>زمان</td>
                <td>نوع سفارش</td>
                <td>قیمت واحد</td>
                <td>مقدار</td>
                <td>قیمت کل</td>
                <td v-if="isActive"> انجام شده</td>
                <td v-if="isActive"> عملیات </td>
                <td v-if="!isActive"> وضعیت </td>
            </tr> 
            <tr v-for="(order,index) in dataSet.filter(order=> isActive==(order.orderStatus==='IS_OPEN') )" :key='index'>
                <td>{{$G2J(order.createdAtDateTime)}}</td>
                <td :class="{red:order.orderType.includes('SELL'),green:order.orderType.includes('BUY')}">{{order.orderType.includes('BUY')?'خرید':'فروش'}}-{{tradeType(order.orderType)}}</td>
                <td>{{$toLocal(order.unitPrice,0)}}</td>
                <td>{{order.amount}}</td>
                <td>{{$toLocal(order.wholePrice,0)}}</td>
                <td v-if="isActive">{{order.executedPercent}}</td>
                <td v-if="isActive" @click="cancelOrderModal(order)" class="cancel-btn">لغو سفارش</td>
                <td v-if="!isActive" :class="{red:order.orderStatus=='CANCELLED_BY_USER'
                ,green:order.orderStatus=='FINISHED_WITHOUT_TRANSACTION'||
                order.orderStatus=='COMPLETED'}">{{conditions[order.orderStatus]}}</td>
            </tr>
        </table>    
        <CancelModal v-if="modalType=='CANCEL'" @modalToggle="value=>modalType=value" @confirm="removeorder()"/>
           </div>
</template>

<script>
import * as variables from '../../../library/variables.json'
import CancelModal from '../../modals/CancelModal'
import {
    tradeTo,
    tradeFrom,
    Loop
} from "@/library/reuseableFunction";

export default {
  name: 'HistoryOfOrders',
  components :{
      CancelModal
  },
  computed: {
        tradeTo,
        tradeFrom
    },
  data() {
      return {
         headers:variables.headers,
        //  ordersType:'ActiveOrders',
         dataSet:[],
         isActive:true,
         removeid:'',
         modalType:'',
       conditions:{'FINISHED_WITHOUT_TRANSACTION':'موفق','COMPLETED':'موفق','CANCELLED_BY_USER':'لغو شده'}

         
      }
  },
   methods: {
       async removeorder() {
            //   this.state.loading = true
            this.cancelModal = false
            const [res, ] = await this.$http.delete(`orders/${this.removeid}`)
            if (res) {
                ++this.state.orderChange
                this.modalType = ''
                this.$error('سفارش با موفقیت حذف شد', '', 'success')
                if (this.state.loop) {
                    this.loop.start()
                } else {
                    this.getLastOrders()
                }
            }
        },   
    cancelOrderModal(e) {
        this.loop.stop()
        this.modalType = 'CANCEL'
        this.removeid = e.id
    },
    tradeType(type){
        return  type.includes('EXCHANGE')?'صرافی':type.includes('MARKET')?'بازار':'حد'
    },
    async getLastOrders() {
        // if (this.page <= this.lastPage) {
            this.page++ 
            let res = await this.$axios.get('/orders', {
                params: {
                    market: this.tradeFrom + '_' + this.tradeTo,
                    size: 20
                }
            })
            if (res.content)
                this.dataSet = res.content;
                    this.loading = false
        }
        // },
    },
     mounted() {
        this.loading = true
        if (this.state.loop) {
            this.loop = new Loop(this.getLastOrders, 5000, 'lastOrder')
            this.loop.start()
        } else {
            this.getLastOrders()
        }

    },
    beforeDestroy() {
        this.loop.stop()
    }
  }
</script>

<style lang="scss" scoped>
    .toggle-comp{
    max-width: 312px;
    flex-grow: 1;
    /* min-width: 150px; */
    min-width: 150px;
}

.footer-table{
    padding-bottom: 50px;
}
.cancel-btn{
    color: red;
    cursor: pointer;

}
table{
    width: 100%;
}
tr{
    font-size: clamp(11px,1.1vw,12px);
    color: #828282;
}
.red{
    color:#CA2F4B
}
.green{
    color: #03C70B;
}
</style>