<template>
<div class="brief">
    
        <WalletChart :colors="colors" :wallets='wallets'/>

        <div v-for="(coin,index) in sorted" :key="index" class="crypto" >
          <div  v-if="coin[0]" class="crypto">
            <div class="title-icon" >
                <div class="icon" :id="coin[0]"    ><img class="icon-img" :src="require('../../../assets/coins/'+coin[0]+'.png')"/></div>
                <div><div>{{$coins[coin[0]].persianName}}</div><div class="symbol">({{ $coins[coin[0]].code}})</div></div>
            </div>
            <div class="percent">{{$toLocal((coin[1].wholePriceOfCredit/totalValue)*100,2)}}%</div>
          </div>
        </div>

</div>
  
    
</template>

<script>
import * as Vibrant from 'node-vibrant'
import WalletChart from './WalletChart'
export default {
  name: 'Brief',
  components:{
      WalletChart
  },
  props:{ 
     wallets:Object
  },
  data(){
    return{
        totalPercent:'',
        totalValue:1,
        colors:{}
       
    }
  },
  watch:{
    'wallets':function(){
        this.setData()
    }
  },
  computed:{
    sorted:function(){
       let entireRes = Object.entries(this.wallets)
            const sortCoins = this.$walletSortCoins
            entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
            return entireRes
    }
  },
  methods:{
       setData(){
        for (const [code, data] of Object.entries(this.wallets)) {
        //  if(code!="TOMAN"){
          console.log(code)
                this.setColor(code)
                this.totalValue+=data.wholePriceOfCredit
          // }
}
  
      }
              
,
          setColor(code){
            Vibrant.from(require('../../../assets/coins/'+code+'.png')).getPalette((err, palette) =>
                        {
                           document.getElementById(code).style.background="rgba("+palette.Vibrant.rgb+",0.3)"
                        
                        })
          }
  },
  mounted(){
    this.setData()
  }
  
}
</script>
<style scoped>
.brief{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F1F7FF;
    border-radius: 8px;
    padding:24px 24px 24px 24px;
}
.icon{
    /* background: #C4D7F5; */
border-radius: 4px;
height: 40px;
width: 40px;
display: flex;
justify-content: center;
align-items: center;
}

.icon-img{
  width: 28px;
  height: 28px;
}

.chart{
  /* display: none; */
}
.title-icon{
  display:flex;
  font-size: clamp(12px,1.3vw,14px);
  font-weight: bold;
  justify-content: flex-start;
  padding-top:12px;
  column-gap: 8px;

}

.symbol{
  color: var(--gray3);
  font-size: clamp(10px,1.1vw,12px);
  font-weight: normal;

}

.crypto{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  color: var(--gray2);

}

.percent{
    font-size: clamp(15px,1.6vw,18px);
    font-weight: bold;
}
</style>