<template>
<div>
    <div class="page-header">قوانین و مقررات</div>
    <div class="passage-container" v-html="privacyPolicy"/>
        
</div>
  
    
</template>

<script>
export default {
  name: 'Terms',
    methods:{
         async getInfo() {
            const res = await this.$axios.get('/exchange-info')
            this.privacyPolicy = res.baseDTO.privacyPolicy || ''
        },
    },
    data(){
        return {
            privacyPolicy:''
        }
    },
    mounted(){
        this.getInfo()
    }
}

</script>
<style scoped>
h4{
    color: #143B74;
    font-size: clamp(16px,1.9vw,20px);
}

.text{
    font-size: clamp(15px,1.6vw,18px);
    color: #4F4F4F;
}

.passage-container{
   background: #FCFCFC;
border-radius: 6px;
padding:24px;


}
</style>