<template>
<div class="dashboard">
    <div class="side-details">
        <div class="page-title">حساب کاربری</div>
        <div class=" details section">
            <div class="detail"><span class="detail-label"> نام کاربری</span><span class="detail-value">{{user.firstName+' '+user.lastName}}</span></div>
            <div class="detail"><span class="detail-label">ایمیل </span><span class="detail-value">{{user.email}}</span></div>
            <div class="detail"><span class="detail-label">موبایل </span><span class="detail-value">{{user.mobileNumber}}</span></div>
            <div class="detail"><span class="detail-label">کد ملی </span><span class="detail-value">{{user.nationalCode}}</span></div>
            <div class="level-detail"><span class="detail-label">سطح کاربری </span><span class="level">{{userLevels[user.userAccountLevel]}}</span></div>
            <div class="link">
                <UnderLinedLink>سطوح کاربری</UnderLinedLink>
            </div>
            <button class="blue-btn" @click="$router.push('/Panel/Authentication')">احراز هویت</button>
        </div>
        <div class="invite section">
            <div class="invite-detail">
                <div class="invite-value">{{inviteData.countInvitees}} نفر</div>
                <div class="invite-label">تعداد دوستان شما</div>
            </div>
            <div class="invite-detail">
                <div class="invite-value">{{inviteData.profit}} </div>
                <div class="invite-label">مجموع سود شما</div>
            </div>
            <div class="link">
                <UnderLinedLink href='/Panel/InviteFriends'> دعوت دوستان به باداکس</UnderLinedLink>
            </div>

        </div>
    </div>
    <div class="errorText" v-if="errorFlag">اطلاعات شما هنوز تایید نشده است</div>
    <!-- {{state.loading}} -->
    <div class="table-container">
       
        <ToggleComp class="toggle-comp" @choose="(choice)=>{isExchange=(choice==='EXCHANGE')}" :items="[{label:'بازار',value:'P2P'},{label:' صرافی',value:'EXCHANGE'}]" />

        <table v-if="!errorFlag  && !loading">
            <tr class="head">
                <td class="frst-head" align="right">ارز </td>
                <td align="right">موجودی شما </td>
                <td align="right" class=" resp-3">قیمت بازار<span class="symbol">تومان</span></td>
                <td class="resp-2" align="right"> قیمت خرید<span class="symbol">تومان</span></td>
                <td class="resp-1" align="right">قیمت فروش <span class="symbol">تومان</span></td>
                <td align="left">تغییرات </td>
            </tr>
            <tr class="row" v-for="(coin,index) in sorted.filter(coin=>coin[0]!='TOMAN')" :key="index">
                <td align="right">
                    <div class="title">
                        <img class="icon resp-1" :src="require('../../assets/coins/'+$coins[coin[0]].code+'.png')" />
                        <div>{{$coins[coin[0]].persianName}}
                            <div class="measure">({{$coins[coin[0]].code}})</div>
                        </div>
                    </div>
                </td>
                <td align="right"><span>{{$toLocal(coin[1].credit,$decimal[coin[0]])}}</span><span class="symbol">{{$coins[coin[0]].code}}</span></td>
                <td align="right" class=" resp-3"><span>{{$toLocal(coin[1].price,0)}}</span></td>
                <td class="resp-2" align="right"><span>{{$toLocal(coin[1].buyPrice,0)}}</span></td>
                <td class="resp-1" align="right"><span>{{$toLocal(coin[1].sellPrice,0)}}</span></td>
                <td align="left"><span class="change" v-if="coin[0]!='TOMAN'" :class="{red:coin[1].change<0,green:coin[1].change>0}"><span v-if="coin[1].change>0">+</span> <span v-if="coin[1].change<0">-</span> {{(Math.abs(coin[1].change).toFixed(2))}} %</span></td>
            </tr>
        </table>
    </div>

</div>
</template>

<script>
export default {
    name: 'Dashboard',
    data() {
        return {
            phone: "",
            username: "",
            email: "",
            nationCode: '',
            cryptos: [],
            user: {},
            wallets: {},
            inviteData: {},
            errorFlag: false,
            userLevels: {
                'BRONZE': 1,
                'SILVER': 2,
                'GOLD': 3,
                'PLATINUM': 4,
                'SUPERVISOR': 5,
                data: '',
            },
            isExchange: false,
            loading: false
        }
    },
    watch: {
        'isExchange': function () {
            if (this.isExchange)
                this.getExchangeCoins()
            else
                this.getP2PCoins()
        }
    },
    computed:{
         sorted(){
           let entireRes = Object.entries(this.wallets)
            const sortCoins = this.$walletSortCoins
            entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
            return entireRes

      }
    },
    methods: {
        async getDetails() {
            const response = await this.$axios.get('/users/account?imageNeeded=false')
            this.user = response.baseDTO
            const inviteDResponse = await this.$axios.get('/users/invites')
            this.inviteData = inviteDResponse.baseDTO
        },
        // async getWallets(){
        //     const [res, ] = await this.$http.get(`/wallets/customer-addresses?walletType=${this.isExchange?'EXCHANGE':'P2P'}`)
        //     if (res){
        //         this.wallets = res
        //     }
        // },
        async getP2PCoins() {
            this.loading = true
            this.state.loading = true
            let cryptos
            let crypto
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=P2P')
            if (res) {
                this.wallets = res
            }
            await this.$axios.get('/trades/market-info', {
                params: {
                    weeklyDataIncluded: false,
                }
            }).then(response => cryptos = response.content.filter(coin => coin.marketType.includes('TOMAN')))
            for (const [key, value] of Object.entries(this.wallets)) {

                if (key !== 'TOMAN') {
                    crypto = cryptos.find(coin => coin.marketType.includes(key))
                    if (crypto) {
                        value.buyPrice = crypto.buyPrice
                        value.sellPrice = crypto.sellPrice
                        value.change = crypto.last24HourChange
                        value.price = crypto.lastPrice
                    }
                }
                this.loading = false
                this.state.loading = false
            }

        },

        async getExchangeCoins() {
            this.loading = true
            this.state.loading = true


            let cryptos
            let crypto
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE')
            if (res) {
                this.wallets = res
            }
            await this.$axios.get('coins/exchange-price/usd', {
                params: {
                    includeWeeklyChange: false
                }
            }).then(response => cryptos = response)

            for (const [key, value] of Object.entries(this.wallets)) {
                if (key !== 'TOMAN') {
                    crypto = cryptos.find(coin => coin.destCoinSymbol === key)
                    if (crypto) {
                        value.buyPrice = crypto.buyPrice
                        value.sellPrice = crypto.sellPrice
                        value.change = crypto.price24hrChangePercent
                        value.price = crypto.price
                    }

                }
            }
            this.loading = false
            this.state.loading=false

        }
    },
    mounted() {
        this.getP2PCoins()
        this.getDetails()
    }
}
</script>

<style scoped>
.table-container {
    flex-grow: 9;
}
.toggle-comp{
    max-width: 250px;
    margin-bottom: 10px;
}
.dashboard {
    display: flex;
    justify-content: space-between;

    flex-wrap: wrap;
    row-gap: 40px;
    padding-left: 20px;

    column-gap: 35px;
}

.details {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding-top: clamp(20px, 2.3vw, 30px);
    /* max-width: 260px; */
}

.detail-label {
    color: var(--gray3);
    font-size: 12px;
}

.detail {
    display: grid;
    column-gap: 16px;
    grid-template-columns: 1fr 4fr;
    white-space: nowrap;
    justify-content: right;

}

.side-details {
    flex-grow: 1;
}

.errorText {
    flex-grow: 9;
}

.detail-value {
    font-size: clamp(14px, 1.4vw, 16px);
    color: var(--gray2);
}

.level::after {
    content: url('../../assets/public/leafborder2.svg');
    transform: rotatey(180deg);

}

.level::before {
    content: url('../../assets/public/leafborder2.svg');
}

.link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.level {
    color: #F2994A;
    font-weight: bold;
    font-size: clamp(26px, 2.5vw, 30px);
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: center;
    padding-right: 5px;
}

.level-detail {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
}

.blue-btn {
    width: 100%;
    height: 40px;
}

.invite-label {
    font-size: clamp(12px, 1.3vw, 14px);
    color: var(--gray2);

}

.invite-value {
    font-size: clamp(16px, 1.6vw, 18px);
    color: var(--gray2);

}

.invite {
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
    row-gap: clamp(30px, 4vw, 52px);
}

.symbol {
    color: var(--gray2);
    padding-left: 6px;
    font-size: clamp(10px, 1.1vw, 12px);
}

.table-header {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    width: 100%;
}

.change {
    display: flex;
    direction: ltr;

}

.markets {
    padding-top: clamp(80px, 12vw, 130px);
}

.title {
    display: flex;
    justify-content: right;
    align-items: flex-start;
    font-size: clamp(14px, 1.5vw, 16px);
}

.measure {
    font-size: clamp(10px, 1.1vw, 12px);
    color: var(--gray3);
}

.red {
    color: rgba(214, 43, 43, 1);
    ;

}

.markets-link {
    text-align: center;
    margin-top: 16px;
}

.symbol {
    color: rgba(30, 37, 48, 0.6);
    margin-right: 4px;
}

.icon {
    margin-left: 8px;
    height: 36px;
    width: 36px;
}

.green {
    color: var(--green);

}

.head {
    background: transparent;
    color: var(--gray4);
    font-size: clamp(13px, 1.3vw, 14px);
    font-weight: 400px !important;
}

.row {
    background: #FAFCFF;
    color: var(--gray2);
    height: 64px;
    font-size: clamp(13px, 1.3vw, 14px);
}

.symbol {
    color: var(--gray3)
}

td {
    font-weight: 400px !important;

}

table {
    border-collapse: collapse;
    flex-basis: 600px;
    width: 100%;
}

.row td:first-child {
    /* padding-right: clamp(15px,2.3vw,35px); */
}

@media(max-width:600px) {
    .resp-1 {
        display: none;
    }
}

@media(max-width:450px) {
    .resp-2 {
        display: none;
    }
}

@media(max-width:350px) {
    .resp-3 {
        display: none;
    }
}
</style>
