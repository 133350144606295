<template>
<div class="crypto-filter" v-if="crypto">

    <div class="filter-title">
        <label class="label" @click="dropShow=!dropShow">
            <img :class="{'drop-open':dropShow}" src='../../../../assets/icons/arrowdown.svg' />
            <div>{{$coins[crypto.destCoinSymbol].code}}</div>
            <img class="title-icon" :src="require('../../../../assets/coins/'+crypto.destCoinSymbol+'.png')" />
        </label>
        <div class="line" />
        <input @input="e=>inputHandler(e)"  :value='value' />
        <!-- $toLocal(value,$decimal[crypto.destCoinSymbol]) -->
    </div>
    <div>

        <div name="slide" class="dropdown" v-if="dropShow">
            <div class="row" v-for="(coin,index) in cryptos" :key="index" @click="selectCrypto(coin)">
                <img class="icon resp" :src="require('../../../../assets/coins/'+coin.destCoinSymbol+'.png')" />
                <div>{{$coins[coin.destCoinSymbol].persianName}}</div>
                <div class="buy-price">تومان&nbsp;&nbsp;{{$toLocal(coin.buyPrice,$decimal[coin.destCoinSymbol])}}</div>
                <div class="sell-price resp">تومان&nbsp;&nbsp;{{$toLocal(coin.sellPrice,$decimal[coin.destCoinSymbol])}}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'HeaderCryptoFilter',
    props: ['cryptos', 'propValue'],
    data() {
        return {
            dropShow: false,
            crypto: {},
            value: 0
        }
    },
    watch: {
        propValue() {
            this.value = this.propValue
        },
        cryptos() {
            this.crypto = this.cryptos[0]
        }
    },
    methods: {
        inputHandler(e){
            this.value=e.target.value;
            this.value=this.$toLocal(this.value,this.$decimal[this.crypto.destCoinSymbol])
            
            this.$emit('input',this.value);
        },
        selectCrypto(coin) {
            this.dropShow = false;
            this.crypto = coin
            this.$emit('selectCoin', this.crypto)

        }

    },

    mounted() {
        this.crypto = this.cryptos[0]
    }

}
</script>

<style scoped>
.icon {
    width: 24px;
    height: 24px;
}

.title-icon {
    width: 18px;
    height: 18px;
}

.label {
    display: flex;
    column-gap: 8px;
    padding: 5px 11px 5px 10px;
    align-items: center;
    cursor: pointer;
}

.filter-container {
    display: flex;
    width: 100%;
    /* border: 1px solid; */
    flex-grow: 1;

}

.crypto-filter {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 3;

    flex: 1;
    top: 0px;
    min-height: 36px;
    position: relative;
}

.filter-title {
    display: flex;
    background: #EDF1ED;
    border-radius: 6px;
    width: 100%;
    flex: 1;
    align-items: center;
    height: 36px;
}

.row {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 5fr 5fr 5fr;
    padding: 8px 8px 8px 0px;
    column-gap: 5px;
    cursor: pointer;
    /* column-gap: 16px; */

    /* padding: 7px 20px 7px 16px; */
}

.line {
    height: 30px;
    width: 0.5px;
    background: #4F4F4F;
}

input {
    background: #EDF1ED;
    width: 100px;
    flex-grow: 1;

}

.sell-price {
    color: #CA2F4B;
}

.buy-price {
    color: #03C70B;
}

.drop-open {
    transform: rotate(180deg);
}

.dropdown {
    transition: height .4s ease-in-out;

    position: absolute;
    
    border-radius: 8px 0px 8px 8px;
    background: #EDF1ED;
    padding:5px 5px 5px 25px;
    max-height: 250px;
    overflow: auto;

}

@media (max-width:550px){
    .resp{
        display: none;
    }
    .row{
    grid-template-columns:   5fr 5fr;
    }
}
</style>
