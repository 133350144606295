<template>
<div class="faq">
    <div class="page-header" align='right'>سوالات متداول</div>
    <div class="questions-container">
        <div class="questions">
            <div v-for="(question,index) in questions" :key="index" class="question" @click="openedIndex=(openedIndex==index)?null:index" >
                <div class="question-row" >
                    <span>{{question.question}}</span>
                    <img :class="{'drop-open':openedIndex==index}" src='../../../assets/icons/arrowdown.svg'/>
                </div>
                <div v-if="openedIndex==index" class="answer">
                    <div>{{question.answer}}</div>
                </div>
            </div>
        </div>

        <div class="contact-img">
            <img src='../../../assets/public/contactus.png'/>
        </div>

    </div>
    

</div>
</template>

<script>
export default {
  name: 'FAQ',
  data(){
    return{
        openedIndex:null,
        questions:[
          
        ]
      
    }
  },
    mounted(){
      this.getFaq()
      console.log(this.questions)

  },
  methods:{
     async getFaq(){
            this.questions=await this.$axios.get('/faq').then(response => this.questions=response )
      }
  
  }
}
</script>
<style scoped>
.faq{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.question{
background: #FCFCFC;
box-shadow: 2px 2px 9px rgba(186, 185, 185, 0.27);
border-radius: 6px;
width: 100%;
}


.questions-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly; 
}
.question:hover{
    background: #E9EBFF;

}
.questions{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 400px;
    row-gap: 16px;
}

.answer{
    font-size: 14px;
   padding: 0px clamp(18px,2.1vw,24px) 10px clamp(18px,2.1vw,24px);
   color: #504B4B;
}

.drop-open{
    transform: rotate(180deg);
}

.question-row{
    display: flex;
    justify-content: space-between;
    padding: clamp(18px,2.1vw,24px);
    font-size:14px;
    color: #222222;
}


.contact-img{
   flex-grow: 1;
   display: flex;
   justify-content:center;
   flex-basis: 400px;
    /* flex-basis: 500px; */
}

.contact-img  >img {
    width: 100%;
    height: auto;
    
}
</style>