<template>
  <div class="sidebar">
     <!-- <div class="bodex-container"> -->
       <div>
       <a href='/' @click.prevent="$router.push('/')"><img class="bodex" src='../../assets/icons/bodex.svg' /></a>

       </div>
       <!-- </div> -->
    <span class="label">صرافی</span>
    <div :class="{link:true, ' active-link':$route.name=='Exchange'}">
      <a href="/Panel/Exchange" @click.prevent="$router.push('/Panel/Exchange')" ><div class="title">سفارش سریع</div>  <img class="icon" src="../../assets/icons/fast-trade.svg"/></a>
    </div>
    <div :class="{link:true, ' active-link':$route.name=='P2P'}">
        <a  href="/Panel/P2P" @click.prevent="$router.push('/Panel/P2P/TOMAN/BITCOIN')" ><div  class="title">معامله بازار</div> <img class="icon" src="../../assets/icons/market-trade.svg"/></a>
      <div v-if="$route.name=='P2P'" class="cryptos">
        <a v-for="(crypto,index)  in cryptos"  :key="index"   @click="activeIndex=index"
          :class="{crypto:true, 'active-crypto':$route.params.crypto==$coin2Snake[crypto.relatedCoin]}"
          :href="'/Panel/P2P/TOMAN/'+$coin2Snake[crypto.relatedCoin]"
          @click.prevent="$router.push(`/Panel/P2P/${tradeTo || 'TOMAN'}/${$coin2Snake[crypto.relatedCoin]}`)"  >
           <img class="crypto-icon" :src="require('../../assets/coins/'+crypto.relatedCoin+'.png')"/>
           <div class="title">{{$coinLabel[crypto.relatedCoin]}}</div>
          </a>
      </div>

    </div>

  </div>
    
</template>

<script>
import {
    tradeTo,
} from "@/library/reuseableFunction";
export default {
  name: 'Sidebar',
  computed:{
    tradeTo
  },
  data(){
    return{
      activeIndex:0,
      cryptos:[],
      
    }
  },
  methods:{
         async getCoins() {
                let res= await this.$axios.get('/trades/market-info',{
                        params: {    
                            weeklyDataIncluded: false
                        }
                    }).then(response=>res=response.content)                   
                if (res) {
                    this.cryptos = res.filter(crypto=>crypto.marketType.includes('TOMAN'))
                }
            },
  },
  mounted(){
    this.getCoins()
  }
}
</script>
<style scoped>
.sidebar{
  /* overflow:hidden; */
  height: calc(100vh - 15px);
  background: linear-gradient(183.05deg, #123B77 -1.99%, #223459 31.28%, #00171F 105.75%);
  border-radius:  12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  min-width: clamp(150px,17vw,211px);
  flex-grow: 1;
   max-width: 211px;

 }

 .link > a{
     color: white;
 }
/*
.bodex-container{
  display: flex;
  max-width: 153px;
   padding: 12px;
  padding-top:15px;
} */
.label{
  width: 100%;
  text-align: right;
  font-size: clamp(12px,1.3vw,14px);
  font-weight: bold;
  margin-top: 14px;
  margin-right: 16px;
}

.active-link{
  background: linear-gradient(235.27deg, rgba(0, 115, 250, 0.184) 0%, rgba(9, 196, 255, 0.184) 49.27%, rgba(166, 250, 255, 0.06256) 100%, rgba(38, 116, 197, 0.184) 100%);
  box-shadow: inset 9.43333px -9.43333px 9.43333px rgba(90, 183, 189, 0.184), inset -9.43333px 9.43333px 9.43333px rgba(255, 255, 255, 0.184);
backdrop-filter: blur(36px);

}
.crypto-icon{
  height: 30px;
  width: 30px;
}
.cryptos{
  display: flex;
  max-height: 100%;
  flex-direction: column;
  width: 100%;
  font-size: clamp(14px,1.5vw,16px);

  padding-right:clamp(5px,0.7vw,10px);
}

.crypto{
  /* padding: 8px 16px 8px 0px ; */
  height: 42.5px;
  display: flex;
  column-gap: 20px;
  opacity: 0.6;
  padding-right: 8px;
  align-items: center;
  border-radius: 12px;
  color: white;
  cursor: pointer;
}
.active-crypto{
  opacity: 1;
  background: #F0F3F8;
border-radius: 0px 8px 8px 0px;
color: #143B74;
}

.link{
  font-size: clamp(16px,1.7vw,18px);
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 0px;
  cursor: pointer;
  /* min-height: 48px; */
  /* font-weight: bold; */
}
.icon{
  display: none;
  padding: 8px 0px;
}

.bodex{
  width: 153px;
   padding: 12px;
  

 
}
@media(max-width:700px){
   .label, .title{
    display: none;
   
  }

  .sidebar{
    min-width: 50px;
    max-width: 60px;
    overflow-y: auto;

  }

  .bodex{
    position: relative;
      transform: rotate(-90deg);
      margin-top:50px;
  margin-bottom: 40px;
    
  }
  .crypto{
    padding-right: 0px;
    display: flex;
    justify-content: center;
  }
  .icon{
    display: block;
  }
}
</style>