<template>
<div >
    
      <div class="page-title">تاریخچه تراکنش ها</div>
<div class="filters">

   <div class="toggle-comp"> 

   <ToggleComp  @choose="(choice)=>{tableType=choice}" :items="[{label:' رمزارزی',value:'Crypto'},{label:'ریالی ',value:'Rial'}]"/>
   </div>
    <FilterDrop @choose="(choice)=>transType=choice.key" :items="[{key:'DEPSOIT',label:'واریز'},{key:'WITHDRAW',label:'برداشت'}]"/>
</div>


<div class="table-container">
        <div class="local-loading" v-if="loading">
            <div class="super-spinner"/>
        </div> 
    <perfect-scrollbar @ps-y-reach-end="transType=='WITHDRAW'?getWithdrawHistory():getDepositHistory()" ref="scroll">
    <table>
            <tr class="header">
                <td class="resp-1">زمان</td>
                <td class="resp-2">نوع تراکنش</td>
                <td v-if="tableType=='Crypto'">نوع ارز</td>
                <td  >مقدار درخواستی<span v-if="tableType=='Rial'">(تومان)</span></td>

                <td v-if="tableType=='Crypto'">آدرس کیف پول</td>
                <td v-if="tableType=='Rial' ">شماره کارت</td>
                <td class="resp-1">شناسه تراکنش</td>
                <td class="resp-1">وضعیت</td>
            </tr> 
            
            <tr class="row" v-for="(transaction,index) in transactions.filter(transaction=>transactionFilter(transaction.relatedCoin))" :key='index'>
                <td class="resp-1">{{$G2J(transaction.createdAtDateTime)}}</td>
                <td class="resp-2" :class="{red:transType=='WITHDRAW',green:transType=='DEPSOIT'}">{{transType=='WITHDRAW'?'برداشت':'واریز'}}</td>
                <td v-if="tableType=='Crypto'">{{$coins[transaction.relatedCoin].persianName}}</td>
                <td > <span dir='ltr'>{{$toLocal(transaction.amount,$decimal[transaction.relatedCoin])}}&nbsp;{{transaction.tokenType}}</span></td>
                <td>{{transaction.destinationWalletAddress || '-'}}</td>
                <td class="resp-1" >{{transaction.hashCode || '-'}}</td>
                
                <td class="resp-1" :style="{color:statusColor(transaction.transactionStatus)}">{{statusLabel(transaction.transactionStatus)}}</td>
            </tr>
        </table> 
      </perfect-scrollbar>
    </div>  
</div>
    
</template>

<script>
import FilterDrop from "./FilterDrop"
import {
    statusLabel,statusColor

} from "@/library/reuseableFunction";


export default {
  name: 'Transactions',
   components:{   
         FilterDrop
  },
  computed:{
    transactions(){
      return this.transType=='WITHDRAW'?this.withdraList:this.depositList
    },
    statusLabel,statusColor
  },
  methods:{
    transactionFilter(coin){
      return (this.tableType=='Rial' && coin.includes('TOMAN')) || (this.tableType=='Crypto' && !coin.includes('TOMAN'))

    },
    async getWithdrawHistory() {
            const address = 'wallets/withdrawal-requests'
            if (this.page <= this.lastPage) { //for scroll loading
                this.page++ //for scroll loading
                this.loading = true
                const [res, ] = await this.$http.get(address, {
                    params: {
                        size: 10,
                        page: this.page
                    }
                })
                if (res) {
                    this.withdraList = [...this.withdraList, ...res.content]
                    this.lastPage = res.totalPages

                }
                    this.loading=false

            }

        },
        async getDepositHistory() {

            const address = 'wallets/deposit'
            if (this.page2 <= this.lastPage2) { //for scroll loading
                this.page2++ //for scroll loading
                this.loading = true
                const [res, ] = await this.$http.get(address, {
                    params: {
                        size: 10,
                        page2: this.page2
                    }
                })
                if (res) {
                    this.depositList = [...this.depositList, ...res.content]
                    this.lastPage2 = res.totalPages
                }
                    this.loading=false


            }
        },
        closemodal() {
            this.modalshow = false
        },
        showModal(e) {
            this.modalshow = true
            this.removeid = e.id
        },
        async removeorder(e) {
            this.state.loading = true
            await this.$axios.post(`/wallets/withdrawal-request/cancel/${e}`)
            this.modalshow = false
            // this.completedModal = true
            this.$error('حذف با موفقیت انجام شد', '', 'success')
            this.tableContents = []
            this.page = 0
            this.withdraList = []
            this.getWithdrawHistory()
        },

    },
    mounted() {
        this.getWithdrawHistory()
        this.getDepositHistory()
    },
  
  data(){
    return{  
      transType:"DEPSOIT",
      tableType:"Crypto",
      withdraList:[],
      depositList:[],
      loading:false,
      page2:0,
      page:0,
      lastPage2:1,
      lastPage:1


      
    }
  }
}
</script>
<style scoped>
.table-container{
    height: 200px;
    width: 100%;
    position: relative;
    display: flex;
    overflow-y:scroll;
}
.local-loading{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.4);
    z-index: 1;
}
.super-spinner {
    box-sizing: border-box;
    position: relative;
    margin: auto;
    width: 90px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 10px solid transparent;
    border-top-color: #FFE976;
    border-bottom-color: #AF9A28;
    transform: rotateZ(-45deg);
    animation: super-spinner 5s linear infinite;
    z-index: 2;
}

.super-spinner::after {
    box-sizing: border-box;
    content: "";
    position: absolute;
    inset: -10px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 10px solid transparent;
    border-right-color: #FFF3B6;
    border-left-color: #857000;
    animation: super-spinner 5s linear infinite;
}


@keyframes super-spinner {
    0% {
        transform: rotateZ(0deg);
    }

    25% {
        transform: rotateZ(360deg);
    }

    75% {
        transform: rotateZ(720deg);
    }

    100% {
        transform: rotateZ(1080deg);
    }
}
.toggle-comp{
  max-width: 380px;
  margin-top: 12px;
  flex-grow: 1;
}
table{
  width: 100%;
}

.filters{
 display: flex;
 align-items: center;
 justify-content: space-between;
}
.red{
    color:#CA2F4B
}
.header{
    position: sticky;
    top: 0;
    height: 40px; 
    font-weight: bold;
    font-size: clamp(12px,1.3vw,14px);
    color: #828282;
    background: white;

}
.green{
    color: #03C70B;
}
.row{
    font-size: clamp(12px,1.3vw,14px);
    color: #828282;

}
@media(max-width:1000px){
    .resp-1{
        display: none;
    }
}
@media(max-width:550px){
    .resp-2{
        display: none;
    }
}

@media(max-width:400px){
    .resp-3{
        display: none;
    }
}
</style>