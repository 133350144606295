<template>
 <div class="c">
     <div class="toman-row">
         <div class="title">
             <img class="resp" src="../../../assets/icons/iran-flag.svg">
             <span>موجودی تومان</span>
         </div>
         <span >{{$toLocal(tomanBalance,0)}}</span>
         <div class="btns">
             <UnderlinedBtn @click="modalType='TomanCharge'" color="#03C70B">واریز</UnderlinedBtn>
             <UnderlinedBtn @click="modalType='TomanWithdraw'" :cash='tomanBalance' color="#CA2F4B">برداشت</UnderlinedBtn>
         </div>
     </div>
     <div class="cryptos-wallet">
         <div class="table-header">
             <div class="resp"></div>
             <div>ارز</div>
             <div>موجودی</div>
             <div class="resp">معادل (تومان)</div>
             <div class="resp">عملیات</div>
         </div>
         

         <div class=""   v-for="(coin,index) in sorted" :key="index">   
             <div class="crypto-row" v-if="coin[0]!='TOMAN'">
                <img class="icon resp" :src="require('../../../assets/coins/'+coin[0]+'.png')"/>
                <div >{{$coins[coin[0]].persianName}} <div class="symbol">({{$coins[coin[0]].code}})</div></div>
                <div class="depsoit"><span class="measure">{{$coins[coin[0]].code}}</span><span>{{$toLocal(coin[1].credit,$decimal[coin[0]])}}</span></div>
                <div class="resp">{{$toLocal(coin[1].wholePriceOfCredit,0)}}</div>
                <div  class="btns">
                    <UnderlinedBtn @click="relatedCoin=coin[0];modalType='CRYPTODEPOSIT';" color="#03C70B">واریز</UnderlinedBtn>
                    <UnderlinedBtn @click="openWithdrawModal(coin[0],coin[1].credit)" color="#CA2F4B">برداشت</UnderlinedBtn>
                    <UnderLinedLink :href="$coins[coin[0]].code=='USDT'?'/Panel/P2P/TOMAN/TETHER':'/Panel/P2P/TOMAN/'+coin[0]" >معامله</UnderLinedLink>
                    <!-- <UnderLinedLink v-else :href="'/Panel/P2P/TOMAN/TETHER'">معامله</UnderLinedLink> -->
                </div>

            </div>       
            

         </div>

     </div>
 
   <Deposit @modalToggle="type=>modalType=type" :relatedCoin="relatedCoin" v-if="modalType=='CRYPTODEPOSIT'"   :exchange='walletType=="EXCHANGE"'/>
   <Withdraw @modalToggle="type=>modalType=type" v-if="modalType=='CRYPTOWITHDRAW'" :relatedCoin="relatedCoin" :credit="credit" :exchange='walletType=="EXCHANGE"'/>  
   <TomanWithdraw @modalToggle="type=>modalType=type" v-if="modalType=='TomanWithdraw'" :cash="tomanBalance"/>
   <TomanCharge @modalToggle="type=>modalType=type" v-if="modalType=='TomanCharge'"/>   
 </div>
</template>

<script>
import Deposit from '../../modals/Depsoit'
import Withdraw from '../../modals/Withdraw'
import TomanWithdraw from '../../modals/TomanWithdraw'
import TomanCharge from '../../modals/TomanCharge'
export default {
  name: 'WalletsTable',
  components:{
      Deposit,
      Withdraw,
      TomanWithdraw,
      TomanCharge  ,
  },
  props:{
    wallets:Object,
    walletType:String
  },
  data(){
    return{
       cryptos:this.wallets,
       modalType:'',
       relatedCoin:'',
       nets:[],
       credit:0,
       
       
    }
  },
  computed:{
        tomanBalance(){

        if(this.wallets['TOMAN'])
            {
                const temp= this.wallets['TOMAN']
                return temp.credit
            } 
              return 0
      },
      sorted(){
           let entireRes = Object.entries(this.wallets)
            const sortCoins = this.$walletSortCoins
            entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
            return entireRes

      }
  },
  methods:{
     
      openWithdrawModal(code,credit){
            this.relatedCoin=code;
            this.credit=credit
            console.log(credit)
            this.modalType='CRYPTOWITHDRAW';
     },
    
      openTomanWithdrawModal(){
          this.modalType='TomanWithdraw';
      },
        
     
        
   
  },
  
}
</script>
<style scoped>
.icon{
    width: 30px;
    height: 30px;
}

.title{
    display: flex;
    align-items: center;
    column-gap: 12px;
}
.cryptos-wallet{
     background: rgba(250, 252, 255, 0.6);
    border-radius: 6px;
    /* background: red; */
    padding:0px clamp(15px,2vw,24px) 24px clamp(15px,2vw,24px);
}

.crypto-row{
   background: #F6FDFF;
   align-items: flex-start;
   column-gap: 15px;
   display: grid;
   /* background: blue; */
   border-radius: 8px;
   padding: 12px clamp(16px,1.8vw,22px) 12px clamp(25px,2.7vw,32px) ;
      grid-template-columns: 1fr 6fr 6fr 6fr 6fr;

    
}
.table-header{
    display: grid;
    text-align: right;
   column-gap: 15px;
   white-space: nowrap;

        grid-template-columns: 1fr 6fr 6fr 6fr 6fr;

   padding: 8px clamp(16px,1.8vw,22px) 8px clamp(25px,2.7vw,32px) ;
   color: var(--gray3); 



}

.depsoit{
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.btns{
    display: flex;
    justify-content: space-between;
    width: 100%;
    column-gap: 8px;
    row-gap: 8px;
}

.toman-row{

    background: #F8FCFF;
    /* background: rgb(244, 148, 28); */
    /* background: red; */
   padding: 20px clamp(15px,3.3vw,46px) 20px clamp(25px,4.5vw,56px);
   font-weight: bold;
   border-radius: 8px;

   display: grid;
   grid-template-columns: 2fr 2fr 1fr;
   align-items: center;
   width: 100%;
   justify-content: space-between;
   font-size: clamp(14px,1.5vw,16px);

}

.symbol{
    color: var(--gray5);
    font-size: clamp(10px,1.1vw,12px);
}

.measure{
    color: var(--gray3);
}

@media(max-width:680px){
    .resp{
        display: none;
    }
    .crypto-row{
               grid-template-columns: 6fr 6fr ;

    }

    .table-header{
               grid-template-columns:  6fr 6fr ;

    }
    .toman-row{
               grid-template-columns:  6fr 6fr ;

    }

}
</style>