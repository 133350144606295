<template>
  <div class="app">
    <GeneralNavbar v-if="!$route.path.includes('Panel')"/>
    <LoadingPage v-if="state.loading"/>
   <router-view></router-view>
  </div>
</template>

<script>
import GeneralNavbar from './components/navbars/GeneralNavbar'
import LoadingPage from './components/tools/LoadingPage'
export default {
  name: 'App',
  components: {
    GeneralNavbar,
    LoadingPage
  }
}
</script>

<style>
.app {
  background:  #FAFCFF;
  height: 100vh;   
  width: 100vw;
}
</style>
