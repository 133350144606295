<template>
<div class="drop-container">

  <div class="dropdown">
      <a href="/Panel/History/ActiveOrders" @click.prevent="$router.push('/Panel/History/ActiveOrders')" class="link">سفارشات فعال</a>
      <a href="/Panel/History/Orders" @click.prevent="$router.push('/Panel/History/Orders')" class="link">تاریخچه سفارشات</a>

  </div>
</div>  
</template>

<script>
export default {
  name: 'OrdersDropDown',
  
}
</script>
<style scoped>
.dropdown{
    position: absolute;
   background: #F6F9FE;
   border-radius: 0px 0px 8px 8px ;
   width: 160px;
   display: flex;
   flex-direction: column;
   row-gap: 16px;
   
   align-items: center;
   padding: 16px 12px 16px 12px;
}

.dropdown > a{
    text-align: right;
    width: 100%;
}
.link:hover{
     background: -webkit-linear-gradient(95.12deg, #143B74 -9.51%, #2B6BC9 104.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.link{
    color: #4F4F4F;
    font-size: clamp(14px,1.5vw,16px);
    cursor:pointer

}
.drop-container{
    position: absolute;
}

</style>