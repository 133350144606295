<template>
<div class="loading-page">
    <img class="loading-icon" src='../../assets/icons/bodex.svg' />
    <div class="super-spinner"/>
</div>
</template>

<script>
export default {
    name: 'LoadingPage',
    data() {
        return {
            count: 0
        }
    },
    methods: {
        // loadingCount(){
        //     if(this.count<3){
        //       this.count++
        //     }
        //     else  
        //       this.count=0
        //     }
    },
    mounted() {
        // setInterval(500,
        //   this.loadingCount()
        // )
    }
}
</script>

<style scoped>
.loading-page {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 0px;
    top: 0px;
    bottom: 0;
    left: 0;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 0px 15px;
    z-index: 100;
}

.text {
    margin-top: 15px;
    color: white;
    font-size: clamp(13px, 1.5vw, 18px);
}
.loading-icon{
  position: absolute;
}


.super-spinner {
    box-sizing: border-box;
    position: relative;
    margin: auto;
    width: 250px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 16px solid transparent;
    border-top-color: #FFE976;
    border-bottom-color: #AF9A28;
    transform: rotateZ(-45deg);
    animation: super-spinner 5s linear infinite;
}

.super-spinner::after {
    box-sizing: border-box;
    content: "";
    position: absolute;
    inset: -16px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 16px solid transparent;
    border-right-color: #FFF3B6;
    border-left-color: #857000;
    animation: super-spinner 5s linear infinite;
}

@keyframes super-spinner {
    0% {
        transform: rotateZ(0deg);
    }

    25% {
        transform: rotateZ(360deg);
    }

    75% {
        transform: rotateZ(720deg);
    }

    100% {
        transform: rotateZ(1080deg);
    }
}

</style>
