<template>
<div class="auth-page">
    <AuthCard>
        <template #card-header>ورود</template>
        <template #card-body>
            <LabelInput @input="value=>{data.email=value;emailError=''}" :errorText="emailError" label="آدرس ایمیل" />
            <LabelInput @input="value=>{data.password=value;}" type='password' label="رمز عبور" />
        </template>
        <template #card-footer>
            <div class="forget-pass">رمز عبور خود را فراموش کرده ام <UnderLinedLink href='/ForgetPass'> بازیابی رمز عبور</UnderLinedLink>
            </div>
           
            <button :class="{'authentication-confirm-btn':true,disabledbtn:!data.email ||!data.password }" :disabled="!data.email ||!data.password" @click="send()">ورود</button>

            <GoogleBtn @click="googleSso()" />
        </template>

    </AuthCard>
    <div class="suggest">حساب کاربری ندارم <UnderLinedLink href='/SignUp'>ثبت نام</UnderLinedLink>
    </div>

</div>
</template>

<script>
import AuthCard from './components/AuthCard'
import GoogleBtn from './components/GoogleBtn'
const verifyType = {
    EMAIL: 'Email',
    SMS: 'SMS',
    GOOGLE_AUTHENTICATOR: 'GoogleAuth',
}
export default {
    name: 'Login',
    components: {
        AuthCard,
        GoogleBtn,
    },
    data() {
        return {
            data: {
                email: '',
                password: '',
                osType: '',
                deviceType: '',
                browser: '',
            },
            show: false,
            emailError: '',
            passwordError: '',
        }
    },
    methods: {

        googleSso() {
            window.location.href = this.$google_sso
        },
        async send() {

            await this.checkEmail()
            this.state.loading = true
            const res = await this.$axios.post('/users/login', this.data)
            if (res.message === 'login successful, otp is required') {

                this.$cookies.set('username', this.data.email)
                this.state.userInfo.email = this.data.email
                this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
                this.$router.push('/TwoStepVerify/' + verifyType[res.baseDTO.towStepAuthenticationType])

            } else if (res.token) {
                this.$setToken(res.token, this.state.userInfo.remember)
                await this.$router.push('/Panel/Dashboard')
            }

        },
        async checkEmail() {
            if (!this.$validEmail(this.data.email) || !this.data.email) {
                this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                return Promise.reject('repeat failed : ' + this.data.email)
            }
        },
        getData() {
            this.data.osType = this.$detectOs()
            this.data.browser = this.$detectBrowser() || 'CHROME'
            this.data.deviceType = this.$detectDevice()
        },
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
.forget-pass {
    display: flex;
    flex-wrap: wrap;
    font-size: clamp(13px, 1.3vw, 14px);
    color: #4F4F4F;
    text-align: center;
    font-weight: bold;

}

.captcha-container {
    display: flex;
    justify-content: center;
    margin-top: 22px;
}

.suggest {
    display: flex;
    justify-content: center;
    font-size: 14px;
}

.disabledbtn {
    opacity: 0.7;
}
</style>
