<template>
<div class="switch" >
    <div :class="{active:choice==item.value}"  v-for="(item,index) in items " 
    :key="index" @click="choice=item.value;$emit('choose',choice)" >
     {{item.label}}
    </div>
</div>
  
</template>

<script>
export default {
  name: 'ToggleComp',
  props:['items','default'],
  data(){
      return {
          choice:this.default || this.items[0].value
      }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.switch{
    width: 100%;
    display: flex;
    font-size: clamp(12px,1vw,14px);
    flex-wrap: wrap;
    align-items: center;
    /* color: #828282; */
    color:var(--gray5);
    border-radius: 6px;
    padding: 3px;
   
    height: 37px;
    box-shadow: inset -2px -1px 5px rgba(194, 194, 194, 0.27), inset 2px 1px 4px rgba(199, 185, 185, 0.25);
}
.switch > div{
      flex: 1;
      height: 100%;
      cursor: pointer;
      display: flex;
      white-space: nowrap;
      align-items: center;
      justify-content: center;
    
}
.active{
    background: linear-gradient(95.12deg, #143B74 -9.51%, #2B6BC9 104.28%);
    box-shadow: inset 27.2667px -27.2667px 27.2667px rgba(9, 45, 102, 0.1), inset -27.2667px 27.2667px 27.2667px rgba(255, 255, 255, 0.1);  
    color: rgba(255,255,255,1);
    height: 100%;
    border-radius: 6px;
}

</style>