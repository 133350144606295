<template>
  <button class="google-btn" @click="$emit('click')">
      <img class="google-icon" src="../../../assets/icons/google.svg"/>
      <div>ورود با حساب گوگل</div>
  </button>
    
</template>

<script>
export default {
  name: 'GoogleBtn',
  
}
</script>
<style scoped>

.google-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    background: #DFF4F4;
    box-shadow: inset 0px -2px 13px rgba(201, 201, 201, 0.25), inset -3px 2px 9px rgba(116, 116, 116, 0.25);
    border-radius: 6px;
    width: 100%;
    height: 40px;
    color: #4F4F4F;

}

.google-icon{
    padding-left:8px;
}
</style>
