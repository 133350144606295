<template>
  <div class="details">
    <!-- <img class="bg" src="../../../assets/public/linebg.png"/> -->
     <div v-for="(detail,index) in details" :key="index" class="detail">
         <img :src="require('../../../assets/public/'+detail.icon+'.svg')"/>
         <div class="value">{{detail.value.toLocaleString()}}</div>
         <div class="title">{{detail.title}}</div>
     </div>
  </div>
    
</template>

<script>
export default {
  name: 'Details',
  data(){
    return{
        details:[
            {title:'تعداد کاربران',value:'',icon:'users'},
            {title:'ارزش معاملات انجام شده',value:'',icon:'coins'},
            {title:'تعداد معاملات',value:'',icon:'exchange'},
        ]
    }
  },
    methods: {
        async getInfo() {
            const res = await this.$axios('trades/market-info-home')
            this.details[0].value = res.baseDTO.userCount
            this.details[1].value = res.baseDTO.tradesValue
            this.details[2].value = res.baseDTO.tradesAmount
        }
    },
    mounted() {
        this.getInfo()
    }
}
</script>
<style scoped>
.detail{
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    justify-content: space-between;
}
.bg{
   position: absolute;
   z-index: 0;
   /* width: 100%; */
   top: -200px;
}
.details{
    display: flex;
    position: relative;
    justify-content: space-evenly;
    row-gap: 30px;
    column-gap: 30px;
    flex-wrap: wrap;
    margin-top: clamp(100px,13vw,170px);
}

.value{
    font-weight: bold;
    font-size: clamp(28px,3.2vw,36px);
    background: -webkit-linear-gradient(95.12deg, #143B74 -9.51%, #2B6BC9 104.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.title{
    font-weight: bold;
    font-size: clamp(18px,2.1vw,24px);
    color:#143B74;

}
</style>