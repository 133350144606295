import Vue from 'vue'
import App from './App.vue'
import './style/index.scss'
import './library/states.js'
import router from './library/router'
import {InlineSvgPlugin} from 'vue-inline-svg';
import VueRouter from 'vue-router'
import LabelInput from './components/tools/LabelInput'
import ToggleComp from './components/tools/ToggleComp'
import UnderLinedLink from './components/tools/UnderLinedLink'
import UnderlinedBtn from './components/tools/UnderlinedBtn'
import SwitchComponent from './components/tools/SwitchComponent'
import VerifyCodeInput from './components/tools/VerifyCodeInput'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueApexCharts from 'vue-apexcharts'
import TrendChart from "vue-trend-chart";
import './library/axios'
import './library/globalFunctions'
import './library/globalConstants'
import './library/georgianToJalali'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

Vue.use(PerfectScrollbar)
Vue.use(TrendChart);
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component('VerifyCodeInput',VerifyCodeInput)
Vue.component('SwitchComponent',SwitchComponent)
Vue.component('LabelInput',LabelInput)
Vue.component('UnderLinedLink',UnderLinedLink)
Vue.component('UnderlinedBtn',UnderlinedBtn)
Vue.component('ToggleComp',ToggleComp)
Vue.use(InlineSvgPlugin);
Vue.use(VueSweetalert2);
Vue.config.productionTip = false
Vue.use(VueRouter)
new Vue({
  router,
 
  render: h => h(App),
}).$mount('#app')


