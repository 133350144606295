import  General from '../components/general/General'
import History from '../components/panel/History/History'
import Login from '../components/auth/Login'
import SignUp from '../components/auth/SignUp'
import EmailOTP from '../components/auth/EmailOTP'
import TwoStepVerify from '../components/auth/TwoStepVerify'
import ForgetPass from '../components/auth/ForgetPass'
import ChangePass  from '../components/auth/ChangePass'
import Router from "vue-router";
import Panel from "../components/panel/Panel";
import Vue from 'vue'
import RedirectFromBank from  '../components/general/RedirectFromBank'
import FromGoogle from '../components/auth/FromGoogle.vue'

const that = Vue.prototype

const disabledRoutes = [
  'OTC',
  'Exchange',
]
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path:'/redirect-from-banks',
      name: 'RedirectFromBank',
      component: RedirectFromBank,
    },
    {
      path: '/fromgoogle',
      name: 'FromGoogle',
      component: FromGoogle
  },
    { 
      path: "/",
      name: "General",
      component: General,
      children:[
        {
          path: "FAQ",
          name: "FAQ",
          component: General,
          
        }, {
          path: "/",
          name: "home",
          component: General,
          
        },{
          path: "Terms",
          name: "Terms",
          component: General,
          
        },{
          path: "Commissions",
          name: "Commissions",
          component: General,
          
        },{
          path: "AboutUs",
          name: "AboutUs",
          component: General,
          
        },{
          path: "ContactUs",
          name: "ContactUs",
          component: General,
          
        },{
          path: "Markets",
          name: "Markets",
          component: General,
          
        },{
          path: "FastOrder",
          name: "FastOrder",
          component: General,
          
        },
       

      ]
    },
    {
      path: "/Login",
      name: "Login",
      component: Login,
    },{
      path: "/SignUp",
      name: "SignUp",
      component: SignUp,
      
    },{
      path: "/ForgetPass",
      name: "ForgetPass",
      component:ForgetPass,
      
    },{
      path: "/TwoStepVerify/:type",
      name: "TwoStepVerify",
      props:true,
      component: TwoStepVerify,
    },{
      path: "/reset-pass-email",
      name: "ChangePass",
      component: ChangePass,
    },{
      path: "/reset-pass-sms",
      name: "ChangePass",
      component: ChangePass,
    },{
      path: "/reset-pass-google-auth",
      name: "ChangePass",
      component: ChangePass,
    },{
      path: "/EmailOTP",
      name: "EmailOTP",
      component: EmailOTP,
    },
    {
      path: "/Panel",
      name: "Panel",
      component: Panel,
      children:[
        { 
          path: "P2P/TETHER/TETHER",
          redirect:'P2P/TOMAN/TETHER'
        }, 
        { 
          path: "P2P",
          redirect:'P2P/TOMAN/BITCOIN'
        },
        { 

          path: "P2P/:currency/:crypto",
          name: "P2P",
          props: true,
          component: Panel,

          
        },{
          path: "Authentication",
          name: "Authentication",
          component: Panel,
          
        },
          {
          path: "InviteFriends",
          name: "InviteFriends",
          component: Panel,
          
        },{
          path: "Settings",
          name: "Settings",
          component: Panel,
          
        },{
          path: "Dashboard",
          name: "Dashboard",
          component: Panel,
          
        },{
          path: "Notifications",
          name: "Notifications",
          component: Panel,
          
        },{
          path: "Wallet",
          name: "Wallet",
          component: Panel,
          
        },{
          path: "Exchange",
          name: "Exchange",
          component: Panel,
        },{
          path: "fino-verifying",
          name: "fino-verifying",
          component: Panel,
        },{
          path: "History",
          name: "History",
          component: Panel,
          children:[
              {
                path: "Orders",
                name: "Orders",
                component: History,

              },{
                path: "ActiveOrders",
                name: "ActiveOrders",
                component: History,

              }, {
                path: "Logins",
                name: "Logins",
                component: History,

              },{
                path: "Transactions",
                name: "Transactions",
                component: History,

              },
          ]
          
        },
      ]
      
    }
   
  ],
});

const forLogin = [
  'History',
  'Dashboard',
  'Panel',
  'P2P',
  'Exchange',
  'TransactionsCrypto',
  'TransactionsRial',
  'Trades',
  'Orders',
  'Wallet',
  'Settings',
  'ActiveOrders',
  'fino-verifying',
  'Notifications',
  'Orders',
  'Trade',
  'Transactions',

]
const unAuthorUser = [
  'P2P',
  'Exchange',
  'Transactions',
  'Trades',
  'Orders',
  'ActiveOrders',
  'Dashboard',
  'Wallet',
  
]
const otp = [
  'TwoStep',
]
const auth = [
  'Login',
  'SignUp',
  'ForgetPassword',
]
router.beforeEach(async (to, from, next) => {

  that.state.loading = true

  if(!that.state.coins){
    await that.$getCoins()
  }

  if (that.state.token) {

      if (!that.state.gotten) {
        that.state.loading = true
          await that.$getuserInfo()
          that.state.loading = true
          await that.$getFees()
      }

      that.state.loading = true
      if(that.state.userInfo.supervisor && disabledRoutes.includes(to.name)){
        that.$error('','با عرض پوزش قابلیت مورد نظر برای بازار گردان غیر فعال است')
          next(from)
      }
       if (from.name === 'Blog') {
          await window.open(to.fullPath,'_self')
      }
      else if (otp.includes(to.name) || auth.includes(to.name)) {
          next({name: 'home'})
      } 
      else if (to.name === 'P2P') {
        // if(!to.params.crypto || !to.params.currency){
        //   next('/Panel/P2P/TOMAN/BITCOIN')
        // }
        // else if(to.params.crypto=='TETHER' && to.params.currency=='TETHER' ){
        //   next('/Panel/P2P/TOMAN/TETHER')

        // }
        if(!that.state.userInfo.authorized){
           next({name: 'HomePage'})
        }
        
        else{
          try {
            that.state.loading = true
            next()
            // next(`/Panel/P2P/${to.params.currency}/${to.params.crypto}`)
        } catch (e) {
            if (e.response.data.message === "MARKET_IS_NOT_ACTIVE") {
                if (otp.includes(from.name)) {
                    next({name: 'HomePage'})
                } else {
                    await that.$error('بازار غیرفعال است')
                    next(from)
                }
            }
        }
        }
      } else {
          if (that.state.userInfo.authorized) {
              next()
          } else {
              if (otp.includes(from.name) && to.name !== 'Account') {
                  next({name: 'Account'})
              } else if (unAuthorUser.includes(to.name)) {
                  that.state.loading = false
                  const res = await that.$error('عدم دسترسی', 'برای دسترسی به این بخش نیاز است ابتدا حساب شما تکمیل و تایید شود', 'error', 'حساب کاربری')
                  if (res.isConfirmed) {
                      next('/Panel/Authentication')
                  } else {
                      next(from)
                  }
              } else next()
          }
      }
  } 
  else {
      if ((otp.includes(to.name) && !Vue.$cookies.isKey('username')) || forLogin.includes(to.name))
          next({name: 'Login'})
      else {
          next()
      }
  }
  that.state.loading = false
})

export default router;