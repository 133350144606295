<template>
   <div class="section">
            <div class="section-title">اطلاعات شخصی</div>
            <form class="input-fields">
                <LabelInput :disabled="lock"  @input="value=>data.firstName=$S2Fa(value)" :value="data.firstName" label='نام'/>
                <LabelInput :disabled="lock" @input="value=>data.lastName=$S2Fa(value)" :value="data.lastName" label='نام خانوادگی'/>
                <Calendar :disabled="lock" :isBirth="true" @input="value=>data.birthDay=value" :value="data.birthDay" label='تاریخ تولد'/>
                <LabelInput :disabled="lock" :maxLength="10" type="nochar" @input="value=>data.nationalCode=value"   :value='data.nationalCode' label='کدملی'/>
                <LabelInput  :maxLength="11" type="nochar"  @input="value=>data.mobileNumber=value" :value="data.mobileNumber" label='تلفن همراه'/>
                <LabelInput  :maxLength="11" type="nochar" @input="value=>data.telephone=value" :value="data.telephone" label='تلفن ثابت'/>
                <textarea v-model="data.address"  rows="3" placeholder="آدرس"/>           
            </form>
        </div> 
    
</template>

<script>
import Calendar from '../../tools/Calendar'
export default {
  name: 'PersonalFields',
  components:{
      Calendar
  },
   props: {
     data:Object,
     lock:Boolean
     },
  data(){
    return {
     
    }
  }
 
}
</script>
<style scoped>

textarea{
    width: 100%;
     background: #EDF1ED;
     
}

textarea:focus {
  outline: none !important;
 
}


</style>