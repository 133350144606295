<template>
<div class="auth-page">

  <AuthCard>
     <template #card-header>
         فراموشی رمز عبور
    </template>
     <template #card-body>
         <p class="text">
                 آدرس ایمیلی که با آن در بی بیتکس ثبت نام کردید را وارد کنید
         </p>
         <LabelInput @input="value=>{email=value;}"  label="آدرس ایمیل" />
     </template> 
    <template #card-footer>
          <button :class="{'authentication-confirm-btn':true,'disabled-btn':!email }" :disabled="!email" @click="send()">ثبت و ارسال کد بازیابی </button>
    </template>

  </AuthCard>
    </div>
</template>

<script>
import AuthCard from './components/AuthCard'
export default {
  name: 'OtpEmail',
  components:{
      AuthCard
  },
  data(){
    return{
       goto:{
          EMAIL: '/reset-pass-email',
          SMS: '/reset-pass-sms',
          GOOGLE_AUTHENTICATOR: '/reset-pass-google-auth',
       },
         email: '',
            emailError: '',
    }
  },
    methods: {
        checkEmail() {
            if (!this.email) {
                this.emailError = 'ایمیل اجباری';
            } else if (!this.validEmail(this.email)) {
                this.emailError = 'ایمیل صحیح وارد نمایید';
            } else {
                this.emailError = ''
            }
            if (!this.emailError.length) {
                return true;
            }
        },
        validEmail(email) {
            let re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            return re.test(email);
        },
        receive(e) {
            this.email = e
            this.checkEmail(e)
        },
        async send() {
            if (this.checkEmail(this.email)) {
                await this.checkEmail()
                this.state.loading = true
                const res = await this.$axios.post('/users/forget-password?email=' + this.email)
                this.$cookies.set('username', this.email)
                this.state.userInfo.email = this.email
                if (res.baseDTO) {
                    this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
                    this.state.userInfo.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
                } else {
                    this.state.userInfo.towStepAuthenticationType = 'EMAIL'
                }
                await this.$router.push(this.goto[res.baseDTO.towStepAuthenticationType])
            }
        }
    }
}
</script>
<style scoped>
.text{
    font-size: 12px;
    color:#4F4F4F

}

.disabled-btn{
    opacity: 0.7;
}
</style>
