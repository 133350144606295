<template>
<div class="auth-page">
  <AuthCard>
     <template #card-header>
         فراموشی رمز عبور
    </template>
     <template #card-body>
        <p class="text" v-if="pathMatch === 'email'">کد ۶ رقمی که به ایمیل
            {{$cookies.get('username')}} ارسال شده است را وارد نمایید.</p>
        <p class="text" v-else-if="pathMatch === 'sms'"> کد ۶ رقمی که به شماره
            {{state.userInfo.mobileNumber}} ارسال شده است را وارد
            نمایید.</p>
        <p class="text" v-else-if="pathMatch === 'google-auth'">کد ۶ رقمی سرویس
            Google Authenticator را وارد نمایید.</p>
         <VerifyCodeInput @change="e=>otp.code=e"/>
  <p class="text">یک رمز عبور جدید انتخاب کنید
         </p>
         <LabelInput @input="value=>{otp.password=value;}" type='password' label="رمز عبور جدید" />


     </template> 
     <template #card-footer>
         <button v-if="countDown===0" @click.prevent="send()" type="submit" class="login-btn" >
ارسال مجدد                 </button>
         <button :class="{'authentication-confirm-btn':true,'disabled-btn':!otp.password || !otp.email }" :disabled='!otp.password || !otp.email' @click="submit()">ثبت</button>
     </template>

  </AuthCard>
</div>  
</template>

<script>
import AuthCard from './components/AuthCard'
export default {
  name: 'OtpEmail',
  components:{
      AuthCard
  },
  data(){
    return{ 
            pathMatch:'',
            passError:'',
            countDown: 120,
            num: 6,
            otp: {
                code: '',
                email: this.state.userInfo.email || this.$cookies.get('username') || '',
                password: ''
            }
    }
  },
  computed: {
        disable() {
            return !this.otp.password || this.passError !== '' || this.otp.code.length !== 6 || (this.pathMatch !== 'google-auth' && this.countDown === 0) || this.state.loading
        }
    },
    methods: {
        showPass() {
            if (this.type === "password") {
                this.type = 'text'
            } else {
                this.type = 'password'
            }
        },
        checkPass() {
            if (!this.otp.password) {
                this.passError = 'رمز اجباری';
            } else {
                if (this.otp.password.length < 8) {
                    this.passError = 'حداقل 8 کاراکتر';
                } else if (!this.validPass(this.otp.password)) {
                    this.passError = 'ترکیبی از حروف کوچک و بزرگ و عدد';
                } else {
                    this.passError = ''
                }
            }
            if (!this.passError.length) {
                return true;
            }
        },
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        onChange(e) {
            this.otp.code = e
        },
        validPass(password) {
            let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
            return re.test(password);
        },
        async submit() {
          console.log('check1')
            if (this.checkPass(this.otp.password) && !this.disabled) {
                this.state.loading = true
                let [res, ] = await this.$http.post('/users/reset-password', this.otp)
                // this.reset()
                 console.log('check2')

                if (res) {
                  console.log('check3')

                    this.$error('رمز عبور با موفقیت تغییر یافت.', '', 'success')
                    await this.$router.push('/login')
                }
            }
            else if(this.passError){
               this.$error(this.passError, '', 'error')

            }
        },
        async send() {
            this.state.loading = true
            // this.reset()
            await this.$axios.post('/users/forget-password?email=' + this.otp.email)
            this.$error('کد جدید ارسال شد.', '', 'success')
            this.$cookies.set('username', this.state.userInfo.email)

            if (this.countDown === 0) {
                if (this.state.userInfo.towStepAuthenticationType !== 'GOOGLE_AUTHENTICATOR')
                    this.countDown = 120
                this.countDownTimer()
            }
        },
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
    mounted() {
        this.pathMatch=this.$route.path.replace('/reset-pass-','')
        // this.setPlaceholder()
        if (!this.$cookies.isKey('username')) {
            this.$router.push('/login')
        }
        if (this.pathMatch !== 'google-auth') {
            this.countDownTimer()
        }
    }
}
</script>
<style scoped>
.text{
    font-size: 12px;
    color:#4F4F4F

}

.disabled-btn{
    opacity: 0.7;
}
</style>