<template>

      <div class="markets"> 
       <div class="crypto-filter" >
            <label class="label">رمزارز</label>
            <input placeholder="جستجو ..."   @input="(e)=>regex=e.target.value" />
            <img  src='../../assets/icons/search.svg' />
        </div>
          <table>
             <tr class="head">
                 <td class="frst-head" align="right"> 
                     <div class="togglecomp">
                        <ToggleComp  @choose="(choice)=>{toman_tether=choice}" :items="[{label:'تومان',value:'TOMAN'},{label:'تتر',value:'TETHER'}]"/>
                      </div>
                </td>
                 <td align="center">تغییرات </td>
                 <td  align="center">قیمت جهانی</td>
                 <td class="resp-3" align="center">قیمت خرید</td>
                 <td class="resp-2" align="center" >قیمت فروش </td>
                 <td class="resp-1" align="center" >نمودار</td>
                 <td class="resp-1" align="center" >عملیات</td>
             </tr>
             <tr class="row" v-for="(crypto,index) in filteredCryptos" :key="index">
                 <td><div class="title"><img class="icon resp-1" :src="require('../../assets/coins/'+crypto.relatedCoin+'.svg')"/>{{$coinLabel[crypto.relatedCoin]}}</div></td>
                 <td  align="center"><span class="change" :class="{red:crypto.last24HourChange<0,green:crypto.last24HourChange>0}"><span v-if="crypto.last24HourChange>0">+</span>{{Math.abs(crypto.last24HourChange).toFixed(2)}} %</span></td>
                 <td  align="center"><span >{{$tolocal(crypto.lastPrice,toman_tether=='TOMAN'?0:2)}}</span></td>
                 <td  class="resp-3" align="center"><span >{{$tolocal(crypto.buyPrice,toman_tether=='TOMAN'?0:2)}}</span></td>
                 <td class="resp-2" align="center"><span >{{$tolocal(crypto.sellPrice,toman_tether=='TOMAN'?0:2)}}</span></td>
                 <td class="resp-1 " align="center"><CoinChart :data="crypto.lastWeekData"/> </td>
                <td class="resp-1 " align="center"><UnderLinedLink :href="state.token?'Panel/P2P/TOMAN/'+$coin2Snake[crypto.relatedCoin]:'login'" >معامله</UnderLinedLink></td>
             </tr>
          </table>
      </div>
  
</template>

<script>
import CoinChart from './CoinChart'
export default {
  name: 'Markets',
  components:{
      CoinChart
  },
  data(){
      return{
          cryptos:[],
          regex:'',
          cryptoName:"",  
          toman_tether:"TOMAN",
          loading:false,
          alive:false
      }
  },
   watch: {
          
           
        },
  computed:{
      filteredCryptos(){
         return this.cryptos.filter(item=>{
                    if (item.marketType.includes(this.toman_tether) && (item.relatedCoin.includes(this.regex.toUpperCase())
                     || this.$coin2Snake[item.relatedCoin].includes(this.regex.toUpperCase()) || this.$coinLabel[item.relatedCoin].includes(this.regex)))
                            return item
        })

      }
  },
  methods: {
            
            async getPrice() {
                let res= await this.$axios.get('/trades/market-info',{
                        params: {    
                            weeklyDataIncluded: true
                        }
                    }).then(response=>res=response.content)                   
                if (res) {
                    this.cryptos = res
                }
                // this.setCryptos()
            },
          
            // goto(e) {
            //     this.$router.push('/trade/' + (this.toman ? 'TOMAN' : 'TETHER') + `/${e}`)
            // },
            // sort() {
            //     this.tableContents.sort((a, b) => this.$sortCoins.indexOf(a.sourceCoinSymbol) - this.$sortCoins.indexOf(b.sourceCoinSymbol))
            // },
            // async loop() {
            //     this.loading = true
            //     const tab = this.toman
            //     while (this.alive && tab === this.toman) {
            //         await this.getPrice().catch(() => {
            //         })
            //         this.loading = false
            //         await this.$sleep(5000)
            //     }
            // },
            // async tab() {
            //     this.tableContents = []
            //     this.loading = true
            //     await this.beforeLoop()
            //     this.loading = false
            // },
            // async beforeLoop() {
            //     // if (this.state.loop)
            //     //     this.loop()
            //     // else {
            //         this.loading = true
            //         await this.getPrice().catch(() => {
            //             this.loading = false
            //         })
            //         this.loading = false
            //     // }
            // }
        },
        mounted() {
            this.alive = true
            // this.beforeLoop()
            this.getPrice()
        },
        beforeDestroy() {
            this.alive = false
        }
    ,

//   methods:{
//       filterItems(){
//         //   this.cryptos.filter(crypto=>{
//         //       if(crypto.marketType.includes(this.toman_tether))
//                 return this.cryptos
//         //   })
//       },
//    

//   }

  
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.table-header{
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    width: 100%;
}
.togglecomp{
    max-width: 250px;
}
.label {
    color: var(--gray3);
    font-size: clamp(12px, 1.1vw, 14px);
    padding-left: 3px;
}

.crypto-filter {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: white;
    border-radius: 6px;
    max-width: 250px;
    padding: 8px;

}
.filter-drop{
    width: 100%;
    max-width: 380px;
}
input{
    width: 100%;
}
.searchbtn{
    background: #F29A39;
    border-radius: 2px;
    display: flex;
    padding: 4px;
    align-items: center;

}

.change{
    display: flex;
    direction: ltr;
    justify-content: center;
  
}
.chart-container{
    display: flex;
    /* width: 100px; */

    align-items: center;
}
.markets{
    padding-top: clamp(80px,12vw,130px);
}
.chart{
    vertical-align: inherit;
    max-height: 60px;
}


.title{
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.red{
    color:rgba(214, 43, 43, 1); ;

}

.markets-link{
    text-align: center;
    margin-top: 16px;
}

.symbol{
    color: rgba(30, 37, 48, 0.6);
    margin-right:4px;


}

.icon{
    margin-left:8px;
    width: 30px;
    height: 30px;
}
.green{
    color:rgba(11, 135, 16, 1);

}

.head{
    background: transparent;
    color: #828282;
    height: 41px;;
     font-size:clamp(13px,1.3vw,14px);
    font-weight: 400px!important;
}

.row{
    background: #FAFCFF;
    color:  #4F4F4F;
    height: 64px;
     font-size:clamp(15px,1.6vw,18px);

    
}
td{
       font-weight: 400px!important;
}

table{
    margin-top: 26px;
    border-collapse:collapse;
    width: 100%;
}


table tr:last-child td:first-child {
    border-bottom-right-radius: 4px;
}

table tr:last-child td:last-child {
    border-bottom-left-radius: 4px;
}
table tr:nth-child(2) td:first-child {
    border-top-right-radius: 4px;
}

table tr:nth-child(2) td:last-child {
    border-top-left-radius: 4px;
}

.row td:first-child{
    padding-right: clamp(15px,2.3vw,35px);
}

@media(max-width:900px){
    .resp-1{
        display: none;
    }
}
@media(max-width:550px){
    .resp-2{
        display: none;
    }
}

@media(max-width:400px){
    .resp-3{
        display: none;
    }
}
</style>