<template>
<div class="auth-page">

    <AuthCard>
        <template #card-header>ثبت نام</template>

        <template #card-body>
            <LabelInput @input="value=> {data.email=value;emailError=''}" :errorText="emailError" label="آدرس ایمیل" />
            <LabelInput @input="value=> {data.password=value;passwordError=''}" :errorText="passwordError" type='password' label="رمز عبور" />
            <LabelInput @input="value=> data.referralCode=value" label=" کد دعوت (اختیاری)" />
        </template   >
        <template #card-footer>
            <div class="terms">
                <div class="checkbox" @click="agree=!agree"><i class='fas fa-check fa-lg tik' v-if="agree" src='' /></div><span>قوانین و مقررات بودکس را می پذیرم</span>
            </div>
            <div class="recaptcha-container">
                <vue-recaptcha sitekey="6LcHVO8fAAAAAFBK07N73tyHqb-SzraYJ-hsZcbS" ref="recaptcha" @verify="recaptcha=true" :loadRecaptchaScript="true"></vue-recaptcha>
            </div>
            <button :class="{'authentication-confirm-btn':true,'disabled-btn':!data.email ||!data.password || !agree || !recaptcha }"
             :disabled='!data.email || !data.password || !agree  || !recaptcha' @click="send()">ثبت نام</button>
            <GoogleBtn />
        </template>

    </AuthCard>

    <div class="suggest">قبلا ثبت نام کرده اید؟ <UnderLinedLink href='/Login'> وارد شوید</UnderLinedLink>
    </div>

</div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import AuthCard from './components/AuthCard'
import GoogleBtn from './components/GoogleBtn'
export default {
    name: 'SignUp',
    components: {
        AuthCard,
        GoogleBtn,
        VueRecaptcha

    },
    data() {
        return {
            recaptcha: false,
            agree: false,
            data: {
                email: '',
                password: '',
                referralCode: '',
                //   ipAddress:'',
                deviceType: '',
                osType: '',
                browser: ''
            },
            emailError: '',
            passwordError: ''

        }
    },
    computed: {
        disable() {
            return !this.data.email || !this.data.password || this.emailError.length > 0 || this.passError.length > 0 || !this.agree
        },
        readOnly() {
            return localStorage.referralCode || this.$route.query.ref
        }
    },
    methods: {

        googleSso() {
            window.location.href = this.$google_sso
        },
        async send() {
            try {

                await this.checkEmail()
                await this.check2Pass()

                this.state.loading = true
                const res = await this.$axios.post('/users/register', this.data)
                if (res.message === 'user created successfully , verification email sent') {
                    if (res.baseDTO.towStepAuthenticationType === 'EMAIL') {
                        this.$cookies.set('username', this.data.email)
                        this.state.userInfo.email = this.data.email
                        await this.$router.push('/TwoStepVerify/Email')
                    }
                } else if (res.token) {
                    this.$setToken(res.token, this.state.userInfo.remember)
                    await this.$router.push('/Panel/Dashboard')
                }
            } catch (e) {
                console.log(e)
            }
        },
        check2Pass() {
            if (!this.$validPass(this.data.password) || this.data.password.length < 8) {
                this.passwordError = 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد'
                this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                return Promise.reject('wrong pass : ' + this.data.password)
            } else {
                this.passwordError = ''
            }
        },
        checkEmail() {
            if (!this.$validEmail(this.data.email) || !this.data.email) {
                this.emailError = 'ایمیل صحیح وارد نمایید'
                this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                return Promise.reject('repeat failed: ' + this.data.email)
            } else {
                this.emailError = ''
            }
        },

        getData() {
            this.data.osType = this.$detectOs()
            this.data.browser = this.$detectBrowser() || 'CHROME'
            this.data.deviceType = this.$detectDevice()
        },

    },
    mounted() {
        this.getData()
        if (this.$route.query.ref || localStorage.referralCode) {
            localStorage.referralCode = this.$route.query.ref || localStorage.referralCode
            this.data.referralCode = this.$route.query.ref || localStorage.referralCode
        }
    }

}
</script>

<style scoped>
.terms {
    font-size: clamp(13px, 1.2vw, 14px);
    color: #4F4F4F;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 12px;
    align-items: center;
    font-weight: bold;

}

.captcha-container {
    display: flex;
    justify-content: center;
}

.disabled-btn {
    opacity: 0.7;
}

.checkbox {
    width: 16px;
    height: 16px;
    background: #F2F2F2;
    margin-left: 4px;
    position: relative;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(199, 199, 199, 0.22);
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;
}

.tik {
    position: absolute;
    margin-top: 6px;
}

.suggest {
    display: flex;
    justify-content: center;
    font-size: 14px;
}

.text {
    font-size: 12px;
    color: #4F4F4F
}
</style>
