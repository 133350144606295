<template>
<div class="switch">
    <div :class="{active:choice==item.value}" v-for="(item,index) in items " 
    :key="index" @click="choice=item.value;$emit('choose',choice)" >
     {{item.label}}
    </div>
</div>
  
</template>

<script>
export default {
  name: 'SwitchComp',
  props:{
      items:{type:Array},
    
  },
  data(){
      return {
          choice:this.items[0].value
      }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.switch{
    /* width: 100%; */
    flex-grow: 1;
    display: flex;
    font-size: clamp(12px,1vw,14px);
    flex-wrap: wrap;
    color: var(--gray3);
    border-radius: 6px;
    padding: 3px;
    background: #DFF4F4;
    box-shadow: inset 0px -2px 13px rgba(201, 201, 201, 0.25), inset -3px 2px 9px rgba(116, 116, 116, 0.25);
    border-radius: 6px;
}
.switch > div{
      flex: 1;
      cursor: pointer;
      display: flex;
      white-space: nowrap;
      align-items: center;
      justify-content: center;
    
}
.active{
 background: #FAFCFF;
border-radius: 2px;
    color: var(--gray2);
    padding:5px 0px;
    
}

</style>