<template>
    <div class="historyPage">
 

   <ActiveOrders v-if="$route.name=='ActiveOrders'"/>
   <Orders  v-if="$route.name=='Orders'"/>
   <Logins  v-if="$route.name=='Logins'"/>
   <Transactions  v-if="$route.name=='Transactions'"/>


 </div>
    
</template>

<script>
import ActiveOrders from './ActiveOrders'
import Orders from './Orders'
import Logins from './Logins'
import Transactions from './Transactions'
export default {
  name: 'History',
  components:{
      ActiveOrders,
      Orders,
      Logins,
      Transactions
  },
  data(){
  return{
         
      
    }
  }
}
</script>
<style scoped>

</style>