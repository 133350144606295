<template>
    <div class="value-slider">

      <div class="slider-fill" :style="{backgroundImage:`linear-gradient( to right, rgba(250, 252, 255, 1) 0%,  rgba(250, 252, 255, 1)  ${percent}%, rgba(223, 244, 244, 0) ${percent}%)`}"   >
      <input type="range" @input="(e)=>{changeHandler(e)}" />
      <span  v-for="i in 5" :key="i" :class="{'slider-tik-active':(i-1)*25<=percent}">{{(i-1)*25}}%</span>
      <img src="../../../assets/public/slider-arrow.svg" class="pointer" :style="{left:percent+'%'}"/>

      </div>
     </div>
     
</template>

<script>
export default {
  name: 'ValueSlider',
  data(){
    return{
        percent:0
      
    }
  },
  methods:{
    changeHandler(e){
      this.percent=e.target.value
      this.$emit('change',this.percent)
    }
  }
}
</script>
<style scoped>

.slider-tik-active{
    color:var(--gray3);
   

}
input[type="range"]:focus {
    outline: none;
}

input[type="range"] {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    -webkit-appearance: none;

  
  display: block;
  margin: 0;
  z-index: 10;
 width:100%;

  background: transparent;
}

.slider-fill{
    display: flex;
    position: relative;
    align-items: center;
    padding: 0px 3px;
    justify-content: space-between;
    /* background: #FAFCFF; */
    height: 100%;
    border-radius: 2px;
    width: 100%;
    
}

.pointer{
   position: absolute;
   margin-left: -10px;
   z-index: 0;
   
}
.value-slider{
    position: relative;
    direction: ltr;
    font-size: 10px;
    color: var(--gray5);
    display: flex;align-items: center;
    justify-content: space-around;
    max-width: 211px;
    flex-grow: 1;
    padding: 2px 4px;
    height: 28px;
    background: #DFF4F4;
box-shadow: inset 0px -2px 13px rgba(201, 201, 201, 0.25), inset -3px 2px 9px rgba(116, 116, 116, 0.25);
border-radius: 6px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  cursor: pointer;
  height: 15px;
  width: 15px;
  position: relative;
  /* z-index: 2;
  margin-left: -5px; */
  background: url('../../../assets/public/slider-arrow.svg') ;
  background-position: center;
  background-repeat: no-repeat;
}


input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  /* width: 100%; */
  color: transparent;

  border: none; 
}
</style>
