<template>
<div>
    <div class="page-header">  تماس با ما </div>
    <div class='contact-container'>
        <div class="right-sec">
        <p class="text">برای ارتباط با بدکس از راه های ارتباطی زیر در ارتباط باشید</p>
        <div class="details">
            <div class="detail"><img src='../../assets/icons/phone.svg'/><div>تلفن تماس</div><span>{{info.number}}</span></div>
            <div class="detail"><img src='../../assets/icons/email.svg'/><div>ایمیل</div><span>{{info.email}}</span></div>
            <div class="detail"><img src='../../assets/icons/location.svg'/><div>آدرس</div><span>{{info.address}}</span></div>
        </div>
        <div class="clock-container">
        <div class="open-clock">
            <img src='../../assets/icons/clock.svg'/>
            <span>ساعت پاسخ گویی</span>
            <span>همه روزه از 8 الی 22</span>
        </div>

        </div>
        </div>
    <div class="contact-img">
    <img src='../../assets/public/contactus.png'/>

    </div>
    </div>

</div>
  
    
</template>

<script>
export default {
  name: 'ContactUs',
  data(){
    return{
                info: {
                address: "",
                appAndroid: "",
                appIOS: "",
                email: "",
                instagram: "",
                linkedin: "",
                number: "",
                telegram: "",
                twitter: ""
            }
    }
  },
      mounted() {
        this.getInfo()
    },
    methods: {
        async getInfo() {
            const res = await this.$axios.get('/exchange-info')
            this.info = res.baseDTO || {
                address: '',
                appAndroid: '',
                appIOS: '',
                email: '',
                instagram: '',
                linkedin: '',
                number: '',
                telegram: '',
                twitter: ''
            }
        },

    }
}
</script>
<style scoped>
.clock-container{
   
    flex: 10;
    display: flex;
    align-items: flex-end;
}
.right-sec{
    flex-grow: 1;
}
.contact-img{
   flex-grow: 1;
   display: flex;
   justify-content: flex-end;
   
}

.contact-img  >img {
    width: 100%;
    max-width: 530px;
    height: auto;

}
.contact-container{
    padding: 24px;
    background:#FAFCFF ;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.open-clock{
    display: flex;
    align-items: flex-start;
    color: #222222;
    font-size: clamp(11.6px,1.3vw,14px);
    margin-top: 100px;


column-gap: 16px;}

.detail{
    display: grid;
    grid-template-columns: 0fr 1fr 3fr;
    column-gap: 5px;
    font-size: clamp(14px,1.6vw,18px);
    width: 100%;
    max-width: 432px;
   
    
}
.text{
    font-size: clamp(15px,1.6vw,18px);
    
}
.details{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-top: 50px;
}

.detail > img{
    padding-left: 10px;
}


.detail > span{
    color:#333333;
}
.detail > div{
    color:#4F4F4F;
    white-space: nowrap;
}

</style>