<template>
<div>
    <div  class="page-header">چرا در بودِکس خرید و فروش کنیم؟</div>
    <div class="advantages">
        <div  v-for="(advantage,index) in advantages" :key="index" class="advantage">

          <div class="advantage-header">
                <img :src="require('../../../assets/public/'+advantage.icon+'.svg')"/>
                <div>{{advantage.title}}</div>
            </div>
        <div class="reason">بکارگیری به‌روزترین پروتکل‌ها و استانداردهای امنیتی روز جهان</div>
        </div>
       
    </div>
</div>
  
    
</template>

<script>
export default {
  name: 'Advantages',
  data(){
    return{
        advantages:[
            {title:'امن و مطمئن',icon:'fingerprint'},
            {title:'امن و مطمئن',icon:'fingerprint'},
            {title:'امن و مطمئن',icon:'fingerprint'},
            {title:'امن و مطمئن',icon:'fingerprint'},
        ]
      
    }
  }
}
</script>
<style scoped>
.advantage{
    /* margin: auto; */
    min-width: 170px;
    min-height: 170px;
    max-width: 230px;
    max-height: 230px;
    /* border: 1px solid; */
    height: 60vw;
    width: 60vw;
    background: #FCFCFC;
    box-shadow: 0px 2px 12px rgba(165, 147, 255, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    color: #143B74;
    transition: border-radius 1s max-height 1s max-width 1s   ;
}

.advantage-header{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    font-weight: bold;
    background: -webkit-linear-gradient(0deg, #143B74 -9.51%, #2B6BC9 104.28%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: clamp(17px,2vw,24px);
    transition: font-size 1s;
    align-items: center;
}
.advantage-header > img{
    height: auto;
    width: 71%;
}
.advantages{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    /* grid-template-rows: repeat(auto-fill, minmax(250px, 1fr)); */

    justify-items:center;
    align-items: center;
   
    column-gap: 20px;
    row-gap: 20px;

}


.reason{
    font-size: clamp(12px,1.3vw,14px);
     background: -webkit-linear-gradient(0deg, #143B74 -9.51%, #2B6BC9 104.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    /* visibility: hidden; */
    /* display: none; */
    opacity: 0;
    transition: opacity 1s  ;
}

.advantage:hover{
    border-radius: 8px;
    row-gap: 5px;
    min-width: 0px;
    min-height: 0px;
    max-width: 286px;
    max-height: 163px;
}
.advantage:hover > .reason{
     /* visibility: visible;
     display: block; */
    opacity: 1;
}
.advantage:hover > .advantage-header{
     font-size: clamp(15px,1.6vw,18px);
    row-gap: 7px;


}
.advantage:hover > img{
     height: 56px;


}

</style>