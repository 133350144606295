<template>
<div>
    <div class="page-header"> اعلانات</div>
    <div class="notifications">
        <div v-for="(notification,index) in notifications" :key="index" class="notification" @click="readMessage(notification.id,index)" >
            <div class="notification-row" >
               <div class="title-group"><div><img  v-if="!notification.read" src="../../assets/public/notification-circle.png"/></div><span>{{notification.title}}</span></div> 
               <div class="item-group"><span>{{$G2J(notification.createdAtDateTime)}}</span><img :class="{resp:true,'drop-open':openedIndex==index}"  src='../../assets/icons/arrowdown.svg'/>  </div>
            </div>
            <div v-if="openedIndex==index" class="description">
                <div>{{notification.content}}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'Notifications',
  data(){
    return{
        openedIndex:null,
        notifications:[]
      
    }
  },
  methods:{
       async getUnreadMessages() {
                const res = await this.$axios.get('/users/notifications',
                    {
                        params: {
                            page: 1, size: 13,
                        }
                    })
                if(res.message !== 'No Notifications'){
                    this.notifications.push(...res.content)
                    this.unreadedCount = res.totalElements
                }
                else{
                    this.notifications = []
                    this.unreadedCount = 0
                }
            },
            async readMessage(id,index) {
                this.openedIndex=(this.openedIndex==index)?null:index;
                if (!this.notifications[index].read){
                    this.notifications[index].read = true
                    await this.$axios.put(`/users/notifications/read/${id}`)
                }
                this.$forceUpdate()
            },
  },
  mounted(){
           this.getUnreadMessages()
  }
}
</script>
<style scoped>

.notification{
background: #FCFCFC;
box-shadow: 2px 2px 9px rgba(186, 185, 185, 0.27);
border-radius: 6px;
cursor: pointer;
width: 100%;
}

.notification:hover{
    background: #E9EBFF;

}
.notifications{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.description{
    font-size: 14px;
   padding: 0px clamp(20px,3.6vw,60px) 10px clamp(18px,2.1vw,24px);
   color: #504B4B;
   word-break: break-all;
}

.drop-open{
    transform: rotate(180deg);
}

.notification-row{
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    padding: clamp(14px,1.6vw,18px)  clamp(13px,1.9vw,24px);
    font-size:clamp(14px,1.5vw,16px);
    color: #222222;
}

.item-group{
    display: flex;
    align-items: center;
    column-gap: clamp(5px,1.5vw,17px);
}
.title-group{
    display: grid;
    grid-template-columns: 2fr 10fr;
    align-items: center;   
    column-gap: clamp(5px,1.5vw,17px);
}

@media(max-width:382px){
    
    .resp{
        display: none;
    }

}
</style>