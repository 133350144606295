<template>
<div class="invite-page">
    <div>
        <span class="page-title">دعوت از دوستان</span>
        <div class="links section"> 

            <div class="link-container">
                <div  class="link-label">
                   <span v-if="!codeAlertShow"> کد دعوت</span><span v-else>کپی شد</span><div @click="copyCode()" class="copy"/>
                </div>
                <span class="invite-code">{{inviteData.referralCode}}</span>

            </div>
            <div class="link-container">
                <div class="link-label">
                   <span v-if="!linkAlertShow"> لینک دعوت</span><span v-else>کپی شد</span><div @click="copyLink()" class="copy"/>
                </div >
                <span class="invite-link">bodex.com/SignUp?refer_code={{inviteData.referralCode}}</span>
                    
            </div>
        </div>
    </div>
    <div >
        <span class="page-title">آمار</span>
        <div class="statics section">
                <p>ﺷﻤﺎ ﻣﯽ‌ﺗﻮاﻧﯿﺪ دوﺳﺘﺎن ﺧﻮد را ﺑﺎ ﻟﯿﻨﮏ اﺧﺘﺼﺎﺻﯽ ﺧﻮد ﺑﻪ ﺑﯽ ﺑﯿﺘﮑﺲ دﻋﻮت ﮐﻨﯿﺪ.
             درﺣﺎل ﺣﺎﺿﺮ ﺑﺮاﺳﺎس اﯾﻦ ﻃﺮح،%30 از ﮐﺎرﻣﺰد
              ﺗﻤﺎﻣﯽ ﻣﻌﺎﻣﻠﺎت ﮐﺴﺎﻧﮐ ﯽﻪ ﺑﺎ ﻟﯿﻨﮏ ﺷﻤﺎ ﺛﺒﺖ ﻧﺎم ﮐﺮده‌اﻧﺪ ﺑﻪ ﺷﻤﺎ ﭘﺮداﺧﺖ ﺧﻮاﻫﺪ ﺷﺪ. 
        </p>
        <div class="details">
            <div class="detail">
                <img src="../../assets/public/friends.png"/>
                <div class="measure"><span class="value">{{inviteData.countInvitees}}</span>نفر</div>
                <div class="detail-label">تعداد دوستان شما</div>

            </div>
            <div class="detail">

                <img src="../../assets/public/moneybag.png"/>
                <div class="measure"><span class="value">{{inviteData.profit.toLocaleString()}}</span>تومان</div>
                <div class="detail-label">مجموع سود از دوستان دعوت شده</div>

            </div>

        </div>


        </div>
    
    </div>
</div>
  
    
</template>

<script  >
export default {
  name: 'InviteFriends',
  data(){
    return{
        link:'',
        code:'',
        profit:'',
        friendsNumber:'',
        codeAlertShow:false,
        linkAlertShow:false,
        inviteData:{countInvitees:'',profit:'',referralCode:''}
      
    }
  },
  methods:{
      copyLink(){
            navigator.clipboard.writeText(this.link)
           this.linkAlertShow=true;
         setTimeout(()=>{this.linkAlertShow=false;},2000)
      } ,
      copyCode(){
            navigator.clipboard.writeText(this.code)
           this.codeAlertShow=true;
         setTimeout(()=>{this.codeAlertShow=false;},2000)
      },
  async getData(){
      
       const res = await this.$axios.get('/users/invites');
       this.inviteData=res.baseDTO;
       this.link='bodex.com/SignUp?refer_code='+this.inviteData.referralCode
       this.code=this.inviteData.referralCode
       
       console.log(res)
  }
  },
  mounted(){
      this.getData()
  }
}
</script>
<style lang="scss" scoped>
.links{
   
    padding: 15px 24px 24px 0px;
    font-family: Arial, Helvetica, sans-serif!important;
}
  .details{ 

        display: flex;
        justify-content: space-around;
        column-gap: 30px;
        row-gap: 30px;
        flex-wrap: wrap;

        .detail{ 
            padding-right: 20px;


            .detail-label{
                color: var(--gray2);
            }
            .measure{
                color: #828282;
                .value{
                    font-size: clamp(18px,2.1vw,24px);
                    font-weight: bold;
                    color: var(--gray2);
                    padding-left:10px
                }
            }

        }

    }
.invite-page{
    display: flex;
    flex-wrap: wrap;
   
    padding-bottom: 50px;
    column-gap: 50px;
.statics{
    max-width: 830px;
   
  
}    
    
.link-container{
    padding-bottom: clamp(70px,9vw,113px);
    row-gap: 11px;
    display: flex;
    flex-direction: column;

    .link-label{
        display: flex;
        align-items: center;
        column-gap: 8px;
         color: var(--gray2);
        font-size: clamp(14px,1.4vw,16px);
        
.copy{
    content: url('../../assets/icons/copy.svg');
    padding: 7px;
    border-radius: 4px;
    background: #DFF4F4;
}


    }
    
.invite-code{
     
    font-size: clamp(18px,2.3vw,28px);
    font-weight: bold;
}

.invite-link{
    font-size: 14px;
    color: #404040;
}
}


}

@media(max-width:412px){
    .details{
        justify-content: space-between ;
       
    }
}
</style>