<template>
<Modal @close="$emit('modalToggle','')">
    <template #header>
        <div>برداشت موجودی تومانی</div>
    </template>
    <template #body>
        <div class="description">
            <div>لطفا مقدار درخواستی و شماره شبای خود را وارد کنید</div>
            <div class="description-wallet">{{$toLocal(cash,0)}}
                <div>تومان</div> <img src="../../assets/icons/dark-wallet.svg" />
            </div>
        </div>
        <LabelInput @input='value=>amount=value' type="number" label=" میزان برداشت" />
        <p class="error">{{errorHandler}}</p>

        <div class="card-select">
            <LabelInput @input='value=>regex=value' :value="walletAddress" label=" شماره کارت" @click="show=true"  :disabled="true"/>
            <div class="cards-drop" v-if="show" :style="{height:cardFilter.length*30+'px'}">
                <div class="card-row" v-for="(card,index) in cardFilter" :key="index" @click="chooseCard(card.cardNumber)">
                    {{card.cardNumber}}
                </div>
            </div>
        </div>
        

        <p>در صورت اطمینان از صحت درخواست خود روی گزینه دریافت ایمیل کلیک کنید تا برایتان ارسال شود و پس از ورود کد درخواست خود را ثبت کنید</p>
        <div :class="{'label-input':true }">
            <label>کد تایید دوعاملی</label>
            <div class="input-container">
                <input type="text" v-model="TwoStep_code" :disabled="disabled"/>
                <button class="email-btn" v-if="otpInput===false" @click="withdraw()" :class="{disabled:disabled}" :disabled="disabled">
                    ارسال کد تایید
                </button>
                <div class="email-btn" v-if="otpInput===true && countDown>0 && counter" :class="{disabled:disabled}">
                    {{Math.floor(countDown/60)}}:{{countDown%60}}
                </div>
                <button v-if="otpInput===true && countDown===0 && counter" class="email-btn" @click.prevent="send()">
                    ارسال مجدد
                </button>
            </div>

        </div>
        <button class="modal-confirm-btn" :class="{disabled:!otpInput || countDown==0 || TwoStep_code.length<6}" :disabled="!otpInput || countDown==0 ||TwoStep_code.length<6" @click="submitWithdrawRequest()">ثبت درخواست برداشت</button>
    </template>
</Modal>
</template>

<script>
import Modal from './Modal'
export default {
    name: 'TomanWithdraw',
    components: {
        Modal
    },
    props: {

        cash: {
            default: function () {
                return 0
            }
        },
        wage: {
            default: 0
        },

    },

    computed: {
        cardFilter() {
            if (this.banks)
                return this.banks.filter(card => card.cardNumber.replace('-', '').startsWith(this.regex))
            else
                return []
        },
        disabled() {
            return !this.$S2N(this.amount) ||
                !this.walletAddress ||
                (this.$S2N(this.amount) > this.$S2N(this.cash)) ||
                (this.$S2N(this.amount) > this.$S2N(this.max)) ||
                (this.$S2N(this.amount) < this.$S2N(this.min))
            // ( !this.enable )
            // (!this.selected_net)
        },
        errorHandler() {
            if ((this.$S2N(this.amount) > this.$S2N(this.cash)))
                return 'موجودی کافی نمیباشد'
            else if ((this.$S2N(this.amount) < this.$S2N(this.min)))
                return 'مقدار برداشت باید حداقل' + this.$coins[this.relatedCoin].code + ' ' + this.min + 'باشد'
            else if ((this.$S2N(this.amount) > this.$S2N(this.max)))
                return 'مقدار برداشت باید حداکثر ' + this.$coins[this.relatedCoin].code + ' ' + this.max + ' باشد '
            else
                return ''

        },

    },
    data() {
        return {
            banksOptions: false,
            unit: "تومان",
            amount: 0,
            banks: [],
            regex: '',
            walletAddress: '',
            TwoStep_code: '',
            towStepAuthenticationType: '',
            transaction: {
                code: '',
                amount: '',
                relatedCoin: 'TOMAN',
                destinationWalletAddress: ''
            },
            show:false,
            countDown: 120,
            otpInput: false,
            min:0,max:0

        }
    },
    methods: {
        chooseCard(cardNumber) {
            this.walletAddress = cardNumber
            this.regex = ''
            this.show=false
        },
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        async submitWithdrawRequest() {
            this.state.loading = true
            this.transaction.amount = this.$S2N(this.amount)
            this.transaction.destinationWalletAddress = this.walletAddress
            this.transaction.code = this.TwoStep_code
            const [res, ] = await this.$http.post(`/wallets/withdrawal-request`, this.transaction)
            if (res) {
                this.$error('درخواست شما با موفقیت ثبت شد', '', 'success')
                    ++this.state.requestChanged
                this.$emit('modalToggle', '')
            }
        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/users/verified-bank-accounts')
            this.banks = res.content
            const coinsResponse = await this.$axios.get('/coins/details')
            this.coins = coinsResponse.content
            this.coinData = this.coins.find(coin => coin.coin == 'TOMAN')
            this.wage = this.coinData.tokenTypeDetails[0].withdrawalFee
            this.max = this.coinData.tokenTypeDetails[0].maxWithdraw
            this.min = this.coinData.tokenTypeDetails[0].minWithdraw
            console.log( this.coinData)

        },
        async withdraw() {
            if (!this.disabled && !this.state.loading) {
                const res = await this.$axios.post("/users/send-code")
                if (res.message === "code sent successfully.") {
                    this.otpInput = true
                    this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
                    if (this.towStepAuthenticationType !== 'GOOGLE_AUTHENTICATOR') {
                        this.counter = true
                        this.countDownTimer()
                    }
                }
            }

        },

        async send() {
            this.TwoStep_code = ''

            this.state.loading = true
            const a = {
                EMAIL: 'ایمیل',
                SMS: 'شماره'
            }
            const res = await this.$axios.post("/users/send-code")
            this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
            if (res.message === 'code sent successfully.') {
                this.$error('', 'کد به ' + a[this.towStepAuthenticationType] + ' شما ارسال شد', 'success')
                if (this.countDown === 0)
                    this.countDown = 120
                this.countDownTimer()
            }
        },
    },
    mounted() {
        this.getData()
    },
}
</script>

<style scoped>
.disabled{
    opacity: 0.7;
}
.error {
    font-size: clamp(12px, 1.3vw, 14px);
    color: red;
}

.label-input {
    margin-top: 6px;
    /* width: 100%; */
    display: flex;
    text-align: right;
    /* flex: 1; */
}

.card-select {
    width: 100%;
    position: relative;
}

.card-row {
    padding: 5px 10px;
    cursor: pointer;

}

.cards-drop {
    width: 100%;
    background: #EDF1ED;
    font-size: clamp(13px, 1.4vw, 14px);
    max-height: 250px;
    position: absolute;
}

input {
    background: #EDF1ED;
    height: 40px;
    flex-grow: 1;
    size: 1;
    width: 60px;
    color: #828282;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

}

.input-container {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
}

label {
    border-radius: 0px 6px 6px 0px;
    font-size: clamp(12px, 1.3vw, 13px);
    background: #EDF1ED;
    color: #828282;

    display: flex;
    padding: 0px 10px;
    align-items: center;
    flex-grow: 1;
    white-space: nowrap;

}

.email-btn {
    position: absolute;
    left: 10px;
    cursor: pointer;

}

p {
    font-size: 14px;
}

.modal-title {
    color: #143B74;
    margin-right: 12px;
}

.description-wallet {
    display: flex;
    column-gap: 10px;
    align-items: flex-start;
}

.number {
    font-weight: bold;
}

.crypto-amount {
    color: var(--green);
}

.commission {
    display: flex;
    /* align-items: center; */
    column-gap: 10px;
    font-size: 14px;
    color: var(--gray3);
}

.commission-network {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    /* border: 1px solid; */
}

.copy {
    display: flex;
    align-items: center;
    background: #DFF4F4;
    height: 100%;
    justify-content: center;
    border-radius: 4px 0px 0px 4px;
    padding: 0px 8px;
}

.toggle-comp {
    flex-grow: 1;
    max-width: 194px;
}

.value {
    color: var(--gray4);
    font-weight: bold;
}

.crypto-network {
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-size: 12px;
    column-gap: 20px;
}

.wallet-address {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    background: #F7F7F7;
    font-size: 12px;
    border-radius: 4px;
}

.address-label {
    color: var(--gray5);
}

.address-value {
    color: var(--gray4);
    font-weight: bold;
}

.description {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 35px;
    row-gap: 10px;
    font-size: clamp(12px, 1.3vw, 14px);
    margin-top: 15px;
}

::v-deep .modal-card {
    max-width: 602px;
}
</style>
