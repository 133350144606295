<template>
<div>
  <div class="wallet-switch">
               <ToggleComp  @choose="(choice)=>{walletType=choice}" :items="[{label:'بازار',value:'P2P'},{label:' صرافی',value:'EXCHANGE'}]"/>
      </div>
  <div class="wallet">
    <div v-if="errorFlag">
      اطلاعات شما  هنوز تایید نشده است
    </div>
     
      <div v-if="!errorFlag" class="brief-sec">
          <div class="page-title">خلاصه</div>
          <Brief :wallets="wallets" />
      </div>
      <div  v-if="!errorFlag" class="wallets">
        <div class="wallet-head">
          <div class="page-title">کیف پول ها</div>
         
        </div>
          <WalletsTable :wallets="wallets" :walletType="walletType"/>
      </div>

  </div>
</div>
    
</template>

<script>
import Brief from './Brief'
import WalletsTable from './WalletsTable'

export default {
  name: 'Wallet',
  components:{
    WalletsTable,
    Brief


  },
  data(){
    return{
      wallets:{},
      errorFlag:false,
      walletType:'P2P'
      
    }
  },
  watch:{
    'walletType':function(){
       this.getWallets()
    }
  },
  methods:{
        async getWallets() {
            this.state.loading = true

             const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType='+this.walletType)
            if (res) {
                // let entireRes = Object.entries(res)
                // const sortCoins = this.$walletSortCoins
                // entireRes.sort((a,b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
                this.wallets = res
            }
            this.loading = false



        },
        
  },
  mounted(){
       this.getWallets()
  }
  
}
</script>
<style scoped>
.wallet-switch{
  max-width: 150px;
  margin-bottom: 10px;
  flex-grow: 1;
}
.wallet-head{
  display: flex;
  justify-content: space-between;
  
}
.wallet{
    display: flex;
    column-gap: 10px;
    row-gap: 20px;
   
    flex-wrap: wrap;
}


.brief-sec{
    max-width: 312px;
    flex-grow: 1;
}

.wallets{
   flex-basis: 500px;
   /* width: 100%; */
   flex-grow: 1;
}

.crypto-wallets{
     background: rgba(250, 252, 255, 0.6);
    border-radius: 6px;
}

.crypto-rows{
    
}


@media(max-width:995px){
  .brief-sec{
      max-width: 1000px;
  }
}
</style>