<template>
<Modal @close="$emit('modalToggle','')">
    <template #header >
        <div>لغو سفارش </div>
    </template>
    <template #body>
        <div class="text">
            آیا از لغو سفارش مطمئنید؟
        </div>
        <div class="btns">
            <button class="modal-confirm-btn red"  @click="$emit('modalToggle','')">خیر</button>
            <button class="modal-confirm-btn" @click="$emit('confirm')">بله</button>
        </div>
    </template>
</Modal>
  
    
</template>

<script>
import Modal from './Modal'
export default {
  name: 'CancelModal',
  components:{
      Modal
  },
 
  data(){
    return{
       
        
      
    }
  },
  methods:{
      
  }
}
</script>
<style scoped>
.red{
    background: red;
}
.btns{
    display: flex;
    align-items: center;
    column-gap: 8px;
}
.factor > div{
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: space-between;
    flex-grow: 1;
}

.factor{
    display: flex;
    margin-top: 12px;
    column-gap: 30px;
}

.data-row{
    display: flex;
    justify-content: space-between;
    font-size: 13px;
}

.data-row > span:last-child {
    font-weight: bold;
    color:var(--gray4)
}
.number{
font-weight: bold;
}

p{
    font-size: 14px;
}
</style>