<template>
    <div class="box" @click="$emit('toggle')">
      <div class="container">
        <inline-svg class="flash" :src="require('../../../../assets/public/arrowright.svg')" />
      </div>
      <div class="container rotate">
        <inline-svg class=" flash" :src="require('../../../../assets/public/arrowright.svg')"  />
      </div>
    </div>

    
</template>

<script>
export default {
  name: 'ExchangeBox',
  
}
</script>
<style scoped>
.box{
    background: #DFF4F4;
box-shadow: inset 0px -2px 13px rgba(201, 201, 201, 0.25), inset -3px 2px 9px rgba(116, 116, 116, 0.25);
border-radius: 6px;
padding: 9px;
width: 44px;
height: 36px;
margin-top: 20px;

display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
row-gap: 3px;
transition: padding 0.5s ease;
}

.rotate{
  transform: rotate(180deg);

}

.box:hover{
  padding: 9px 5px 9px 5px;
}

.container{
  display: flex;
  width: 100%;
  justify-content: flex-start;
}
.flash{
  fill: #143B74;
  /* fill: linear-gradient(95.12deg, #143B74 -9.51%, #2B6BC9 104.28%); */

}

@media(max-width:650px){
  .box{
    transform: rotate(-90deg);
  }
}
</style>