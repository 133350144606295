<template>
<div class="auth-page">

    <AuthCard>
        <template #card-header>
            شناسایی دو عاملی
        </template>
        <template #card-body>
            <p class="text" v-if="verifyType === 'Email'">کد 5 رقمی که به شماره خود ارسال شده است
                را وارد نمایید.</p>

            <VerifyCodeInput @change="e=>onChange(e)" @complete="submit()" :num="5" />
            <span class="countdown">{{Math.floor(countDown/60)}}:{{countDown%60}}ثانیه </span>

        </template>
        <template #card-footer>
            <button :class="{'authentication-confirm-btn':true,'disabled-btn':otp.code.length<6 }" v-if="countDown >0" :disabled="otp.code.length<5" @click="submit()">ثبت</button>
            <button :class="authentication-confirm-btn" v-if="countDown==0" @click="send()">ارسال مجدد </button>
        </template>

    </AuthCard>
</div>
</template>

<script>
import AuthCard from './components/AuthCard'
export default {
    name: 'Finotech',
    components: {
        AuthCard
    },
    data() {
        return {

            countDown: 120,
            error: '',
            num: 5,
            otp: {
                code: '',
                email: this.state.userInfo.email || this.$cookies.get('username'),
            }
        }
    },
    methods: {
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
        async send() {
            // this.reset()
            this.state.loading = true
            const res = await this.$axios.get(`/finotech/send-sms-to-customer?email=${this.state.userInfo.email}`)
            if (res.message === 'Sms sent to customer.') {
                if (this.countDown === 0) {
                    this.countDown = 120
                    this.countDownTimer()
                }
            }
        },
        onChange(e) {
            this.otp.code = e
        },
        async submit() {
            this.state.loading = true
            if (this.otp.code.length === 5 || this.loading) {
                this.loading = true
                let [res, ] = await this.$http.get('/finotech/otp', {
                    params: {
                        otp: this.otp.code,
                        email: this.state.userInfo.email
                    }
                })
                this.loading = false
                if (res) {
                    await this.$error('', 'فرایند احراز هویت انجام شد. منتظر تایید اطلاعات توسط پشتیبانی باشید.', 'success')
                    this.$router.push({
                        name: 'Authentication'
                    })
                }
            }
            this.state.loading = false
        },
        // paste,
        // reset,
    },
    created() {
        this.countDownTimer()
    },
    beforeDestroy() {
        clearTimeout(this.timeOut)
    },
    mounted() {
        // this.setPlaceholder()
    }
}
</script>

<style scoped>
.text {
    font-size: 12px;
    color: #4F4F4F
}

.disabled-btn {
    opacity: 0.7;
}

.countdown {
    margin-top: 10px
}
</style>
