<template>

      <div class="markets"> 
          <table>
             <tr class="head">
                 <td class="frst-head" align="right"> 
                     <div class="togglecomp">
                      <ToggleComp choosed='TRC20' @choose="(choice)=>{toman_tether=choice}" :items="[{label:'تومان',value:'TOMAN'},{label:'تتر',value:'TETHER'}]"/> 
                     </div>
                 </td>
                 <td align="center">تغییرات </td>
                 <td  align="center">قیمت جهانی</td>
                 <td class="resp-3" align="center">قیمت خرید</td>
                 <td class="resp-2" align="center" >قیمت فروش </td>
                 <td class="resp-1" align="center" >نمودار</td>
                 <td class="resp-1" align="center" >عملیات</td>
             </tr>
             <tr class="row" v-for="(crypto,index) in filteredCryptos.slice(0,9)" :key="index">
                 <td><div class="title"><img class="icon resp-1" :src="require('../../../assets/coins/'+crypto.relatedCoin+'.svg')"/>{{$coinLabel[crypto.relatedCoin]}}</div></td>
                 <td  align="center"><span class="change" :class="{red:crypto.last24HourChange<0,green:crypto.last24HourChange>0}"><span v-if="crypto.last24HourChange>0">+</span>{{Math.abs(crypto.last24HourChange).toFixed(2)}} %</span></td>
                <td  align="center"><span >{{$toLocal(crypto.lastPrice,toman_tether=='TOMAN'?0:2)}}</span></td>
                 <td  class="resp-3" align="center"><span >{{$toLocal(crypto.buyPrice,toman_tether=='TOMAN'?0:2)}}</span></td>
                 <td class="resp-2" align="center"><span >{{$toLocal(crypto.sellPrice,toman_tether=='TOMAN'?0:2)}}</span></td>
                 <td class="resp-1 " align="center"><CoinChart :data="crypto.lastWeekData"/> </td>
                <td class="resp-1 " align="center"><UnderLinedLink :href="state.token?'Panel/P2P/TOMAN/'+$coin2Snake[crypto.relatedCoin]:'login'" >معامله</UnderLinedLink></td>
             </tr>
          </table>
          <div class="markets-link"><a  href="/Markets" @click.prevent="$router.push('/Markets')"> مشاهده همه ارزها</a></div>
      </div>
  
</template>

<script>
import CoinChart from '../CoinChart'

export default {
  name: 'Markets',
   components:{
      CoinChart
  },
  data(){
      return{
            cryptos: [],
            cryptoName: "",
            toman_tether: "TOMAN",
            filteredCryptos: [],
            loading: false,
            alive: false,
      }
  },
    watch: {
            'toman_tether'() {
                this.setCryptos();
            }
        },
        methods: {
            
            async getPrice() {
                let res= await this.$axios.get('/trades/market-info',{
                        params: {    
                            weeklyDataIncluded: true
                        }
                    }).then(response=>res=response.content)                   
                if (res) {
                    this.cryptos = res
                }
                this.setCryptos()
            },
            setCryptos(){
                this.filteredCryptos= this.cryptos.filter(item=>{
                    if (item.marketType.includes(this.toman_tether))
                            return item
                    })

            },
        
        },
        mounted() {
            this.alive = true
            // this.beforeLoop()
            this.getPrice()
        },
        beforeDestroy() {
            this.alive = false
        }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-header{
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    width: 100%;
}
input{
    background: rgba(255, 255, 255, 0.05);
    width: 100%;
    color:white
}
.searchbtn{
    background: #F29A39;
    border-radius: 2px;
    display: flex;
    padding: 4px;
    align-items: center;

}
.togglecomp{
    max-width: 187px;
}
.change{
    display: flex;
    direction: ltr;
    justify-content: center;
  
}

.markets{
    padding-top: clamp(80px,12vw,130px);
}
.chart{
    vertical-align: inherit;
    max-height: 60px;
}


.title{
    display: flex;
    align-items: center;
    margin-right: 10px;
}



.markets-link{
    text-align: center;
    margin-top: 16px;
}
 a{
    color: #143B74;
;
}

.symbol{
    color: rgba(30, 37, 48, 0.6);
    margin-right:4px;


}

.icon{
    padding-left:8px;
}
.green{
    color:rgba(11, 135, 16, 1);

}
.red{
    color:rgba(214, 43, 43, 1); ;

}
.head{
    background: transparent;
    color: #828282;
    height: 41px;;
     font-size:clamp(13px,1.3vw,14px);
    font-weight: 400px!important;
}

.row{
    background: #FAFCFF;
    color:  #4F4F4F;
    height: 64px;
     font-size:clamp(15px,1.6vw,18px);

    
}
td{
       font-weight: 400px!important;
}

table{
    border-collapse:collapse;
    width: 100%;
}


table tr:last-child td:first-child {
    border-bottom-right-radius: 4px;
}

table tr:last-child td:last-child {
    border-bottom-left-radius: 4px;
}
table tr:nth-child(2) td:first-child {
    border-top-right-radius: 4px;
}

table tr:nth-child(2) td:last-child {
    border-top-left-radius: 4px;
}

.row td:first-child{
    padding-right: clamp(15px,2.3vw,35px);
}

@media(max-width:900px){
    .resp-1{
        display: none;
    }
}
@media(max-width:550px){
    .resp-2{
        display: none;
    }
}

@media(max-width:400px){
    .resp-3{
        display: none;
    }
}
</style>
