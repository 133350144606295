<template>
<Modal @close="$emit('modalToggle','')">
    <template #header>
        <div>برداشت {{$coins[relatedCoin].persianName}}</div>
    </template>
    <template #body>
        <div v-if="!selected_net" class="crypto-network">
            <p> شبکه انتقال ارز را اتنخاب کنید</p>
            <div  class="toggle-comp">
                    <div class="net-options">
                            <div class="net-option" :class="{'active-option':cryptoNetworkIndex==index}" v-for="(network,index) in nets" :key="index" @click="cryptoNetworkIndex=index">
                                 {{network.tokenType}}
                            </div>     
                    </div> 
            </div>
        <button class="modal-confirm-btn" @click="selectNet()">تایید</button>

        </div>
        <template v-else>
                <div class="description">
                <div>لطفا مقدار درخواستی و شماره شبای خود را وارد کنید</div>
                <div class="description-wallet">{{$coins[relatedCoin].code}}&nbsp;{{credit}}<img src="../../assets/icons/dark-wallet.svg"/></div>  
        </div>
        <LabelInput @input="value=>amount=value"  type="number" label=" میزان برداشت"/>
        <p class="error" >{{errorHandler}}</p>    
        
        <LabelInput @input="value=>walletAddress=value" label=" آدرس کیف پول مقصد"/>

       <div class="commission-network">

       
        <div class="commission">
            <div>کارمزد انتقال</div>
            <div>{{$coins[relatedCoin].code}}&nbsp;{{wage}}</div>
        </div>
       </div>
       
        <p class="error" v-if="!enable && !!selected_net">
                 در حال حاضر برداشت از شبکه مورد نظر مقدور نیست
        </p>
       <p>در صورت اطمینان از صحت درخواست خود روی گزینه ارسال کد کلیک کنید تا برایتان ارسال شود و پس از ورود کد درخواست خود را ثبت کنید</p>
         <div :class="{'label-input':true }" >
            <label >کد تایید دوعاملی</label>
            <div class="input-container" >
                <input type="text" v-model="code" />
                <button class="email-btn" v-if="otpInput===false"  @click="withdraw()" :class="{disabled:disabled}" :disabled="disabled">
                    ارسال کد تایید
                </button>
                <div class="email-btn" v-if="otpInput===true && countDown>0 && counter"  :class="{disabled:disabled}" >
                   {{Math.floor(countDown/60)}}:{{countDown%60}}
                </div>
                <button v-if="otpInput===true && countDown===0 && counter" class="email-btn" @click.prevent="send()">
                    ارسال مجدد
                </button>
            </div>
     
        </div>
            <button  class="modal-confirm-btn" :class="{disabled:disabled || !otpInput || countDown==0}"
            @click="submitWithdrawRequest()" :disabled="disabled || !otpInput || countDown==0">
                ثبت درخواست برداشت
            </button>
         
            

           

        </template>
    
    </template>
</Modal>
  
    
</template>

<script>
import Modal from './Modal'
export default {
  name: 'Withdraw',
  components:{
      Modal
  },
  props:{
          relatedCoin: {

                default: function(){
                    return ''
                }
            },
            exchange: {
                  default: function(){
                    return false
                }
            },
            credit: {
                  default: function(){
                    return 0
                }
            },
           
  },
  data(){
    return{
           amount: '',
            walletAddress: '',
            local: false,
            token: false,
            transaction: {
                amount: "",
                destinationWalletAddress: "",
                relatedCoin: "",
                tokenType: "",
                code: "",
                walletType :(this.exchange ? 'EXCHANGE' : 'P2P')
            },
            towStepAuthenticationType: "",
            otpInput: false,
            counter: false,
            timeOut: '',
            countDown: 120,
            code: '',
            selected_net: "",
            txIdIsNeeded: false,
            drop: false,
            enable : false,
            nets:[],
            coins:[],
            wage:0,
            cryptoNetworkIndex:0,
            coinData:{},
            min:'',
            max:'',


      
    }
  },
  computed:{
          disabled() {
            return !this.$S2N(this.amount) ||
                !this.walletAddress ||
                (this.$S2N(this.amount) > this.$S2N(this.credit)) ||
                (this.$S2N(this.amount) > this.$S2N(this.max)) ||
                (this.$S2N(this.amount) < this.$S2N(this.min)) 
                // ( !this.enable )
                // (!this.selected_net)
        },
        errorHandler() {
            if((this.$S2N(this.amount) > this.$S2N(this.credit))) 
                return 'موجودی کافی نمیباشد'
            else if((this.$S2N(this.amount) < this.$S2N(this.min))) 
                return 'مقدار برداشت باید حداقل'+this.$coins[this.relatedCoin].code +' '+this.min+'باشد'
            else if((this.$S2N(this.amount) > this.$S2N(this.max))) 
                return 'مقدار برداشت باید حداکثر '+this.$coins[this.relatedCoin].code+' ' +this.max+' باشد '
            else 
                return ''
            
        },
      
      
  },
  methods:{
     
           countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                this.$error('زمان شما به اتمام رسید', '')
            }
        },
         selectNet(){
            this.selected_net = this.nets[this.cryptoNetworkIndex].tokenType
            this.transaction.tokenType= this.selected_net
            this.txIdIsNeeded = this.nets[this.cryptoNetworkIndex].txIdIsNeeded
            this.wage = this.nets[this.cryptoNetworkIndex].withdrawalFee
            this.max = this.nets[this.cryptoNetworkIndex].maxWithdraw
            this.min = this.nets[this.cryptoNetworkIndex].minWithdraw
            this.enable = this.nets[this.cryptoNetworkIndex].withdrawEnable || true
            
            this.drop = false
          
        },
         async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/coins/details')
            this.coins = res.content
            this.coinData=this.coins.find(coin=>coin.coin==this.relatedCoin)
            this.nets=this.coinData.tokenTypeDetails
        },
        async send() {
            this.code = ''
            this.state.loading = true
            const a = {
                EMAIL: 'ایمیل',
                SMS: 'شماره'
            }
            const res = await this.$axios.post("/users/send-code")
            this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
            if (res.message === 'code sent successfully.') {
                this.$error('', 'کد به ' + a[this.towStepAuthenticationType] + ' شما ارسال شد', 'success')
                if (this.countDown === 0)
                    this.countDown = 120
                this.countDownTimer()
            }
        },
        async withdraw() {
            if (!this.disable && !this.state.loading) {
                this.state.loading = true
                this.transaction.amount = this.$S2N(this.amount),
                    this.transaction.destinationWalletAddress = this.walletAddress,
                    this.transaction.relatedCoin = this.relatedCoin
                if ('TETHER' === this.relatedCoin) {
                    this.transaction.tokenType = this.token ? 'ERC20' : 'TRC20'
                }
                const res = await this.$axios.post("/users/send-code")
                this.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType
                this.otpInput = true
                if (this.towStepAuthenticationType !== 'GOOGLE_AUTHENTICATOR') {
                    this.counter = true
                    this.countDownTimer()
                }
            }
        },
        async submitWithdrawRequest() {
            this.state.loading = true
            this.transaction.code = this.code
            const [res, ] = await this.$http.post('/wallets/withdrawal-request', this.transaction)
            if (res) {
                this.$emit('modalToggle','')
                this.$error('درخواست شما با موفقیت ثبت شد', '', 'success')
                    ++this.state.requestChanged
                
            }
        },
      
  },
  mounted(){
      this.getData()
  },
   beforeDestroy() {
        clearTimeout(this.timeOut)
    },
}
</script>
<style scoped>
.error{
    font-size: clamp(12px,1.3vw,14px);
    color: red;
}
.disabled{
    opacity:0.7
}
.net-option{
    border-radius: 4px;
    height: 30px;
    font-size: clamp(13px,1.4vw,15px);
    display: flex;
    align-items: center;
    padding: 0px 8px;
    background: rgb(214, 212, 212);
    cursor: pointer;
}

.active-option{
    background: linear-gradient(95.12deg, #143B74 -9.51%, #2B6BC9 104.28%);
    box-shadow: inset 27.2667px -27.2667px 27.2667px rgba(9, 45, 102, 0.1), inset -27.2667px 27.2667px 27.2667px rgba(255, 255, 255, 0.1);  
    color: rgba(255,255,255,1);
}
.net-options{
    display: flex;
    column-gap: 10px;
    align-items: center;
}
.label-input{
    margin-top:6px;
    /* width: 100%; */
    display: flex;
    text-align: right;
    /* flex: 1; */
}
input{
    background: #EDF1ED;
     height: 40px;
     flex-grow: 1;
     size: 1;
    width: 60px;
    color: #828282;
    border-top-left-radius:6px;
    border-bottom-left-radius:6px;

}
.input-container{
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}
label{
     border-radius: 0px 6px 6px 0px;
    font-size: clamp(12px,1.3vw,13px);
    background: #EDF1ED;
    color: #828282;

    display: flex;
    padding:0px 10px;
    align-items: center;
  flex-grow: 1;
  white-space: nowrap;
    
}

.email-btn{
   position: absolute;
   left:10px;
   cursor: pointer;
  
}

p{
    font-size: 14px;
}

.modal-title{
    color: #143B74;
    margin-right: 12px;
}
.description-wallet{
    display: flex;
    column-gap: 10px;
    align-items:flex-start;
}
.number{
font-weight: bold;
}

.crypto-amount{
    color: var(--green);
}

.commission{
    display: flex;
    /* align-items: center; */
    column-gap: 10px;
    font-size: 14px;
    color: var(--gray3);
}

.commission-network{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    /* border: 1px solid; */
}
.copy{
    display: flex;
    align-items: center;
    background: #DFF4F4;
    height: 100%;
    justify-content: center;
    border-radius: 4px 0px 0px 4px;
    padding: 0px 8px;
}

.toggle-comp{
    flex-grow: 1;
    max-width: 194px;
}
.value{
    color: var(--gray4);
    font-weight: bold;
}

.crypto-network{
    align-items: center;
    flex-grow: 1;
    font-size: 12px;
    column-gap: 20px;
}
.wallet-address{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    background: #F7F7F7;
    font-size: 12px;
border-radius: 4px;
}

.address-label{
    color: var(--gray5);
}
.address-value{
    color: var(--gray4);
    font-weight: bold;
}

.description{
    display: flex;
    justify-content: space-between;
    column-gap: 35px;
    font-size: 14px;
    margin-top: 15px;
}

::v-deep .modal-card{
    max-width: 602px;
}
</style>