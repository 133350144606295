<template>
    <div class="section">
            <div class="section-title">آپلود مدارک</div>
            <p class="text">لطفا تصویر کارت ملی را در کنار چهره خودتان همراه با یک دست نوشته به 
                صورت کاملا خوانا و واضح ،دقیقا مانند تصویر نمونه 
                ،ارسال نمایید. دقت شود متن دست نوشته نبایستی تایپ شود. هم چنین توجه داشته باشید
                 هنگام تهیه تصویر ، متن دست نوشته و اطلاعات کارت ملی به صورت برعکس و آینه ای ثبت نشود.
                 </p>
                 <div  class="upload-section">
                    <div class="text-pattern">
                        <div class="input-group-title">متن دست نوشته</div>
                         <p class="pattern">
                         این جانب ........... با شماره ملی ....... در تاریخ ........... مدارک خود را جهت احراز هویت در سایت بی بیتکس 
                         ارسال و اعلام می کنم مدارک، حساب 
                         کاربری و اطلاعات کارت بانکی خود را در اختیار دیگری قرار نداده ام و متعهد می شوم از دریافت وجه و خرید ارز 
                         دیجیتال برای اشخاص دیگر تحت هر عنوانی )آگهی استخدام، کسب سود، کاریابی در منزل و ....( خودداری کنم. 
                     </p>
                    </div>
                    <!-- <div class="imgaes-section-container">  -->
                    <div class="image-pattern">
                        <div class="input-group-title">نمونه سلفی با کارت ملی:</div>
                        <div class="images-container">
                            <img src='../../../assets/public/selfie-pattern-right.png'/>
                            <img src='../../../assets/public/selfie-pattern-false.png'/>
                        </div>

                    </div>
                    <div>
                        <div class="input-group-title">محل آپلود تصویر:</div>
                        <div class="image-upload-container">

                            <div class="image-upload" >
                                           
                                 <label class="upload-image-container">
                                    <input   @change="upload($event.target)" type="file"  accept="image/*" name="image" id="file" />
                                    <img v-if="path" :src="path" id='user-img'/>
                                    <div v-else class="default-image-container">
                                        <img   src='../../../assets/public/upload.svg'  id="output-img"   />
                                        <p id="upload-image-sub" >تصور سلفی با کارت ملی</p>
                                    </div>
                                 <button class="upload-btn"  >آپلود</button>
                                 </label>
                           </div>
                        </div>
                    </div>

                   
                   
                 </div>
        </div>

    
</template>

<script>
export default {
  name: 'ProofUpload',
  props:{
          data:Object
  },
   watch: {
            'data.image'() {
                if (this.data.image)
                    if (typeof this.data.image === 'string')
                        this.path = this.data.image
            }
        },
         data() {
            return {
                path: '',
                loadedpicture: '',
                hovered: '',
            }
        },
   computed: {
         
        },
  methods:{
             drop(e) {
                let dt = e.dataTransfer
                this.upload(dt)
            },
            upload(evt) {
                let file = evt.files[0]
                this.data.image = file
                let fileReader = new FileReader();
                fileReader.addEventListener("loadstart", (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                })
                fileReader.addEventListener("progress", (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                });
                fileReader.addEventListener('load', (event) => {
                    this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
                    this.path = event.target.result
                });
                fileReader.readAsDataURL(file);
                // this.loadFile(evt)
            },
            // loadFile(event){         
            //         var image = document.getElementById('output-img');
            //         this.data.image=URL.createObjectURL(event.files[0]);
            //         image.src = URL.createObjectURL(event.files[0]);
            //         document.getElementById('upload-image-sub').style.display="none";
            //         console.log(this.data.image);
            // }
      
  }
  
}

</script>
<style scoped>

.upload-section{
    display: flex;
    column-gap: 32px;
    justify-content: space-evenly;
    flex-wrap: wrap;

}
#upload-image-sub{
    color:#828282;
    font-size: clamp(12px,1.3vw,14px);
    font-weight: bold;
}

#user-img{
    max-width: 100%;
    max-height: 100%;
}
.upload-image-container{
    margin-bottom: 10px;
    height: 100%;
    min-height: 165px;
    position: relative;
    width: 100%;

}

.default-image-container{
    margin-bottom: 10px;
    background: no-repeat center/100% 100% url('../../../assets/public/upload-border.png');
    height: 100%;
    min-height: 100%;
    background-repeat: no-repeat;
    position: relative;
    color: #828282;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#output-img{
    max-width: 100%;
    max-height: 100%;
   
}
input[type="file"]{
    width: 100%;
    position: absolute;
    opacity: 0;
    z-index: 1;
    height: 100%;
}
.upload-section > div{
    flex-grow: 1;
    
    flex-basis: 200px;
}


.upload-btn{
    width: 100%;
    background: linear-gradient(95.12deg, #143B74 -9.51%, #2B6BC9 104.28%);
border-radius: 6px;
height: 40px;
color: white;
}

.image-upload{
    display: flex;
    align-items:center;
    flex-direction: column;
    width: 100%; 
    max-width: 530px;
}
.pattern{
    color:var(--green) ;
    font-size: clamp(12px,1.3vw,14px);

}
.images-container{
    display: flex;
    column-gap: 14px;
}
.image-upload-container{
    display: flex;
    justify-content: center;
}

.text{
    color: #828282;
    font-size: clamp(12px,1.3vw,14px);

}

@media(max-width:520px){

    .images-container{
        flex-wrap: wrap;
        justify-content: space-evenly;
    
    
    }
}
</style>