<template>
      <div class="section">
            <div class="section-title">اطلاعات بانکی</div>
            <div> 
                <div class="input-group-title">حساب جدید</div>
                <form class="input-fields">
                    <LabelInput  type="nochar"  :value="creditCard.cardNumber" :maxLength="16"  @input="(value)=>cardNumberHandle(value)"  label='شماره کارت '/>
                    <LabelInput type="nochar" :value="creditCard.shebaCode"  :maxLength="24" @input="(value)=>creditCard.shebaCode=value"  label='شماره شبا ' />
                    <div class="bank-title-action">
                        <LabelInput :value="creditCard.bankName" :disabled="true"  label='بانک' />
                        <div class="action">
                            <UnderlinedBtn @click="addAccount()" :disabled="disabled"   color='#143B74'>افزودن حساب جدید</UnderlinedBtn>
                        </div>
                    
                    </div>
                </form>
            </div>
            <div>
                <div class="input-group-title">حساب های قبلی</div>
                <div class="credit-cards">
                    <div class="row" v-for="(bankAccount,index) in bankAccounts" :key="index" >
                    <LabelInput label='شماره کارت ' :value="bankAccount.cardNumber"  :disabled="true"/>
                    <div class="resp"> <LabelInput label='شماره شبا ' :value="bankAccount.shebaCode" :disabled="true"/> </div>
                    <div class="bank-title-action">
                        <div class="resp"><LabelInput label='بانک' :value="bankAccount.bankName" :disabled="true"/></div>
                        <div class="action">
                           <UnderlinedBtn @click="removeAccount(index)"  color='#CA2F4B'>حذف</UnderlinedBtn>
                        </div>
                    </div>
                    </div>
                </div>

            </div>
           
        </div>  
    
</template>

<script>

export default {
  name: 'BankInformation',
  props:['bankAccounts'],
  computed:{
      disabled(){
          return this.creditCard.shebaCode.length!=24 ||  this.creditCard.cardNumber.length!=16 || this.creditCard.bankName=='کارت نامعتبر'
      }
  },
  data(){
      return{
           creditCard:{
               bankName:"",
               shebaCode:"",
               cardNumber:""
           },
      
     banks: {
        '603799': 'بانک ملی ایران',
        '589210': 'بانک سپه',
        '627648': 'بانک توسعه صادرات',
        '207177': 'بانک توسعه صادرات',
        '627353': 'بانک تجارت',
        '585983': 'بانک تجارت',
        '627961': 'بانک صنعت و معدن',
        '603770': 'بانک کشاورزی',
        '639217': 'بانک کشاورزی',
        '628023': 'بانک مسکن',
        '627760': 'پست بانک ایران',
        '502908': 'بانک توسعه تعاون',
        '627412': 'بانک اقتصاد نوین',
        '622106': 'بانک پارسیان',
        '639194': 'بانک پارسیان',
        '627884': 'بانک پارسیان',
        '502229': 'بانک پاسارگاد',
        '639347': 'بانک پاسارگاد',
        '639599': 'بانک قوامین',
        '504706': 'بانک شهر',
        '502806': 'بانک شهر',
        '603769': 'بانک صادرات',
        '610433': 'بانک ملت',
        '991975': 'بانک ملت',
        '589463': 'بانک رفاه',
        '502938': 'بانک دی',
        '639607': 'بانک سرمایه',
        '627381': 'بانک انصار',
        '505785': 'بانک ایران زمین',
        '636214': 'بانک آینده',
        '636949': 'بانک حکمت ایرانیان',
        '621986': 'بانک سامان',
        '639346': 'بانک سینا',
        '606373': 'بانک قرض الحسنه مهر',
        '627488': 'بانک کارآفرین',
        '502910': 'بانک کارآفرین',
        '505416': 'بانک گردشگری',
        '639370': 'بانک مهر اقتصاد',
        '628157': 'موسسه اعتباری توسعه',
        '505801': 'موسسه اعتباری کوثر',
    }
           
      }
  },
  
  methods:{
      cardNumberHandle(value){
           let temp = Object.entries(this.banks).filter(bank => {
                if (value.startsWith(bank[0]) || bank[0].startsWith(value) && value)
                    return bank[0]
            })[0]
            console.log(temp)
            this.creditCard.bankName =temp? this.banks[temp[0]] : 'کارت نامعتبر'
            this.creditCard.cardNumber=value
            console.log(this.creditCard.cardNumber.length)
            console.log(this.creditCard.shebaCode.length)

      },
       removeAccount(index) {
           if(this.bankAccounts.length>1)
                this.bankAccounts.splice(index, 1)
       },
         addAccount() {
                // this.bankAccounts.push(JSON.parse(JSON.stringify(this.creditCard)));
                this.bankAccounts.push(this.creditCard);
                this.creditCard = {
                    bankName: "",
                    shebaCode: "",
                    cardNumber: ""
                }
        },
        dash(e)
            {
                this.bankAccounts[e].bankName = this.banks[this.bankAccounts[e].cardNumber ? this.bankAccounts[e].cardNumber.replace(/-/ig, '').slice(0, 6) : ''] || 'کارت نامعتبر'
                this.bankAccounts[e].cardNumber = this.$separator(this.bankAccounts[e].cardNumber)
                this.error=''
            }
  },
    mounted() {
            // eslint-disable-next-line no-unused-vars
            for (let [index, item] of this.bankAccounts.entries()) {
                this.dash(index)
            }
        }
 
}
</script>
<style scoped>

.bank-title-action > *{
      flex: 1;
      flex-basis: 150px;
     
}

.bank-title-action{
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    column-gap: 16px;
    row-gap: 16px;
    font-size: clamp(14px,1.5vw,16px);
    /* white-space: nowrap; */
}

.row{
    display: flex;
    width: 100%;
     column-gap: 16px;
    }
.row > div{
    flex-grow: 1;
    flex-basis: 270px;

}   

@media(max-width:720px){

.resp{
    display: none;
}
}

</style>