<template>
<Modal @close="$emit('modalToggle','')">
    <template #header>
        <div>شارژ موجودی تومانی</div>
    </template>
    <template #body>
        <p class="description">
            جهت افزایش اعتبار کیف پول تومان خود با استفاده از کارت های بانکی عضو شبکه شتاب و از طریق درگاه پرداخت ,حتما باید از کارت های بانکی به نام خودتان که در حساب کاربری تان تایید شده است استفاده نمایید
        </p>
        <LabelInput @input='value=>amount=value' type="number" label=" مبلغ واریزی" />
        <div class="add-btn" @click="$router.push('Authentication');$emit('modalToggle','')"> افزودن شماره کارت </div>
        <div class="card-select">
            <LabelInput @input='value=>regex=value' :value="walletAddress" label=" شماره کارت" @click="show=true" :disabled="true" />
            <div class="cards-drop" v-if="show" :style="{height:cardFilter.length*30+'px'}">
                <div class="card-row" v-for="(card,index) in cardFilter" :key="index" @click="chooseCard(card.cardNumber)">
                    {{card.cardNumber}}
                </div>
            </div>
        </div>
        <button :class="{disabled:!amount || !walletAddress || ($S2N(amount) < 1000)}" :disabled="!amount || !walletAddress || ($S2N(amount) < 1000)" class="modal-confirm-btn" @click="deposit"> انتقال به درگاه پرداخت</button>
    </template>
</Modal>
</template>

<script>
import Modal from './Modal'
export default {
    name: 'TomanCharge',
    components: {
        Modal
    },
    data() {
        return {
            amount: '',
            walletAddress: '',
            banks: [],
            regex: '',
            show: false
        }
    },
    computed: {
        cardFilter() {
            if (this.banks)
                return this.banks.filter(card => card.cardNumber.replace('-', '').startsWith(this.regex))
            else
                return []
        }
    },
    methods: {
        chooseCard(cardNumber) {
            this.walletAddress = cardNumber
            this.regex = ''
            this.show = false
        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/users/verified-bank-accounts')
            this.banks = res.content
        },
        async deposit() {
            this.state.loading = true
            let walletInfo = {
                amount: this.$S2N(this.amount),
                paymentGateway: 'ZARRINPAL',
                cardNumber: this.walletAddress,
                callBackURL: this.$callBackURL
            }
            const res = await this.$axios.post('/wallets/deposit-toman', walletInfo)
            if (res.message === "Deposit Request Created Successfully") {
                this.$emit('modalToggle', '');
                window.open(res.baseDTO.redirectSite, "_self")
            }
        },

    },
    async mounted() {
        await this.getData()
    }
}
</script>

<style scoped>
.label-input {
    margin-top: 6px;
    /* width: 100%; */
    display: flex;
    text-align: right;
    /* flex: 1; */
}

input {
    background: #EDF1ED;
    height: 40px;
    flex-grow: 1;
    size: 1;
    width: 60px;
    color: #828282;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

}

.add-btn {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    margin-top: 26px;
    cursor: pointer;

}

.card-select {
    width: 100%;
    position: relative;
}

.card-row {
    padding: 5px 10px;

}

.cards-drop {
    width: 100%;
    background: #EDF1ED;
    font-size: clamp(13px, 1.4vw, 14px);
    max-height: 250px;
    position: absolute;
}

.input-container {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
}

label {
    border-radius: 0px 6px 6px 0px;
    font-size: clamp(12px, 1.3vw, 13px);
    background: #EDF1ED;
    color: #828282;

    display: flex;
    padding: 0px 10px;
    align-items: center;
    flex-grow: 1;
    white-space: nowrap;

}

.email-btn {
    position: absolute;
    left: 10px;
    cursor: pointer;

}

p {
    font-size: 14px;
}

.modal-title {
    color: #143B74;
    margin-right: 12px;
}

.description {

    font-size: clamp(12px, 1.3vw, 14px);
    margin-top: 15px;
    margin-bottom: 50px;
}

::v-deep .modal-card {
    max-width: 602px;
}
</style>
