<template>
<Modal  @close="$emit('modalToggle','')">
    <template #header>
        <div>فروش </div>
    </template>
    <template #body>
        <div class="description">
                <div>سفارش شما به شرح زیر است در صورت اطمینان,سفارش خود را ثبت کنید </div>
        </div>
        <div class="factor-container">
            <div>فاکتور</div>
            <div class="factor">
                <div><img class="icon" :src="require('../../assets/coins/'+data.destinationCoin+'.png')"/> <div class="crypto-amount">{{data.amount}} </div>{{$coins[data.destinationCoin].code}}</div>
                <div>قیمت واحد <span class="value">{{$toLocal(data.unitPrice,0)}}</span>{{$coins[data.baseCoin].persianName}}</div>
                <div>مبلغ دریافتی<div class="value">{{$toLocal(data.totalPrice,0)}}</div>{{$coins[data.baseCoin].persianName}}</div>

            </div>
        </div>
        <div class="alert">ﭼﻨﺎﻧﭽﻪ ارز ارﺳﺎﻟﯽ ﺷﻤﺎ ﺑﺎ ﺑﯿﺶ از 30 دﻗﯿﻘﻪ ﺗﺎﺧﯿﺮ وارﯾﺰ ﺷﻮد ﻣﺒﻠﻎ درﯾﺎﻓﺘﯽ در زﻣﺎن وارﯾﺰ ارز ﻣﺤﺎﺳﺒﻪ ﻣﯽ ﺷﻮد</div>
        <button @click="$emit('confirm')" class="modal-confirm-btn">ثبت سفارش فروش</button>
    </template>
</Modal>
  
    
</template>

<script>
import Modal from './Modal'
export default {
  name: 'Sell',
  components:{
      Modal
  },
  props:['data'],
  data(){
    return{

      
    }
  }
}
</script>
<style scoped>
.modal-title{
    color: #143B74;
    margin-right: 12px;
}

.factor > div{
    display: flex;
    align-items: center;
    column-gap: 6px;
}
.factor{
    padding: 9px 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    flex-wrap: wrap;
    gap:8px;
    background: rgba(216, 211, 242, 0.2);
    border-radius: 4px;
    font-size: 13px;
    color: var(--gray3);
}
.number{
font-weight: bold;
}
.icon{
    height: 30px;
    width: 30px;
}
.crypto-amount{
    color: var(--red);
}
.copy{
    display: flex;
    align-items: center;
    background: #DFF4F4;
    height: 100%;
    justify-content: center;
    border-radius: 4px 0px 0px 4px;
    padding: 0px 8px;
}
.value{
    color: var(--gray4);
    font-weight: bold;
}
.wallet-address{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    background: #F7F7F7;
    font-size: 12px;
border-radius: 4px;
}

.address-label{
    color: var(--gray5);
}
.address-value{
    color: var(--gray4);
    font-weight: bold;
}

.alert{
    font-size: 12px;
    color: var(--red);
    margin-top: 8px;
}
.description{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    column-gap: 35px;
    font-size: 14px;

}

.text-value{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-basis: 200px;
    flex-grow: 1;
}
::v-deep .modal-card{
    max-width: 602px;
}
</style>