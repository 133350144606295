<template>

     <div class="table">
     <table >
      <tr class="table-header">
          <td  v-for="(column,i) in columns" :key="i"  :class="{'resp-1':i==3||i==0,'resp-2':i==4,'resp-3':i==5 || i==6}" v-html="column.label" ></td>
      </tr>
      <tbody>
        <tr v-for="(data,index) in dataSet" :key="index" class="table-row">
          <td  v-for="(column,i) in columns" :key="i" :class="{'resp-1':i==3||i==0,'resp-2':i==4,'resp-3':i==5 || i==6}" 
          :style="{color:colors[data[column.name]]}"  
          v-html=" 
          column.name=='amount' && data[column.name]+cryptos[data['cryptoType']]
          || orderType[data[column.name]]
          || column.name=='cryptoType' && $coinLabel[$marketType2relatedCoin(data['marketType'])] 
          || column.name=='action' && data[column.name]==true && '<div class='+'container'+ '> <div class='+'btn' +' > لغو سفارش    <div  class='+'line'+'></div></div></div>'
          || column.name=='action' && data[column.name]==false &&   '<div class='+`'disabled-action'`+'>لغو سفارش</div>'
          || column.dictionary  && column.dictionary[data[column.name]]
          || column.name=='createdAtDateTime' && $G2J(data[column.name])
          || dictionary[data[column.name]] 
          || (column.name=='fill')&& data[column.name]+'%'
          ||(column.name=='transId')&& '<div class='+'right-link'+ '> <div class='+'btn' +' >  مشاهده <div  class='+'line'+'></div></div></div>'
          || data[column.name].toLocaleString() 
          "
          >
         
           </td>
      </tr>

      </tbody>

  </table>
     </div>


 
</template>

<script>
export default {
  name: 'Table',
  
  props:{
    
      columns:{
        type: Array,
        required: true
    },
      dataSet:{
        type: Array,
        required: true
    },
    send_withdraw:Boolean,

    
    tableType:String
  }
  ,

   created(){
    //  document.addEventListener('click',(e)=>{
    //   if(e.target!=this.$el.querySelector('#filter'+this.openedFilter)    ){
    //   }
    //  })
  },
  methods:{
      cryptoType(orderType){
          if(orderType.includes('SELL') )
            return 'sourceCoin'
          else 
             return 'destinationCoin'  
      }
  },

  data(){
      return{
          openedFilter:null,
        //   icons:{'eye':'<img src="'+require("../../assets/auth/blackeye.svg")+'"/> '},
          colors:{'LIMITED_SELL':'#CA2F4B',"LIMITED_BUY":"#03C70B",'MARKET_SELL':'#CA2F4B',"MARKET_BUY":"#03C70B",'DEPSOIT':'#03C70B','WITHDRAW':'#CA2F4B','SUCCESS':'#03C70B',"FAIL":"#CA2F4B","SENDING":"#143B74","CHECKING":"#5F7BA5","VIEW":"#143B74","موفق":"#03C70B",'ناموفق':'#CA2F4B'},
          dictionary:{ "VIEW":"مشاهده"},
          orderType:{'LIMITED_SELL':'حد-فروش',"LIMITED_BUY":"حد-خرید",'MARKET_SELL':'بازار-فروش',"MARKET_BUY":"بازار-خرید"},
          cryptos:{'بیتکوین':'BTC','لایت کوین':'LTC','دوج کوین':'DOGE','اتریوم':'ETH'},
          underlinedBtn:`<div class='container'> <div class='btn'  > مشاهده    <div  class='line'/></div></div>`,
          cryptoTypeDict:{'LIMITED_SELL':"sourceCoin","LIMITED_BUY":'destinationCoin'}

         
      }
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


::v-deep .btn{
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    flex-direction: column;
    font-size: clamp(12px,1.4vw,14px);
    color: #143B74;
}
::v-deep .line{
    height:1.8px ;
    width: 0px;
    transition: width 0.2s;
    background: #143B74;
}
::v-deep .btn:hover > .line {
    /* width: 100%; */
  }

::v-deep .container{
      display: flex;
      justify-content: flex-end;
  }
 ::v-deep .right-link{
     display: flex;
     justify-content: flex-start;
 }

 
.table-row{
    height: 38px;
     /* outline: 1px solid rgba(30, 37, 48, 0.2); */
}
table{
    padding-top: 12px;
      font-size: clamp(12px,1vw,14px);
       max-height: 395px;
      border-collapse:collapse;
    background: #FFFBF2;
    color:var(--gray3);
    border-bottom-left-radius:2px ;
    border-bottom-right-radius:2px ;
    

    width: 100%;
}

.table{
   
    flex-grow: 1;
}
.link{
   cursor: pointer;
}
::v-deep .view-btn{
     color: var(--primary);
     cursor: pointer;
}

::v-deep .disabled-action{
    opacity: 0.5;
}

tbody{
    display: block;
      max-height: 193px;
      overflow:auto;
}

::v-deep .data{
    display: flex;
    align-items: center;
}
tr{
    display: table;
    width: 100%;
    table-layout: fixed;
}
.table:hover{
  overflow-y: auto;
  overflow-y: overlay;
}

.table-header{
    /* background: rgba(37, 67, 130, 0.06); */
    height: 40px; 
    font-weight: bold;
}

::-webkit-scrollbar {
  height: 16px;
  overflow: visible;
  width: 16px;
  display: none;
}
td:last-child{
    text-align: left;
    padding-left: 24px;
}
td:first-child{
    padding-right: 24px;


}


.red{
 color: red;
}

::v-deep .green{
    color: green;
}



@media(max-width:900px){
    .resp-1{
        display: none;
    }
}
@media(max-width:550px){
    .resp-2{
        display: none;
    }
}

@media(max-width:400px){
    .resp-3{
        display: none;
    }
}
</style>