<template>
    <VueTradingView  class="chart" :options="chartOptions"/>
</template>

<script>
import VueTradingView from 'vue-trading-view';
export default {
  name: 'TradeView',
  components: {
    VueTradingView,
  },
  props:{
    cryptoSymbol:{
      type:String
    }
  },
  data(){
    return{
   chartOptions:{
   width:"100%",
   height:"360px",
  "symbol": this.cryptoSymbol=='USDT'?"BINANCEUS:USDTUSD":"BINANCE:"+this.cryptoSymbol+"USDT" ,
  "interval": "D",
  "timezone": "Asia/Tehran",
  "theme": "light",
  "style": "1",
  "locale": "fa_IR",
  "toolbar_bg": "#f1f3f6",
  "enable_publishing": false,
  "hide_side_toolbar": false,
  "allow_symbol_change": true,
}
 
      
    }
  }
}
</script>
<style scoped>

</style>