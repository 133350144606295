<template>
<div class="filter-container">
  <div @click="dropShow=!dropShow" class="filter">
      <div class="filter-label"><div>{{choice.label}}</div><img :class="{'open-drop-icon':dropShow}" src="../../../assets/icons/black-drop.svg" ></div>
      <div class="dropdown" v-if="dropShow">
          <div v-for="(item,index) in items.filter(item=>item.key!=choice.key)" :key="index" @click="choice=item;$emit('choose',item)"  class="item"> {{item.label}}</div>
      </div>
  </div>

</div>
    
</template>

<script>
export default {
  name: 'FilterDrop',
  props:{
    items:Array,
  },
  data(){
      return{
          dropShow:false,
         choice:this.items[0]


      }
  }
}
</script>
<style scoped>
.filter{
    background: #EDF1ED;
    border-radius: 6px;
    color: var(--gray3);
    font-size: 12px;
    padding: 0px 14px;
    cursor: pointer;
    width: 100%;
    right: 0px;
    top: 0px;
    position: absolute;
}

.filter-label{
    height: 36px;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.open-drop-icon{
    transform: rotate(180deg);
}

.dropdown{
    /* position: absolute;
    background: #EDF1ED; */

    
}

.filter-container{
    position: relative;
   max-width: 120px;
   margin-right: 10px;
   margin-bottom: 35px;
    z-index: 3;

   flex-grow: 1;


}
.item{
    padding: 4px 0px;
}
</style>