<template>
<div>
        <EmailVerify v-if="choosed=='EMAIL'"  @submit="submitted($event)" :email="email" @close="choosed=''"/>
        <GoogleVerify v-if="choosed=='GOOGLE_AUTHENTICATOR'"  @submit="submitted($event)" :qrCode="qrCode" @close="choosed=''"/>
        <SMSVerify v-if="choosed=='SMS'"  @submit="submitted($event)"  :mobile="mobile" @close="choosed=''"/>
  
        <div class="section-title">شناسایی دوعاملی</div>
        <div class="statics section">
            <p>تایید هویت دو مرحله ای جهت ورود به حساب کاربری  و درخواست برداشت </p>
            <div class="options">
                <div v-for="(choice,index) in choices" :key="index" class="option">
                    <div class="checkbox" @click="showModal(choice.value)">
                        <i class='fas fa-check fa-lg tik' v-if="choice.value==twoStep"/></div>
                    <span>{{choice.label}}</span>
                </div>
            </div>
    
         </div>
</div>
   


  
    
</template>

<script  >
import SMSVerify from '../../modals/SMSVerify'
import EmailVerify from '../../modals/EmailVerify'
import GoogleVerify from '../../modals/GoogleVerify'
export default {
  name: 'Settings',
  components:{
      EmailVerify,GoogleVerify,SMSVerify
  },
  methods:{ 
   
        async getTwoStepAuth() {
            this.twoStep = this.state.userInfo.towStepAuthenticationType
        },
        async showModal(methodType) {
            this.choosed=methodType
            this.state.loading = true
            const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=${this.choosed}`).catch(() => {
                this.getTwoStepAuth()
            })
            if (res) {
                this.mobile = res.baseDTO.starTwoStepAuthentication || ''
                this.qrCode = res.baseDTO.file || ''
                this.qrLink = res.baseDTO.qrCodeLink || ''
                console.log(this.twoStep)
                console.log(res)
            }
            this.getTwoStepAuth()
        },
        async submitted(e){
            this.completedText = e;
            await this.$error('', e, 'success')
            await this.$getuserInfo()
            this.getTwoStepAuth()
            
        },
  },
  mounted(){
            this.getTwoStepAuth()
  },
  
    
  data(){
    return{
          twoStep: this.state.towStepAuthenticationType,
      qrCode: '',
      qrLink: '',
      email:  this.state.userInfo.email,
      mobile: '',
      completedText:'',
      choosed:'',
      choices:[
        {label:"ایمیل",value:"EMAIL"},
        {label:"پیامک",value:"SMS"},
        {label:"شناسایی دوعاملی گوگل",value:"GOOGLE_AUTHENTICATOR"}
      ]
     
      
    }
  },
 
}
</script>
<style lang="scss" scoped>
.links{
   
    padding: 15px 24px 24px 0px;
}

.pass-change{
    width: 100%;
    max-width: 294px;
  
}

.disabled{
    opacity: 0.7;
}

.authentication-confirm-btn{
    margin-top: 80px;
}
.checkbox{
    width:16px;
    height:16px;
    background: #F2F2F2;
    margin-left: 4px;
    position: relative;
    align-items:center;
    justify-content: center;
    border: 1px solid rgba(199, 199, 199, 0.22);
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;
}
.tik{
   position: absolute;
   margin-top: 6px;
}
.option{
    display: flex;
    column-gap: 6px;
    align-items: center;
}

.pass-inputs{
    display: flex;
    flex-direction: column;
    row-gap:20px;  
}
  .options{ 

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        row-gap: 20px;
    }
.settings-page{
    display: flex;
    flex-wrap: wrap;
   
    padding-bottom: 50px;
    column-gap: 50px;
.statics{
    max-width: 830px;
    padding-right: 24px;
    font-size: clamp(12px,1.3vw,16px);
  
}    
.section-title{
    padding-top:15px;
    padding-bottom: 24px;
    font-size: clamp(14px,1.4vw,16px);
     color: var(--gray2);
}

}

@media(max-width:412px){
    .options{
        justify-content: space-between ;
       
    }
}
</style>