<template>
<div class="container">
    <div class="navbar">
        <div class="links">
            <img class="bodex" @click="$router.push('/')" src="../../assets/icons/bodex.svg" />
            <i id="sidebar-burger" @click="sideWidth=(sideWidth=='150px')?'0px':'150px'" class="fas fa-bars fa-lg sidebar-burger"></i>
            <li class="link-container link" v-for="(link,index) in links " :key="index">
                <UnderLinedLink :href="'/'+link.name">{{link.title}}</UnderLinedLink>
            </li>
        </div>
        <div class="profile-link" v-if="state.token" >
            <span><img @click="profileDrop=!profileDrop" class="profile-icon" src='../../assets/icons/user-dark.svg' /></span>
            <ProfiletDropDown id="profile-drop" v-if="profileDrop" />
        </div>
        <div class="btns" v-else>
            <div class="login-link">
                <UnderLinedLink @clicked="$router.push('/Login')" href="/Login">ورود</UnderLinedLink>
            </div>
            <button class="blue-btn" @click.prevent="$router.push('/SignUp')"> <a href="/SignUp" @click.prevent="">ثبت نام</a></button>

        </div>
        <!-- <div  class="profile-link">
            <span><img @click="profileDrop=!profileDrop" class="profile-icon" src='../../assets/icons/user.svg' /></span>
            <ProfileDropDown id="profile-drop" v-if="profileDrop" />
        </div> -->
    </div>
    <div class="sidebar" :style="{width:sideWidth}">
        <li class="side-link" v-for="(link,index) in links" :key="index">
            <a :href="'/'+link.name" @click.prevent="$router.push('/'+link.name)">{{link.title}}</a>
        </li>
    </div>

</div>
</template>

<script>
import ProfiletDropDown from './components/ProfileDropDown'
export default {
    name: 'GeneralNavbar',
    created() {
        document.addEventListener('click', (e) => {
            if (!e.target.closest('.sidebar, .sidebar-burger')) {
                this.sideWidth = '0px'
            }
        })
    },
    components: {
        ProfiletDropDown
    },
    data() {
        return {
            profileDrop:false,
            sideWidth: '0px',
            links: [{
                    title: 'سفارش سریع',
                    name: 'FastOrder'
                },
                {
                    title: 'بازار ها',
                    name: 'Markets'
                },
                {
                    title: 'کارمزد ها',
                    name: 'Commissions'
                },
                {
                    title: 'اخبار',
                    name: 'News'
                },
                {
                    title: 'آموزش ',
                    name: 'Teach'
                },
                {
                    title: 'قوانین ',
                    name: 'Terms'
                },
                {
                    title: 'تماس با ما ',
                    name: 'ContactUs'
                },
                {
                    title: 'راهنما',
                    name: 'FAQ'
                },
                // {title:'درباره ما',name:'AboutUs'},
            ]

        }
    }
}
</script>

<style scoped>
.container {
    position: fixed;
    top: 0px;
    z-index: 10;
    width: 100%;
}
.profile-icon{
    padding: 0px 12px 0px 12px;
    cursor: pointer;
}

.profile-link{
    height: 100%;
}
.profile-link :last-child {
    left: 0px;
}
.profile-link > span{
    height: 100%; 
display: flex;
align-items: center;    
}
.sidebar {

    position: absolute;
    display: none;
    white-space: nowrap;
    z-index: 1;
    top: 0;
    right: 0;
    background: #FAFCFF;
    box-shadow: 0px 0px 10px rgba(231, 167, 67, 0.2);
    text-align: right;
    overflow-x: hidden;
    margin-top: 80px;
    height: calc(100vh - 80px);
    transition: width 1s;
}

.side-link {
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.navbar {
    display: flex;
    background: #FAFCFF;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    column-gap: 40px;
    padding-right: clamp(25px, 3vw, 44px);
    padding-left: 24px;
    height: 80px;

    width: 100%;
}

.link {
    color: #143B74;
    font-size: clamp(14px, 1.3vw, 16px);
    white-space: nowrap;

}

a {
    color: inherit;
}

.login-link {
    color: #143B74;
    display: flex;
    justify-content: center;
    align-items: center;

}

.btns {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 240px;

}

.btns>* {
    height: 36px;
    font-size: clamp(14px, 1.3vw, 16px);
    flex-grow: 1;
}

.bodex {
    width: clamp(90px, 12vw, 153px);
    height: auto;
    cursor: pointer;
}

.links {
    max-width: 758px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-grow: 1;

    column-gap: 15px;
}

.sidebar-burger {
    display: none;
    padding-right: 15px;
}

@media(max-width:775px) {
    .link {
        display: none;
    }

    .links {
        column-gap: 0px;
    }

    .sidebar-burger {
        display: block;
    }

    .sidebar {
        display: block;
    }
}
</style>
