<template>
<Modal @close="$emit('close')">
    <template #header>
        <div>شناسایی دوعاملی <span class="modal-title">پیامک</span></div>
    </template>
    <template #body>
        <p> کد 6 رقمی ارسال شده به شماره تلفن  <span class="number" dir='ltr'>{{mobile}}</span> را وارد نمایید </p>
        <VerifyCodeInput @change="e=>otp.code=e" @complete="submit()"/>
        <button class="modal-confirm-btn">بستن</button>
    </template>
</Modal>
  
    
</template>

<script>
import Modal from './Modal'
export default {
  name: 'SMSVerify',
  components:{
      Modal
  },
  props:['mobile'],
  data(){
    return{
        otp:{
          code:''
      },
    }
  },
  methods:{
          async submit() {
                // const a = {
                //     'EMAIL': 'ایمیل',
                //     'SMS': 'پیامک',
                //     'GOOGLE_AUTHENTICATOR': 'Google Authenticator'
                // }
                if (this.otp.code.toString().length === 6) {
                        this.state.loading = true
                        const [res,] = await this.$http.post(`/users/change-otp?twoStepAuthorization=SMS&code=${this.otp.code}`)
                        if(res){
                            // this.reset()
                            this.$emit('close')
                            this.$emit('submit', 'شناسایی دو مرحله ای شما به ' + 'پیامک' + ' تغییر یافت.')
                        }
                   
                }
                
            },
  }
}
</script>
<style scoped>
.modal-title{
    color: #143B74;
    margin-right: 12px;
}

.number{
font-weight: bold;
}

p{
    font-size: 14px;
}
</style>