<template>
<div>

    <div class="trade-card">
        <LabelInput type='number' :label="'مقدار('+crypto.relatedCoin+')'" @input="value=>amount=value" :value="$toLocal(amount,$decimal[tradeFrom])" />
        <div class="depsoit-slider">
            <div class="depsoit"> موجودی: <div class="depsoit-value">{{credit}}</div> {{isBuy?'تومان':crypto.relatedCoin}} </div>
            <span>{{amountPercent}}%</span>
            <ValueSlider @change="value=>amountPercent=value" />
        </div>

        <LabelInput type='number' :label="'قیمت واحد('+baseCurrencyDict[tradeTo]+')'" @input="value=>unitPrice=value" :value="unitPrice" :disabled="marketType=='MARKET'" />
        <div v-if="isBuy" class="limit">پایین ترین پیشنهاد فروش:<div class="limit-value">{{shopInfo.minSell}}</div>تومان</div>
        <div v-else class="limit">بالا ترین پیشنهاد خرید:<div class="limit-value">{{shopInfo.maxBuy}}</div>تومان</div>
        <!-- <Calendar :uniqkey="isBuy?1:2" :future="true" @input="value=>date=value" label='تاریخ انقضا' v-if="marketType=='LIMIT'" /> -->
        <date-picker v-if="marketType=='LIMIT'" class="calender-comp"   
            color='#143B74' :autoSubmit="true" 
            :type="'datetime'"
            placeholder="تاریخ انقضاء"
            :format="$dateFormat['en']"
            :display-format="$dateFormat['faDateTime']"
            :min="$jmoment().format($dateFormat['en'])"
            ></date-picker>
        <p class="error"><span v-if="disabled">*{{errorTxt}}</span></p>
        <div class="sum">
            <div><span>مبلغ کل سفارش({{baseCurrencyDict[tradeTo]}})</span>&nbsp;&nbsp;&nbsp;{{sum}}</div>
            <div class="sum-commission"><span>کارمزد </span> <span class="sum-commission-value">&nbsp;&nbsp;&nbsp;{{fee}}&nbsp;%</span></div>
        </div>

        <template v-if="state.userInfo.authorized || state.userInfo.supervisor" :disabled="readonly">
            <button v-if="isBuy" class="btn green" :disabled="disabled" :class='{disabled:disabled}' @click="showModal()">ثبت سفارش خرید</button>
            <button v-else class="btn red" :disabled="disabled" :class='{disabled:disabled}' @click="showModal()">ثبت سفارش فروش</button>

        </template>

        <button @click.prevent="" class="btn blue" v-else-if="state.userInfo.pending" :disabled="true">
            حساب تایید نشده
        </button>
        <button @click.prevent="$router.push('/authentication')" class="btn" v-else>
            تکمیل حساب
        </button>

    </div>

    <div class="table-card">
        <div v-if="isBuy" class="table-title">فروشندگان</div>
        <div v-else class="table-title">خریداران</div>
        <div>
            <div class="table-header">
                <div>مقدار({{crypto.relatedCoin}})</div>
                <div>قیمت واحد({{baseCurrencyDict[tradeTo]}})</div>
                <div>قیمت کل({{baseCurrencyDict[tradeTo]}})</div>
            </div>
            <div class="table-row" v-for="(order,index) in tableContents" @click="marketType=='Market'?amount=order.volume:''" :key="index">
                <div :class="{'table-fill-red':isBuy,'table-fill-green':!isBuy}" :style="{width:order.executedPercent+'%'}" />
                <div class="row-grid-container">
                    <div>{{order.volume}}</div>
                    <div>{{order.unitPrice.toLocaleString()}}</div>
                    <div>{{order.wholePrice.toLocaleString()}}</div>
                </div>
            </div>

        </div>
    </div>
    <TradeModal @confirm="$router.push($route.path)" @modalToggle='value=>modalType=value'  v-if="modalType=='TradeModal'" :data="data" />
</div>
</template>

<script>
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'

// import Calendar from '../../tools/Calendar'
import ValueSlider from './ValueSlider'
import TradeModal from '../../modals/TradeModal'
import {
    tradeTo,
    tradeFrom,
} from "@/library/reuseableFunction";
export default {
    name: 'TradeComp',
    components: {
        // Calendar,
        ValueSlider,
    datePicker: VuePersianDatetimePicker,
        TradeModal,
    },

    props: ['tableContents', 'crypto', 'isBuy', 'wallets', 'shopInfo', 'marketType'],

    computed: {
        tradeTo,
        tradeFrom,
        sum() {
            return this.$toLocal(this.$S2N(this.amount) * this.$S2N(this.unitPrice), 0)
        },

        credit() {
            return (this.isBuy) ?
                ((this.wallets['TOMAN']) ? this.$toLocal(this.wallets['TOMAN'].credit, 0) : 0) :
                ((this.wallets[this.$coin2Snake[this.crypto.relatedCoin]]) ? this.wallets[this.$coin2Snake[this.crypto.relatedCoin]].credit : 0)
        },
        fee() {
            return this.$S2N(this.state.userInfo.fee)
        },
        disabled() {
            return !this.$S2N(this.amount) ||
                !this.unitPrice ||
                (this.$S2N(this.amount) > this.$S2N(this.credit)) ||
                (this.$S2N(this.amount) > this.$S2N(this.crypto.maxAmount)) ||
                (this.$S2N(this.amount) < this.$S2N(this.crypto.minAmount))
        },
        errorTxt() {
            if ((this.$S2N(this.amount) > this.$S2N(this.credit)))
                return 'موجودی کافی نمیباشد'
            else if ((this.$S2N(this.amount) < this.$S2N(this.crypto.minAmount)))
                return 'مقدار معامله باید حداقل' + this.$coins[this.tradeFrom].code + ' ' + this.crypto.minAmount + 'باشد'
            else if ((this.$S2N(this.amount) > this.$S2N(this.crypto.maxAmount)))
                return 'مقدار معامله باید حداکثر ' + this.$coins[this.tradeFrom].code + ' ' + this.crypto.maxAmount + ' باشد '
            else if(!this.percentSliderActive && this.amountPercent>0)    
                return ' برای استفاده از اسلایدر مقدار ابتدا قیمت را وارد کنید'
           else if (!this.amount)
                return 'مقدار درخواستی را وارد نمایید'
            else if (!this.unitPrice)
                return 'قیمت واحد را وارد کنید'
            else
                return ''

        },
        percentSliderActive(){
            return !(this.marketType=='LIMIT' && this.isBuy && !this.unitPrice) 
        }

    },
    watch: {
         
        'amountPercent': function () {
            if(this.marketType=='MARKET' && this.isBuy)
                this.getAmountFromServer()
            else if(this.percentSliderActive){
                this.localCalculate()    
            }
        },
        'amount':function(){
            if(this.marketType=='MARKET'){
                this.getPriceFromServer()
                console.log(this.unitPrice)
            }
        }
    },
    methods: {
        showModal() {
            if (!this.disabled) {
                this.data = {
                    amount: this.amount,
                    unitPrice: this.unitPrice,
                    destinationCoin: this.tradeFrom,
                    type: this.isBuy ? 'BUY' : 'SELL',
                    isLimit: this.marketType === 'LIMIT'
                }
                if(this.date)
                    this.data.date=this.date
                console.log(this.isBuy)
                this.modalType = 'TradeModal'

            }

        },

        
       
        async getPriceFromServer() {
            let amount = this.$S2N(this.amount);
            if (amount) {
                const res = await this.$axios.get('/orders/market-buy-sell-whole', {
                    params: {
                        amount: amount,
                        marketType: this.tradeFrom + '_' + this.tradeTo,
                        orderType: `MARKET_${this.isBuy?'BUY':'SELL'}`
                    }
                })
                let price = res.baseDTO.wholePrice;
                this.totalPrice = this.$toLocal(price.toFixed(0));
                this.unitPrice = this.$toLocal((this.$S2N(this.totalPrice) / amount).toFixed(0));
                console.log(this.unitPrice)
                this.value = this.$S2N(this.totalPrice) / this.cash
            }
        },
        localCalculate() {
            let tempAmount=(this.amountPercent/100)*this.$S2N(this.credit)
            if(this.isBuy)
                this.amount= tempAmount / this.$S2N(this.unitPrice)
      
            else 
                this.amount= tempAmount
        },
        async getAmountFromServer(a, p) {
            this.percent = p
            let e = this.percent * this.cash
            if (e) {
                this.getAmount = true
                const res = await this.$axios('/orders/amount-via-price', {
                    params: {
                        marketType: this.tradeFrom + '_' + this.tradeTo,
                        orderType: 'MARKET_BUY',
                        amount: e
                    }
                }).catch(() => {
                })
                this.getAmount = false
                if (res) {
                    this.amount = this.$toLocal(res.baseDTO.wholePrice.toFixed(a))
                    // this.calculate()
                }
            }
        },
        calculate() {
                if (this.marketType=='LIMIT' && this.unitPrice && this.amount) {
                    this.localCalculate()
                } else if (this.marketType=='MARKET') {
                    this.serverCalculate()
                }
            },
           async serverCalculate() {
                this.calculating = true
                const a = this.amount
                const temp = ++this.count
                await this.$sleep(500)

                if (a) {
                    while (this.alive && a && a === this.amount && temp === this.count) {
                        try {
                            await this.getPriceFromServer()
                            this.calculating = false
                            await this.$sleep(5000)
                        } catch (e) {
                            this.calculating = false
                            break
                        }
                    }
                } else {
                    this.calculating = false
                }


            },    
        
        filterOrders(filter) {
            return this.orders.filter(order => {
                if (order.tradeType == filter)
                    return order
            })
        },

        isLoaded() {
            return this.wallets && this.crypto && this.tableContents && this.shopInfo.minSell
        },

    },
    data() {
        return {
            baseCurrencyDict: {
                "TOMAN": "تومان",
                "TETHER": "تتر"
            },
            sellValuePercent: 0,
            buyValuePercent: 0,
            amountPercent: 0,
            unitPrice: 0,
            date: '',
            data: {},
            amount: 0,
            modalType: ''

        }
    },

    mounted() {

    }
}
</script>

<style scoped>
.disabled {
    opacity: 0.7;
}
.table-row:hover{
    background: rgba(0,0,0,0.1);
    border-radius: 5px;
}
.table-title {
    color: var(--gray2);
    font-weight: bold;
    font-size: 14px;
    padding: 40px 0px 12px 0px;
}

.error {
    font-size: clamp(12px, 1.3vw, 14px);
    color: red;
}

.table-header>div:last-child,
.row-grid-container>div:last-child {
    text-align: left;
}

.table-header>div:nth-child(2),
.row-grid-container>div:nth-child(2) {
    text-align: center;
}

.table-card {
    flex-grow: 1;

}

.tables {
    display: flex;
    column-gap: 48px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.table-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: 12px;
    color: var(--gray3)
}

.row-grid-container>div:nth-child(2) {
    font-weight: bold;
}

.table-row {
    position: relative;
    margin-top: 1px;
    cursor: pointer;
    display: flex;

    /* padding: 1px 0px; */
    /* display: flex; */
    align-items: center;
    height: 24px;
    font-size: 12px;
    color: var(--gray2);

}

.row-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-grow: 1;
    padding: 0px 4px;
    /* border:1px solid; */

}

.row-grid-container>div {
    z-index: 1;
}

.table-fill-red {
    background: rgba(250, 50, 86, 0.23);
    box-shadow: inset 13.6px -13.6px 13.6px rgba(222, 170, 179, 0.1), inset -13.6px 13.6px 13.6px rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(27.2px);
    border-radius: 4px;
    height: 100%;
    position: absolute;
    z-index: 0;

    left: 0px;
}

.table-fill-green {
    height: 24px;
    background: rgba(3, 199, 11, 0.3);

    box-shadow: inset 13.6px -13.6px 13.6px rgba(222, 170, 179, 0.1), inset -13.6px 13.6px 13.6px rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(27.2px);
    position: absolute;
    z-index: 0;
    border-radius: 4px;

    height: 100%;

    right: 0px;
}

.depsoit-slider {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 10px;
    width: 100%;
    justify-content: space-between;
}

.trade-section {
    display: flex;
    column-gap: clamp(15px, 2.7vw, 48px);
    ;
    row-gap: 60px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.trade-section>div {
    flex: 1;
    flex-basis: 280px;
}

.trade-card {
    flex: 1;
    display: flex;
    padding-top: 12px;

    flex-direction: column;
    row-gap: 10px;
}

.toggle-comp {
    max-width: 247px;

}

.trade-comp {
    padding: 16px 24px;

}

.depsoit {
    font-size: 12px;
    column-gap: 3px;
    display: flex;
    color: var(--gray3);
}

.depsoit-value {
    color: var(--primary);
    font-size: 14px;
    font-weight: bold;
}

.limit {
    display: flex;
    column-gap: 3px;
    font-size: 12px;
    color: var(--gray3);
}

.limit-value {
    color: var(--gray6);
    font-size: 14px;
    font-weight: bold;
}

.sum {
    color: var(--gray2);
    font-size: 14px;
    background: #EDF1ED;
    padding: 8px 16px;
    font-weight: bold;
}

.btn {
    width: 100%;
    height: 40px;
    color: white;
    border-radius: 4px;
}

.green {
    background: var(--green);
}

.red {
    background: var(--red);
}

.sum-commission {
    font-size: clamp(10px, 1.1vw, 12px);
    font-weight: normal;
    margin-top: 8px;
}

.sum-commission-value {
    color: var(--gray4);
    margin-right: 8px;
}
</style>
