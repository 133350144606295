<template>
  <div class="link-container">
    <div class="link">
          <a :href="href"  @click.prevent="$router.push(href)"><slot></slot> </a>

         <div class="line"/>
    </div>
  </div>
    
</template>

<script>
export default {
  name: 'UnderLinedLink',
  props:{
      href:String
  }
}
</script>
<style scoped>

  .line{
    height: 1.6px ;
    background: #143B74;
    width: 0px;
    transition: width 0.2s;
  
  }
  
  .link-container:hover .line{
    width: 100%;
  }
  
  .link-container{
    display: flex;
    
    justify-content: center;
    padding: 0px 5px;
    align-items: center;
  }

  .link > a{
      color: #143b74;
  }

  .link{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
</style>
