<template>
<div @click="$emit('click')">
  <div v-if="errorText" class="error" >*{{errorText}}</div>
  <div :class="{'label-input':true,disabled:disabled }" >
      <label >{{label}}</label>
      <div class="input-container" >
          <input   :type="(type=='password'&& !passShow)?'password':'text'" :maxLength="maxLength"  @input="inputHandler()" v-model="temp" :disabled="disabled" />
          <img  v-if="type=='password'" src="../../assets/icons/eye.svg" :style="{opacity:(passShow)?'1':'0.5'}" @click="passShow=!passShow"  class="eye"/>
        
      </div>
  </div>
</div>
    
</template>

<script>
export default {
  name: 'LabelInput',
  props:['label','disabled','value','errorText','type','maxLength'],
 
  
  data(){
    return{
      passShow:false,
      temp:this.value
    }
  },
  watch:{
       'value'(){
         this.temp=this.value
       }
  },
  methods:{
    inputHandler(){
      if(this.type=="number")
        {this.temp=this.$toLocal(this.temp)}
      else if(this.type=='nochar')  
        this.temp=this.temp.replace(/[^\d.]|\.\./ig,'')
      this.$emit('input',this.temp)
    },
    
    // p2e(string){ return string.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))},
//     toLocal(number, decimal = 8){
    
//     number = number + ''
//     if (number === '.') {
//         if (decimal)
//             return '0.'
//         else
//             return ''
//     }

//     if (number.startsWith('-'))
//         return 0

//     if (number.toLowerCase().includes('e')) {
//         if (parseFloat(number) < 1.0e-7) {
//             //zero numbers
//             return 0
//         } else
//             number = parseFloat(number) + ''
//     }

//     number = this.p2e(number).replace(/[^\d.]|\.{2}|,/ig, '')
//     if (number.includes('.')) {
//         number = number.split('.')[0] + '.' + (number.split('.')[1] || '')
//     }

//     if (parseFloat(number)) {
//         //separate number
//         const int = parseFloat(number.split('.')[0]).toLocaleString()
//         if (number.includes('.') && decimal) {
//             return int + '.' + (number.split('.')[1].slice(0, decimal) || '')
//         } else {
//             return int
//         }
//     } else {
//         return number
//     }

// }
  }
  ,mounted(){
    this.temp=this.value
  }
}
</script>
<style scoped>
.label-input{
    margin-top:6px;
    /* width: 100%; */
    display: flex;
    text-align: right;
    /* flex: 1; */
}
input{
    background: #EDF1ED;
     height: 40px;
     flex-grow: 1;
     size: 1;
    width: 60px;
    color: #828282;
    border-top-left-radius:6px;
    border-bottom-left-radius:6px;

}

.error{
  color: red;
  font-size: clamp(10px,1.2vw,14px);
  display: flex;
  line-height: 0px;
  align-items: center;
}
.input-container{
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}
label{
     border-radius: 0px 6px 6px 0px;
    font-size: clamp(12px,1.3vw,13px);
    background: #EDF1ED;
    color: #828282;

    display: flex;
    padding:0px 10px;
    align-items: center;
  flex-grow: 1;
  white-space: nowrap;
 

    
}

.eye{
   position: absolute;
   left:10px;
   cursor: pointer;
  
}

.disabled{
  opacity: 0.8;
}
</style>
