<template>
  <div  class="exchange-page">
    <div v-if="orders" class="orders-section">
    <div class="page-title">تاریخچه سفارشات </div>
    <div>
      <div class="order" v-for="(order,index) in orders" :key="index">
        <div class="order-top">
            <div class="date">{{$G2J(order.createdAtDateTime)}}</div>
            <div class="condition">{{statusLabel(order.orderStatus)}}  </div>
        </div>

        <div class="order-bottom">
          <div> 
            <span class="crypto-title">{{order.crypto}}</span>
            <span :class="{green:order.orderType=='EXCHANGE_BUY',red:order.orderType=='EXCHANGE_SELL'}">{{order.amount}}</span>
          </div>
          <div>
            <span class="total-price">{{$toLocal(order.unitPrice,0)}}</span>
            <span class="toman">تومان</span>
          </div>
        </div>
      </div>
    </div>
    </div>
    <TradeSection/>
     
  </div>
    
</template>

<script>
import TradeSection from './TradeSection'
import {
    statusLabel,

} from "@/library/reuseableFunction";

export default {
  name: 'Exchange',
  components:{
    TradeSection
  },
  computed:{
    statusLabel
  },
  data(){
    return{
      orders:[],
     
      
    }
  },
  methods:{
      async getActiveOrders() {
            const res = await this.$axios.get('/orders?orderType=EXCHANGE_BUY,EXCHANGE_SELL', {
                params: {

                }
            })
            this.orders = res.content;
            // this.$emit('update:activeLength', this.orders.length)
        },
  },
  mounted(){
    this.getActiveOrders()
  }
}
</script>
<style scoped>
.order{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 8px 12px;
  background: #FEFEFE;
  font-size: clamp(13px,1.3vw,14px);
  border-radius: 8px;
  height: 70px;

}

.order:hover{
 background: #DFF4F4;
}

.order > div{
   display: flex;
   justify-content: space-between;
}

.date{
  color: var(--gray3);
}
.toman{
  color: var(--gray3);
  font-size: clamp(10px,1.1vw,12px);
  
}

.crypto-title{
  color: var(--gray6);
}

.total-price{
    color: var(--gray4);
    font-size: clamp(12px,1.1vw,14px);


}

.exchange-page{
  display: flex;
  flex-wrap: wrap;
     column-gap: 20px;
    row-gap: 20px;
}
.order-bottom > div{
  display: flex;
  column-gap: 4px;
}
.condition{

  color: var(--primary);

}

.orders-section{
  flex: 1;
  flex-basis: 240px;
}

.red{
  color: var(--red);

}

.green{
  color:var(--green)
}
</style>