<template>
<div class="chart">
     <apexchart type='donut' :key='series.length+""+change' :series="series" :options="options"></apexchart>
</div>
  
    
</template>

<script>

export default {
  name: 'WalletChart',
  props:['colors','wallets'],
 
      mounted(){
         this.chart()
    
  },
  methods:{
          chart() {
            let index = 0
            Object.entries(this.wallets).forEach((e) => {
                    this.options.labels[index] = {
                        'name': this.$coins[e[0]].persianName,
                         'value': e[1].credit || 0
                    }
                    
                    this.series[index] = e[1].wholePriceOfCredit || e[1].totalBalance || 0
                    index++
                })
                ++this.change
        },
  },
 watch:{
   wallets(){
     this.chart()
   }
 },
  data(){
    return{
         
        change:0,
        series:[0],
         options: {
           tooltip:{
             enabled:false
           },
            xaxis: {
              type: "numeric"
            },
            labels: [],
             
            dataLabels:{
              enabled:false,
            },
            states:{
               hover: {
                    filter: {
                        type: 'lighten',
                 }  }, },
           
            legend:{show:false},
            stroke:
             {
            show: true,
            curve: 'smooth',
            lineCap: 'round',
             width: 2,
            },
            plotOptions: {
                pie: {
                     expandOnClick: false,

                  donut: {
                    size: '93%',
                  
                    labels: {
                    show: true,
                  
                    name: {
                        show: true,
                        fontSize: '12px',
                        fontFamily: 'Yekan',
                        fontWeight: 600,
                        offsetY: -17,
                        formatter: function (val) {
                            return val.name || val
                        }
                      },
                      value: {
                          show: true,
                          fontSize: '12px',
                          fontFamily: 'Yekan-en',
                          fontWeight: 400,
                          offsetY: 10,
                          formatter: function (val) {
                              return parseFloat(val).toLocaleString()
                          }
                      },
                      total: {
                          show: true,
                          showAlways: false,
                          label: 'ارزش کل دارایی',
                          fontSize: '12px',
                          fontFamily: 'Yekan',
                          fontWeight: 600,
                          formatter: function (w) {
                              w = w.globals.seriesTotals.reduce((a, b) => a + b)
                              return w.toLocaleString() + '\n' + 'تومان'
                          }
                      }
                  }
                  }
                }
              }
              ,     
      },


      
    }
  }
}
</script>
<style scoped>
.chart{
  /* display: flex; */
  /* justify-content: center; */
  /* width: 100%; */
  /* border: 1px solid; */
}
</style>