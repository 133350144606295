<template>
<div >
    <div class="page-header">درباره بدکس</div>
    <div class="aboutus-container">
        <div class="description">
             <p class="text-container">نام نوبیتکس از ترکیب سه واژه "نو" به معنای جدید، "بیت‌کوین" و"اکسچنج" به معنای مبادله ساخته شده است.<br/>
                  هدف ما فراهــــم نمودن بستری امن، سریع و آسان جهت خرید و فروش مستقیم ارزهای دیجیتال بین خریدار و فروشنده است.<br/>            
                  این بستر به عنوان واسطه ای میان خریداران و فروشندگان، امنیت معاملات و وصول دارایی های مبادله شده را تضمین می‌کند.
            </p>
            <div class="logo" >
             <img src='../../assets/public/bodex.png'/>

            </div>

        </div>
        <div class='history'>
            <div class="head">شکل گیری بدکس</div>
            <div class="step right">شروع به کار ۱۳۹۹-۱۴۰۰</div>
            <div class="step center">تاسیس صرافی ۱۳۹۹-۱۴۰۰</div>
            <div class="step left">تاسیس صرافی ۱۳۹۹-۱۴۰۰</div>

        </div>
       


    </div>

</div>
  
    
</template>

<script>
export default {
  name: 'AboutUs',
  data(){
    return{
      
    }
  }
}
</script>
<style scoped>
.aboutus-container{
   background:#FAFCFF;
   padding: 26px;
}

.head{
    color: #143B74;
    font-size:clamp(20px ,2.2vw, 24px);
}

.step{
    color:#143B74;
    width:100%;
    font-size: clamp(16px,1.7vw,18px);
    margin-top: 48px;
}

.right{
    text-align: right;
}
.left{
    text-align: left;
}
.center{
    text-align: center;
}
.description{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}
.text-container{
    max-width:580px;
    flex-basis: 350px;
    flex-grow:3;
    color: #4F4F4F;

}

.logo{
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
}

.logo > img{
    width: 100%;
    height: auto;
    max-width: 420px;
}
</style>