<template>
  <div class="label-input"  >
      <label >{{label}}</label>
      <div class="input-container" >
          <input id='calendar-input' :value="$G2J(date)" />
          <div class="icon-box eye"><img  src="../../assets/icons/calendar.svg" /></div>

            <!-- custom-input="#calendar-input"  -->
            <date-picker class="calender-comp"   
            color='#143B74' :autoSubmit="true"
             v-model="date" 
            id="birthday"
            :disabled="disabled"
            :type="isBirth?'date':'datetime'"
            :format="$dateFormat['en']"
            :display-format="$dateFormat[isBirth?'fa':'faDateTime']"
            :max="(future)?'':$jmoment().format($dateFormat['en'])"
            :min="(future)?$jmoment().format($dateFormat['en']):''"

            
            ></date-picker>
            <!-- :disabled="disabled" -->
                      
 <!-- :show='showDatePicker' -->
      </div>
     
  </div>
    
</template>

<script>
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
export default {
  name: 'Calendar',
  components: {
    datePicker: VuePersianDatetimePicker
  },
  props:{
      label:String,
        value:String,
        future:Boolean,
        isBirth:Boolean,
        disabled:Boolean
  },
  watch: {
            'date'() {
                if (this.date)
                    this.$emit('input',this.date)
            },
            'value'(){
               this.date=this.value
            }
        },
  data(){
    return{
      date:this.value
    }
  },
  methods:{
 
  }
}
</script>
<style scoped>
.label-input{
    margin-top:6px;
    /* width: 100%; */
    display: flex;
    text-align: right;
    /* flex: 1; */
}

.icon-box{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: #DFF4F4;
    padding:8px;
      position: absolute;
   left:0px;
   cursor: pointer;

}

.calender-comp{
  opacity: 1;
  direction: ltr;
  color: black;;
  position: absolute;
   background: #EDF1ED;
     height: 40px;
     flex-grow: 1;
     size: 1;
    width: 60px;
    color: #828282;
    border-top-left-radius:6px;
    border-bottom-left-radius:6px;
  width: 100%;
}
input{
    background: #EDF1ED;
     height: 40px;
     flex-grow: 1;
     size: 1;
    width: 60px;
    color: #828282;
    border-top-left-radius:6px;
    border-bottom-left-radius:6px;

}
.input-container{
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
}
label{
     border-radius: 0px 6px 6px 0px;
    font-size: clamp(12px,1.3vw,13px);
    background: #EDF1ED;
    color: #828282;

    display: flex;
    padding:0px 10px;
    align-items: center;
  flex-grow: 1;
  white-space: nowrap;
 

    
}

</style>