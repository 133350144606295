<template>
<Modal @close="$emit('modalToggle','')">
    <template #header>
        <div>واریز {{$coins[relatedCoin].persianName}}</div>
    </template>
    <template #body>
        <div v-if='!selected_net' class="toggle-comp">
            <p> شبکه انتقال ارز را اتنخاب کنید</p>
            <div class="net-options">
                <div class="net-option" :class="{'active-option':cryptoNetworkIndex==index}" v-for="(network,index) in nets" :key="index" @click="cryptoNetworkIndex=index">
                    {{network.tokenType}}
                </div>
            </div>
        </div>
        <div v-else class="description">
            <div class="text-value">
                <div>آدرس کیف پول شما در کادر زیر قابل مشاهده است برای واریز کردن ارز های دیجیتال به این کیف میتوانید از آن استفاده کنید.دقت داشته باشید با توجه به وضعیت شبکه,واریزی شما با تاخیر تایید و ثبت میشود </div>
                <div class="wallet-address">
                    <span class="address-label">آدرس کیف پول</span>
                    <span class="address-value">{{address}}</span>
                    <span class="copy" @click="copy(address)"><img src="../../assets/icons/copy.svg" /></span>
                </div>
                <p>لطفا توجه فرمایید که کیف پول مبدا، شبکه ی انتخابی شما را پشتیبانی کند.</p>

            </div>
            <div>
                <img class="qr-img" :src="'data:image/png;base64,'+qrCode" alt="">
                <div class="img-sub">برای واریز اسکن کنید</div>
            </div>
        </div>
        <button v-if='!selected_net' @click="selectNet()" :class="{'modal-confirm-btn':true}">تایید </button>
        <button v-else class="modal-confirm-btn" @click="$emit('modalToggle','')">بستن </button>
    </template>
</Modal>
</template>

<script>
import Modal from './Modal'
export default {
    name: 'Depsoit',
    components: {
        Modal
    },
    props: {
        relatedCoin: {

            default: function () {
                return ''
            }
        },
        isExchange: {
            default: function () {
                return false
            }
        },

    },
    data() {
        return {
            amount: '',
            address: '',
            qrCode: '',
            tag: '',
            txid: '',
            token: false,
            alertLink: false,
            drop: false,
            selected_net: "",
            txIdIsNeeded: false,
            enable: false,
            coins: [],
            cryptoNetworkIndex: 0,
            nets: []

        }
    },
    methods: {
        copyLink() {
            navigator.clipboard.writeText(this.address);
            this.alertLink = true
            setTimeout(() => {
                this.alertLink = false;
            }, 1500);
        },
        closeModale() {
            this.$emit('closeModaleEmit', this.close)
        },
        changeNetwork(e) {
            this.token = e
            this.getQR()
        },
        Copy(e) {
            navigator.clipboard.writeText(e)
            alert('آدرس کپی شد')
        },
        async checkWallet() {
            this.state.loading = true
            // const token = this.token ? 'ERC20' : 'TRC20'
            let query = {
                relatedCoin: this.relatedCoin[0],
                tokenType: this.selected_net,
                walletType: (this.exchange ? 'EXCHANGE' : 'P2P')
            }
            if (this.txIdIsNeeded) {
                query.txid = this.txid
            }
            const res = await this.$axios.get('/wallets/check-confirmed-transaction', {
                params: query
            })
            let text
            if (res.baseDTO.amount) {
                text = `مقدار ${this.$toLocal(res.baseDTO.amount) || 0} ${this.$coinLabel[this.relatedCoin[0]]} واریز شده و تایید ${res.baseDTO.transactionIsDone ? 'شده' : 'نشده'}`
            } else {
                text = 'مقدار واریزی شناسایی نشد'
            }
            this.$error('', text, res.baseDTO.transactionIsDone ? 'success' : 'info')
        },
        selectNet() {
            this.selected_net = this.nets[this.cryptoNetworkIndex].tokenType
            this.txIdIsNeeded = this.nets[this.cryptoNetworkIndex].txIdIsNeeded
            this.enable = this.nets[this.cryptoNetworkIndex].depositEnable || true
            this.drop = false
            if (this.enable) {
                this.getQR()
            }

        },
        async getData() {
            this.state.loading = true
            const res = await this.$axios.get('/coins/details')
            this.coins = res.content
            const coinData = this.coins.find(coin => coin.coin == this.relatedCoin)
            this.nets = coinData.tokenTypeDetails
            this.wage = coinData.transferFee

        },
        async getQR() {
            this.state.loading = true
            const token = this.selected_net
            let params = {
                relatedCoin: this.relatedCoin
            }
            if (this.relatedCoin === 'TETHER') {
                params.tokenType = token
            }
            console.log(params)
            const res = await this.$axios('/wallets/customer/wallet-address?walletType=' + (this.exchange ? 'EXCHANGE' : 'P2P') + '&tokenType=' + token, {
                params
            }).catch(() => {
                this.closeModale()
            })
            if (res) {
                this.address = res.baseDTO.address
                this.qrCode = res.baseDTO.qrCode
                this.tag = res.baseDTO.tag
            }
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
.modal-title {
    color: #143B74;
    margin-right: 12px;
}

.net-option {
    border-radius: 4px;
    height: 30px;
    font-size: clamp(13px, 1.4vw, 15px);
    display: flex;
    align-items: center;
    padding: 0px 8px;
    background: rgb(214, 212, 212);
    cursor: pointer;
}

.qr-img {
    height: 140px;
    width: 140px;
}

.active-option {
    background: linear-gradient(95.12deg, #143B74 -9.51%, #2B6BC9 104.28%);
    box-shadow: inset 27.2667px -27.2667px 27.2667px rgba(9, 45, 102, 0.1), inset -27.2667px 27.2667px 27.2667px rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 1);
}

.net-options {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.img-sub {
    font-size: 12px;
    color: var(--gray4);
    opacity: 0.7;
    text-align: center;
}

.disabled {
    opacity: 0.7;
}

.toggle-comp {
    flex-grow: 1;
    max-width: 194px;
}

.factor>div {
    display: flex;
    align-items: center;
    column-gap: 6px;
}

.factor {
    padding: 9px 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(216, 211, 242, 0.2);
    border-radius: 4px;
    font-size: 13px;
    color: var(--gray3);
}

.number {
    font-weight: bold;
}

.crypto-amount {
    color: var(--red);
}

.copy {
    display: flex;
    align-items: center;
    background: #DFF4F4;
    height: 100%;
    justify-content: center;
    border-radius: 4px 0px 0px 4px;
    padding: 0px 8px;
    cursor: pointer;
}

.value {
    color: var(--gray4);
    font-weight: bold;
}

.wallet-address {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    background: #F7F7F7;
    font-size: 12px;
    border-radius: 4px;
}

.address-label {
    color: var(--gray5);
}

.address-value {
    color: var(--gray4);
    font-weight: bold;
}

.alert {
    font-size: 12px;
    color: var(--red);
    margin-top: 8px;
}

.description {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    column-gap: 35px;
    row-gap: 15px;
    font-size: 14px;

}

.text-value {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-basis: 200px;
    flex-grow: 1;
}

::v-deep .modal-card {
    max-width: 602px;
}
</style>
