<template>
<div class='commission-page'>
    <div class="page-header">کارمزد ها و سطوح کاربری</div>
    <div>
    <p>کارمزد معاملات بی بیتکس به صورت درصد از مبلغ کل معامله است و محاسبه آن بر اساس ملاحظات زیر صورت می گیرد:</p>
    <p>کارمزد خریدار ارزدیجیتال به صورت ارزدیجیتال کسر و کارمزد فروشنده ارزدیجیتال از مبلغ دریافتی به تومان کسر می شود. </p>
    <p>کارمزد از هر دو سمت معامله کسر می شود.</p>
    <p>در هنگام ثبت معاملات ، کارمزد به شما نمایش داده می شود.</p>
    <p>لازم به ذکر است کارمزد معاملات جفت ارز (به طور مثال بیت کوین - تتر) به صورت ثابت ۰.۲% در نظر گرفته می شود.</p>
    </div>
    
        <table class='levels-commission'>
            <tr>
                <td></td>
                <td>سطح کاربری</td>
                <td class="resp">میزان معامله در ۳ ماه اخیر</td>
                <td>کارمزد</td>
            </tr>
           
                <tr class="tr-body" v-for="(level,index) in $accountLevel.filter(e => e.userAccountLevel !== 'SUPERVISOR')" :key="index">
                <td>
                    <img class="img-levels" :src="require('../../assets/icons/'+levels[level.userAccountLevel].icon+'.svg')" alt="">
                </td>
                <td>
                    <!-- <img v-if="index === 0" class="img-levels" src='../../assets/Home/bee1.png' alt=""> -->
                    <span>{{levels[level.userAccountLevel].title}}</span>
                    <!-- <span v-if="index === 0">{{body.title}}</span> -->
                </td>
                <td class="number-font resp" v-if="index === 0">
                    کمتر از {{tradeAmount($accountLevel[index].tradeAmount)}} میلیون
                </td>
                <td class="number-font resp" v-else-if="index === ($accountLevel.length-2)">بیشتر از
                    {{tradeAmount($accountLevel[index-1].tradeAmount)}}
                    میلیون
                </td>
                <td v-else class="resp">
                    از
                    {{tradeAmount($accountLevel[index-1].tradeAmount)}}
                    تا
                    {{tradeAmount($accountLevel[index].tradeAmount)}}
                    میلیون
                </td>
                <td class="number-font">
                    % {{level.wagePercent}}
                </td>
               
            </tr>
        </table>

        <h3>کارمزد برداشت ارزها</h3>
        <p >کارمزد برداشت دارایی‌های دیجیتال به نوع ارز بستگی دارد. این هزینه در واقع به دلیل کارمزدهای تراکنش شبکه کسر می‌شود و بامبو در آن نقشی ندارد. در حال حاضر این هزینه‌ها به شرح زیر است:</p>
        
        
        <table class='cryptos-commission'>
            <tr>
                <td> نوع ارز</td>
                <td>حداقل میزان قابل برداشت</td>
                <td>کارمزد</td>
            </tr>
            <tr v-for='(crypto,index) in coins' :key="index">
                <td>{{crypto.persianName}}</td>
                <td>{{crypto.tokenTypeDetails[0].minWithdraw}}<span class="symbol">{{crypto.code}}</span></td>
                <td>{{crypto.tokenTypeDetails[0].withdrawalFee}}<span  class="symbol">{{crypto.code}}</span></td>
            </tr>
        </table>

        <h3>  کارمزد برداشت ریالی </h3>
        <p >برداشت‌های ریالی برای تمامی حساب‌ها، در قالب انتقال پایا و یک بار در روز خواهد بود. بدیهی است مدت زمان انجام تسویه ریالی، بسته به زمان درخواست برداشت، در روزهای غیر تعطیل می‌تواند حداکثر تا ۲۴ ساعت به طول انجامد.</p>
        

</div>
  
    
</template>

<script>
export default {
  name: 'Commissions',
  data(){
    return{
        commissions:[
            {title:'سطح کاربری 1',for:'کمتر از ۲۰ میلیون تومان ',amount:'0.4%',icon:'bronze'},
            {title:'سطح کاربری 2',for:'۵۰-۲۰ میلیون تومان  ',amount:'0.4%',icon:'silver'},
            {title:'سطح کاربری 3',for:'150-50 میلیون تومان ',amount:'0.4%',icon:'gold'},
            {title:'سطح VIP',for:'بیش از 200 میلیون  ',amount:'0.4%',icon:'diamond'},
        ],
         levels: {
                BRONZE: {
                    title:'سطح کاربری 1',
                    icon:'bronze'
                },
                SILVER: {
                    title:'سطح کاربری 2',
                    icon:'silver'
                },
                GOLD: {
                    title:'سطح کاربری 3',
                    icon:'gold'
                },
                PLATINUM: {
                    title:'سطح VIP',
                    icon:'diamond'
                }
            },
        coins:[]
      
    }
  },
  computed: {
        tradeAmount() {
            return e => e ? e.toString().slice(0, -6) : 0
        }
},
   methods: {
        async getData() {
            const res = await this.$axios.get('/coins/details?p2pSupported=true')
            this.coins = res.content.filter(coin=>coin.coin!='TOMAN')
        }
    },
    mounted() {
        this.$getFees()
        this.getData()
    }
}
</script>
<style scoped>
.commission-page{
    color: #4F4F4F;
}

table{
    margin-top: clamp(50px,7vw,110px);
    border-collapse: collapse;
    width: 100%;
font-size: clamp(14px,1.5vw,16px);

}

tr{
background: #FEFEFE;
height: 55px;

}

.symbol{
    padding-left: 5px;
}
h3{
    font-weight: bold;
    padding:25px 0px;
    font-size:clamp(18px,2vw,22px);
}
.levels-commission tr:first-child{
    font-size: clamp(12px,1.3vw,14px);
}

.cryptos-commission tr:first-child{
    color: #999999;
}

.cryptos-commission{
    font-size: clamp(12px,1.3vw,14px);
    margin-top: clamp(16px,2vw,25px);

}
table tr td:first-child {
    padding-right: 30px;
}


.levels-commission tr td:nth-child(2) {
    width: 43%;
}

.levels-commission tr td:nth-child(3) {
    width: 43%;
}

.cryptos-commission tr td:first-child {
    width: 43%;
}

table tr td:last-child {
       padding-left: 30px;
       text-align: left;

}



table tr:last-child td:first-child {
    border-bottom-right-radius: 8px;
}

table tr:last-child td:last-child {
    border-bottom-left-radius: 8px;
}
table tr:first-child td:first-child {
    border-top-right-radius: 8px;
}

table tr:first-child td:last-child {
    border-top-left-radius: 8px;
}

@media(max-width:670px){
    .resp{
        display: none;
    }
}
</style>