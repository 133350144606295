<template>
<Modal @close="$emit('modalToggle','')">
    <template #header>
        <div><span :style="{color:(data.type=='SELL')?'#CA2F4B':'#03C70B'}"> {{dictionary[data.type]}} </span> شما با موفقیت انجام شد </div>
    </template>
    <template #body>
        <div class="factor">
            <div>
                <div class="data-row">فاکتور</div>
                <div class="data-row"><span>مقدار رمزارز</span><span>&nbsp;{{data.amount}}&nbsp;{{$coins[data.marketType.split('_')[0]].code}}</span></div>
                <div class="data-row"><span> قیمت واحد</span><span>{{$toLocal(data.unitPrice,0)}}&nbsp;{{$coins[data.marketType.split('_')[1]].persianName}}</span></div>
                <div class="data-row"><span> دریافتی شما</span><span>{{$toLocal(received,0)}}&nbsp;{{data.type=='BUY'?$coins[data.marketType.split('_')[0]].code:$coins[data.marketType.split('_')[1]].persianName}}</span></div>
            </div>
            <img :src="(data.type=='SELL')?require('../../assets/public/success-sell-img.png'):require('../../assets/public/success-buy-img.png')"/>
        </div>
        <button class="modal-confirm-btn" @click="$emit('modalToggle','')">بستن</button>
    </template>
</Modal>
  
    
</template>

<script>
import Modal from './Modal'
export default {
  name: 'SuccessfulTrade',
  components:{
      Modal
  },
  props:['data'],
  computed:{
      received(){
          return this.data.type==='SELL'?this.data.unitPrice*this.data.amount :this.data.amount
      }
  },
  data(){
    return{
        dictionary:{
            "SELL":"فروش",
            "BUY":"خرید"
        },
        
      
    }
  }
}
</script>
<style scoped>


.factor > div{
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: space-between;
    flex-grow: 1;
}

.factor{
    display: flex;
    margin-top: 12px;
    column-gap: 30px;
}

.data-row{
    display: flex;
    justify-content: space-between;
    font-size: 13px;
}

.data-row > span:last-child {
    font-weight: bold;
    color:var(--gray4)
}
.number{
font-weight: bold;
}

p{
    font-size: 14px;
}
</style>