<template>
<!-- <div class="code-container">
      <div v-for="i in 6" :key="i">
            <input type="number" pattern="[0-9]" min="0" max="9" @change="(e)=>code[i]=e.target.value"
                 maxlength="1"  value="" inputtype="numeric"  required>
            <div class="underline"/>
      </div>
</div> -->
  <div id="app">
    <CodeInput dir="ltr"    
                    class="input"               
                    :fields="num"
                    
                    :fieldWidth="33"
                    :fieldHeight="28" :loading="false" @change="e=>$emit('change',e)" @complete="e=>$emit('complete',e)" />
  </div>
  
    
</template>

<script>
import CodeInput from "vue-verification-code-input";
export default {
  name: 'VerifyCodeInput',
  props:{
    num:Number
  },
  data(){
    return{
        code:[]
    }
  },
  components:{
    CodeInput

  },
  methods:{
     setPlaceholder(){
                for (let i = 0; i < this.num; i++) {
                    this.$refs['code-input'].$refs['input_' + i][0].placeholder = 0
                }
            },
    reset(){
            this.otp.code = ''
            for(let i=0;i<this.num;i++){
                this.$refs['code-input'].values[i] = ''
                this.$refs['code-input'].$refs['input_' + i][0].value = ''
            }
            this.$refs['code-input'].$refs['input_0'][0].focus()
            },
   
  },
  mounted() {
   
   this.setPlaceholder()
  },
  
}
</script>
<style scoped>
  ::v-deep input[type="tel"]::placeholder {
        color: var(--main-color);
    }

    ::v-deep .input {
        width: 100% !important;

        
    }
::v-deep .react-code-input {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            background: #EDF1ED;
            height: 44px;
            border-radius: 6px;
}
 ::v-deep input {
                border: none!important;
                border-bottom: 2px solid   #BDBDBD!important;
                color: var(--font-color3) !important;
                font-family: 'VistaDev', sans-serif !important;
                font-size: 25px;
              
                
                 background: #EDF1ED;
                border-radius: 0px!important;
              
                letter-spacing: -2px;
                text-align: center !important;
                
            }





/* 

input[type="number"] {
    
	text-align: center;
	appearance: textfield;
      font-size: clamp(18px,1.6vw,19px); 
	-webkit-appearance: textfield;
    flex-grow: 1;
    max-width: clamp(30px,3.1vw,33px);
    border-radius: 4px;
    background: #EDF1ED;
    

}
.code-container{
    display: flex;
    align-items: center;
    width: 100%;
    height: 44px;
    padding: 9px ;
    justify-content: center;
    column-gap: clamp(5px,1.3vw,15px);
   background: #EDF1ED;
border-radius: 6px;
}
.underline{
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background: #BDBDBD;
} */
</style>
