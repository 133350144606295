<template>
  <div>
      <div class="page-title">سفارشات فعال</div>
      <div class="filters">
      <div class="toggle-comp">
      </div>
      <FilterDrop @choose="(choice)=>tradeType=choice.key" :items="[{key:'',label:'خرید و فروش'},{key:'SELL',label:'فروش'},{key:'BUY',label:'خرید'}]"/>
      </div>
 
       <table>
            <tr class="header">
                <td class="resp-3">زمان</td>
                <td>نوع سفارش</td>
                <td>نوع ارز</td>
                <td>قیمت واحد</td>
                <td>مقدار</td>
                <td class="resp-2">قیمت کل</td>
                <td class="resp-1"> انجام شده</td>
                <td > عملیات </td>
            </tr> 
            <tr class="row" v-for="(order,index) in dataSet.filter(data=>data.orderType.includes(tradeType))" :key='index'>
                <td class="resp-3">{{$G2J(order.createdAtDateTime)}}</td>
                <td :class="{red:order.orderType.includes('SELL'),green:order.orderType.includes('BUY')}">{{order.orderType.includes('BUY')?'خرید':'فروش'}}-{{tradeTypeDict(order.orderType)}}</td>
                <td>{{$coins[order.marketType.split('_')[0]].persianName}}</td>
                <td>{{$toLocal(order.unitPrice,0)}}&nbsp;&nbsp;{{$coins[order.marketType.split('_')[1]].persianName}}</td>
                <td>{{order.amount}}</td>
                <td class="resp-2">{{$toLocal(order.wholePrice,0)}}&nbsp;&nbsp;{{$coins[order.marketType.split('_')[1]].persianName}}</td>
                <td class="resp-1">{{order.executedPercent}}</td>
                <td  @click="cancelOrderModal(order)" class="cancel-btn">لغو سفارش</td>
            </tr>
        </table>    
        <CancelModal v-if="modalType=='CANCEL'" @modalToggle="value=>modalType=value" @confirm="removeorder()"/>
  
 
  </div>

</template>

<script>
import CancelModal from '../../modals/CancelModal'
import {
    Loop
} from "@/library/reuseableFunction";
// import * as variables from '../../../library/variables.json'
// import Table from './Table.vue'
import FilterDrop from "./FilterDrop"
export default {
  name: 'AcitveOrders',
  components:{   
        //  Table,
         FilterDrop,
         CancelModal
  },
  
  data(){
    return{
      dataSet:[],
      tableType:"MarketOrders",
      tradeType:'',
      modalType:''
      
    }
  },
  methods:{
        async removeorder() {
            //   this.state.loading = true
            this.cancelModal = false
            const [res, ] = await this.$http.delete(`orders/${this.removeid}`)
            if (res) {
                ++this.state.orderChange
                this.modalType = ''
                this.$error('سفارش با موفقیت حذف شد', '', 'success')
                if (this.state.loop) {
                    this.loop.start()
                } else {
                    this.getLastOrders()
                }
            }
        },   
    cancelOrderModal(e) {
        // this.Loop.stop()
        this.modalType = 'CANCEL'
        this.removeid = e.id
    },
    tradeTypeDict(type){
        return  type.includes('EXCHANGE')?'صرافی':type.includes('MARKET')?'بازار':'حد'
    }, 
   async check() {
                // if (this.page<=this.lastPage){ //for scroll loading
                // this.page ++ //for scroll loading
                this.state.loading = true
                const res = await this.$axios.get('/orders', {
                    params: {
                        // size: 100,
                        // type: 'LIMITED_SELL',
                        orderStatus:"IS_OPEN"
                    }
                })
                // this.lastPage = res.totalPages
                // this.dataSet = [...this.dataSet, ...res.content] 
                this.dataSet=res.content
                } ,Loop,
            load() {
                if(this.page<=this.lastPage){
                    setTimeout(() => {
                    this.check()
                }, 1000)}
            },
  
  },
  mounted(){
    this.check()
  }
}
</script>
<style scoped>


.filters{
 display: flex;
 align-items: center;
 justify-content: space-between;
}


.footer-table{
    padding-bottom: 50px;
}
.cancel-btn{
    color: red;
    cursor: pointer;

}
table{
    width: 100%;
}
.row{
    font-size: clamp(12px,1.3vw,14px);
    color: #828282;

}
.red{
    color:#CA2F4B
}
.header{
    height: 40px; 
    font-weight: bold;
    font-size: clamp(12px,1.3vw,14px);
    color: #828282;

}
.green{
    color: #03C70B;
}

@media(max-width:1000px){
    .resp-1{
        display: none;
    }
}
@media(max-width:550px){
    .resp-2{
        display: none;
    }
}

@media(max-width:400px){
    .resp-3{
        display: none;
    }
}
</style>