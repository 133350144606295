<template>
<div :class="{'general-page':true,bg:$route.path=='/'}">
    <!-- <img   v-if="$route.path=='/'"  class="bg" src='../../assets/public/HomeHeaderBg.png'/> -->

    <div class=" general-component">
        <FAQ v-if="$route.name=='FAQ'" />
        <Terms v-if="$route.name=='Terms'" />
        <Commissions v-if="$route.name=='Commissions'" />
        <AboutUs v-if="$route.name=='AboutUs'" />
        <ContactUs v-if="$route.name=='ContactUs'" />
        <HomePage class="home-page " v-if="$route.path=='/'" />
        <Markets v-if="$route.name=='Markets'" />
        <FastOrder v-if="$route.name=='FastOrder'" />
    </div>

    <Footer />
</div>
</template>

<script>
import Footer from './components/Footer'
import Commissions from './Commissions'
import FAQ from './FAQ'
import Terms from './Terms'
import AboutUs from './AboutUs'
import ContactUs from './ContactUs'
import HomePage from './homepage/HomePage'
import Markets from './Markets'
import FastOrder from './FastOrder/FastOrder'

export default {
    name: 'General',
    components: {
        FAQ,
        Terms,
        Commissions,
        AboutUs,
        ContactUs,
        Footer,
        HomePage,
        Markets,
        FastOrder
    },
    data() {
        return {

        }
    }
}
</script>

<style scoped>
::v-deep .page-header {
    text-align: center;
    font-size: clamp(20px, 2.2vw, 24px);
    color: #143B74;
    font-weight: bold;
    padding: 112px 0px 32px 0px;

}

.general-page {
    display: flex;
    background: #E5E5E5;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;

}

.general-component {
    width: 85%;
    padding-bottom: 43px;
    margin: auto;

}

.home-page {
    width: 100%;
    /* margin-top: -900px; */
}

.bg {

    background: url('../../assets/public/HomeHeaderBg.png');
    background-repeat: no-repeat;

    max-width: 100%;
    background-size: contain;
    /* min-width: 700px; */
    right: 0px;
}

::v-deep .confirm-btn {
    background: #143B74;
    font-size: clamp(14px, 1.5vw, 16px);
    border-radius: 4px;
    color: white;
    width: 100%;
    height: 40px;
}
</style>
