var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('table',[_c('tr',{staticClass:"table-header"},_vm._l((_vm.columns),function(column,i){return _c('td',{key:i,class:{'resp-1':i==3||i==0,'resp-2':i==4,'resp-3':i==5 || i==6},domProps:{"innerHTML":_vm._s(column.label)}})}),0),_c('tbody',_vm._l((_vm.dataSet),function(data,index){return _c('tr',{key:index,staticClass:"table-row"},_vm._l((_vm.columns),function(column,i){return _c('td',{key:i,class:{'resp-1':i==3||i==0,'resp-2':i==4,'resp-3':i==5 || i==6},style:({color:_vm.colors[data[column.name]]}),domProps:{"innerHTML":_vm._s( 
        column.name=='amount' && data[column.name]+_vm.cryptos[data['cryptoType']]
        || _vm.orderType[data[column.name]]
        || column.name=='cryptoType' && _vm.$coinLabel[_vm.$marketType2relatedCoin(data['marketType'])] 
        || column.name=='action' && data[column.name]==true && '<div class='+'container'+ '> <div class='+'btn' +' > لغو سفارش    <div  class='+'line'+'></div></div></div>'
        || column.name=='action' && data[column.name]==false &&   '<div class='+"'disabled-action'"+'>لغو سفارش</div>'
        || column.dictionary  && column.dictionary[data[column.name]]
        || column.name=='createdAtDateTime' && _vm.$G2J(data[column.name])
        || _vm.dictionary[data[column.name]] 
        || (column.name=='fill')&& data[column.name]+'%'
        ||(column.name=='transId')&& '<div class='+'right-link'+ '> <div class='+'btn' +' >  مشاهده <div  class='+'line'+'></div></div></div>'
        || data[column.name].toLocaleString() 
        )}})}),0)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }