<template>
<div>
    <div class="headbar">
        <div class="headbar-title-currency">
            <div class="toggle-comp">
                <span class="page-title">ارزپایه</span>
                <ToggleComp :default="tradeTo" @choose="(choice)=>{$router.push('/Panel/P2P/'+choice+'/'+tradeFrom)}" :items="baseCoins" />
            </div>

            <div>
                <div class="headbar-title">بازار</div>
                <div class="market-title">{{$coinLabel[crypto.relatedCoin]}}-{{baseCurrencyDict[tradeTo]}}</div>
            </div>
        </div>

        <div class="crypto-values">

            <div>
                <div class="headbar-title">درصد تغییرات</div>
                <div align="right">
                    <div class="change" :class="{change:true,red:crypto.last24HourChange<0,green:crypto.last24HourChange>0}"><span v-if="crypto.last24HourChange>0">+</span><span v-if="crypto.last24HourChange<0">-</span>{{$toLocal(Math.abs(crypto.last24HourChange),2)}}%</div>
                </div>
            </div>
            <div>
                <div class="headbar-title"> بیشترین</div>
                <div class="headbar-value">{{$toLocal(crypto.buyPrice,0)}}</div>
            </div>
            <div>
                <div class="headbar-title">کمترین</div>
                <div class="headbar-value">{{$toLocal(crypto.sellPrice,0)}}</div>
            </div>
            <div>
                <div class="headbar-title">آخرین</div>
                <div class="headbar-value"> {{$toLocal(crypto.lastPrice,0)}}</div>
            </div>
            <div>
                <div class="headbar-title">حجم</div>
                <div class="headbar-value">{{crypto.last24HourVolume}}</div>
            </div>
        </div>
    </div>
    <div class="body">
        <SideMarket :cryptos="cryptos" />
        <div class="container">
            <TradeView :cryptoSymbol="$coins[tradeFrom].code" />
            <BuySellSection :crypto="crypto" />
            <History />
        </div>
    </div>

</div>
</template>

<script>
import TradeView from './TradeView'
import BuySellSection from './BuySellSection'
import SideMarket from './SideMarket'
import History from './History'
import {
    tradeTo,
    tradeFrom,
    Loop
} from "@/library/reuseableFunction";
export default {
    name: 'P2P',
    components: {
        SideMarket,
        TradeView,
        BuySellSection,
        History
    },
    computed: {
        tradeTo,
        tradeFrom
    },
    watch: {
        'tradeTo': function () {
            this.getCoins()
        }
    },
    mounted() {
        this.loading = true
        if (this.state.loop) {
            this.loop = new Loop(this.getCoins, 5000, 'realPrice' + (tradeFrom))
            this.loop.start()
        } else {
            this.getCoins()
        }

    },
    beforeDestroy() {
        this.loop.stop()

    },
    methods: {

        async getCoins() {
            let res = await this.$axios.get('/trades/market-info', {
                params: {
                    weeklyDataIncluded: false,
                }
            }).then(response => res = response.content)
            if (res) {
                this.cryptos = res.filter(crypto => crypto.marketType.includes(this.tradeTo))
                this.getCoinsDetails()
            }
        },
        async getCoinsDetails() {
            const res = await this.$axios.get('/coins')
            this.coinsDetails = res.content
            if (this.cryptos) {
                console.log('dfssdf')
                this.crypto = this.cryptos.find(crypto => crypto.marketType.includes(this.tradeFrom))
                if (this.crypto && this.coinsDetails)
                    this.setCrypto()
            }
        },
        setCrypto() {
            this.crypto.minAmount = this.coinsDetails.find(coin => coin.coin == this.tradeFrom).minAmount
            this.crypto.maxAmount = this.coinsDetails.find(coin => coin.coin == this.tradeFrom).maxAmount
        },

    },
    data() {
        return {
            ordersType: "ActiveOrders",
            dataSet: [],
            crypto: {},
            coinsDetails: [],
            cryptos: [],
            baseCurrencyDict: {
                "TOMAN": "تومان",
                "TETHER": "تتر"
            },
            baseCoins: [{
                label: 'تومان',
                value: 'TOMAN'
            }, {
                label: 'تتر',
                value: 'TETHER'
            }]

        }
    }
}
</script>

<style scoped>
.headbar {
    display: flex;
    width: 100%;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
}

.crypto-values>div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70px;
}

.headbar-title-currency>div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70px;
}

.toggle-comp {
    max-width: 312px;
    flex-grow: 1;
    /* min-width: 150px; */
    min-width: 150px;
}

.footer-table {
    padding-bottom: 50px;
}

.headbar-title-currency {
    display: flex;
    flex-direction: row;
    row-gap: 10px;
    flex-wrap: wrap;
    column-gap: 10px;
    flex-grow: 1;

}

.crypto-values {
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-basis: 400px;
    flex-wrap: wrap;
    row-gap: 30px;
    justify-content: space-between;
    flex-grow: 2;
    padding-bottom: 20px;
}

.red {
    color: var(--red);
}

.green {
    color: var(--green);
}

.change {
    direction: ltr;
}

.market-title {
    color: var(--gray4);

    font-size: clamp(18px, 2.2vw, 24px);

}

.headbar-title {
    color: var(--gray2);
    font-size: clamp(13px, 1.4vw, 14px);

}

.headbar-value {
    color: var(--gray4);
    font-size: clamp(14px, 1.5vw, 18px);
}

.trade-view {}

.body {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    column-gap: 10px;
    row-gap: 10px;
}

.container {
    flex-grow: 1;

}
</style>
