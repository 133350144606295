<template>
  <div>
      <div class="page-title">تاریخچه ورود به سایت</div>
       <Table  :filters="[]"  :dataSet="dataSet" :columns="[date,ip,device,platform,browserType,condition] "/>
  </div>
    
</template>

<script>
export default {
  name: 'OpenOrders',
  data(){
    return{
      
    }
  }
}
</script>
<style scoped>

</style>