<template>
<div class="card-container">

<div class="auth-card"> 
  <div class="container">
    <div class="card-header">
          <slot name='card-header'> 
          </slot>
    </div>
   
    <div class="card-body">
        <slot name='card-body'>
        </slot>
    </div>
    <div class="card-footer">
      <slot name="card-footer">
      </slot>
    </div>
  </div>


</div>
</div>

</template>
<script>
export default {
  name:'AuthCard'
}

</script>
<style scoped>
.auth-card{
   background: #FAFCFF;
   width: 100%;
  
}

.card-body{
  display: flex;
  flex-direction: column;
}

.card-header{
  display: flex;
  justify-content: center;
    color: #143B74;
    font-size: clamp(20px,2vw,24px);
    font-weight: bold;
    width: 100%;
}

.card-footer{
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  row-gap: 16px;
}
button{
   padding: 8px 0px 8px 0px;
   width: 100%;
}

.container{
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 100%;
    
}
.card-container{
  padding: 20px;
  padding-top: 104px;
  width: 100%;
  max-width: 400px;
  /* background:   blue; */
}
</style>