<template>
    <div class="pass-change ">
          
        <div class="section-title"> تغییر رمز عبور </div>
        <form  class="pass-inputs section" @submit.prevent="submit"> 
 
            <LabelInput @input="value=>{oldPassword=value;}" label="رمز عبور فعلی"  type='password' />
            <LabelInput label="رمز عبور جدید" @input="value=>{newPassword1=value;}"   type='password'/>
            <LabelInput label="تکرار رمز عبور جدید" @input="value=>{newPassword2=value;}"    type="password"/>
               
            <button type="submit" :class="{disabled:!oldPassword|| !newPassword1||!newPassword2}" 
                class="authentication-confirm-btn"  :disabled="!oldPassword||
                !newPassword1||!newPassword2">تغییر رمز عبور</button>
            
        </form>
    </div>

</template>

<script  >
export default {
  name: 'Settings',
  methods:{ 
      async submit() {

            let passSent = {
                oldPassword: this.oldPassword,
                password: this.newPassword1
            }
            await this.check2Pass()
            if (this.newPassword1 && this.newPassword2) {
                if (this.checkPass())
                    this.state.loading = true
                try {
                    console.log(passSent)
                    const res = await this.$axios.post(`/users/change-password`, passSent)
                    if (res.message === "password changed successfully") {
                        this.$error('رمز شما تغییر یافت', '', 'success')
                        this.oldPassword = ''
                        this.newPassword1 = ''
                        this.newPassword2 = ''
                    }
                } catch (error) {
                    if (error.response.data.message === 'Old Password Is Incorrect') {
                        this.passError = 'رمز قبلی اشتباه است.'
                    }
                }
            }
        },
        checkPass() {
            if (!this.oldPassword) {
                this.passError = 'رمز فعلی اجباری';
            } else {
                this.passError = ''
            }
            if (this.newPassword1) {
                if (this.newPassword1.length < 8) {
                    this.pass1Error = 'حداقل 8 کاراکتر';
                } else if (!this.validPass(this.newPassword1)) {
                    this.pass1Error = 'ترکیبی از حروف کوچک و بزرگ و عدد';
                } else if (!this.newPassword2) {
                    this.pass1Error = ''
                    this.pass2Error = 'تکرار رمز اجباری';
                } else if (this.newPassword2 !== this.newPassword1) {
                    this.pass1Error = ''
                    this.pass2Error = 'تکرار رمز مطابقت ندارد';
                } else {
                    this.pass2Error = ''
                }
            }
            if (!this.passError.length && !this.pass1Error.length && !this.pass2Error.length) {
                return true;
            }
        },
        async check2Pass() {
            if (!this.$validPass(this.oldPassword) || this.oldPassword.length < 8) {
                this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                console.log(this.oldPassword)
                return Promise.reject('wrong pass : ' + this.oldPassword)
            }
            if (this.newPassword1 !== this.newPassword2){
                this.$error('اطلاعات وارد شده اشتباه است', 'تکرار رمز اشتباه است')
                return Promise.reject('repeat failed : ' + this.newPassword1 + '!==' + this.newPassword2)
            }
        },
        validPass(password) {
            const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
            return re.test(password);
        }
   
  },
 
    
  data(){
    return{
       
         newPassword1:'',
         oldPassword:'',
         newPassword2:'',
         passError: '',
        pass1Error: '',
        pass2Error: '',
     
      
    }
  },
 
}
</script>
<style lang="scss" scoped>
.links{
   
    padding: 15px 24px 24px 0px;
}

.pass-change{
    width: 100%;
    max-width: 294px;
  
}

.disabled{
    opacity: 0.7;
}

.authentication-confirm-btn{
    margin-top: 80px;
}
.checkbox{
    width:16px;
    height:16px;
    background: #F2F2F2;
    margin-left: 4px;
    position: relative;
    align-items:center;
    justify-content: center;
    border: 1px solid rgba(199, 199, 199, 0.22);
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;
}
.tik{
   position: absolute;
   margin-top: 6px;
}
.option{
    display: flex;
    column-gap: 6px;
    align-items: center;
}

.pass-inputs{
    display: flex;
    flex-direction: column;
    row-gap:20px;  
}
  .options{ 

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        row-gap: 20px;
    }
.settings-page{
    display: flex;
    flex-wrap: wrap;
   
    padding-bottom: 50px;
    column-gap: 50px;
.statics{
    max-width: 830px;
    padding-right: 24px;
    font-size: clamp(12px,1.3vw,16px);
  
}    
.section-title{
    padding-top:15px;
    padding-bottom: 24px;
    font-size: clamp(14px,1.4vw,16px);
     color: var(--gray2);
}

}

@media(max-width:412px){
    .options{
        justify-content: space-between ;
       
    }
}
</style>