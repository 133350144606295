<template>
<div class="drop-container">

  <div class="dropdown">
      <div class="depsoit-label">موجودی قابل برداشت</div>
      <div class="depsoit-value">{{$toLocal(toman,0)}}&nbsp; تومان</div>
      <div class="actions">
        <UnderlinedBtn color='#CA2F4B' @click="state.userInfo.authorized?$emit('modalToggle','TomanWithdraw'):''">برداشت</UnderlinedBtn>
        <UnderlinedBtn color='#03C70B' @click="state.userInfo.authorized?$emit('modalToggle','TomanCharge'):''">واریز</UnderlinedBtn>

      </div>
      <div class="line"/>
      <a href="/Panel/Wallet" class="link" @click.prevent="$router.push('/Panel/Wallet')">مشاهده کیف پول ها</a>
      <a href="/Panel/History/Transactions" class="link" @click.prevent="$router.push('/Panel/History/Transactions')">تاریخچه تراکنش ها</a>
      <a   href="/Panel/History/ActiveOrders" @click.prevent="$router.push('/Panel/History/ActiveOrders')" class="resp-order link">سفارشات فعال</a>
      <a  href="/Panel/History/Orders" @click.prevent="$router.push('/Panel/History/Orders')" class="resp-order link">تاریخچه سفارشات</a>

  </div>
      
</div>  
</template>

<script>

export default {
  name: 'DepsoitDropDown',
  props:['toman'],
  data(){
    return{
      
    }
  },
  components:{
  }
}
</script>
<style scoped>
.dropdown{
   background: #ECF4FF;
   border-radius: 0px 0px 8px 8px ;
   width: 198px;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 16px;
   row-gap: 10px;
   /* z-index: 10; */
    position: absolute;

}

.dropdown > a{
    text-align: right;
    width: 100%;
}
.depsoit-value{
    color: #333333;
      font-weight: bold;
    font-size: clamp(13px,1.4vw,16px);
       width: 100%;

  

}

.depsoit-label{
     font-size:clamp(10px,1.1vw,12px);
   color: #4F4F4F;
   width: 100%;


}

.actions{
    display: flex;
    justify-content: space-around;
    font-weight: bold;
   width: 100%;

    font-size: clamp(13px,1.4vw,16px);
}

.line{
   border: 0.4px solid #BDBDBD;
   width: 100%;

}

.link{
    color: #4F4F4F;
    font-size: clamp(14px,1.5vw,16px);
    cursor: pointer;

}

.link:hover{
     background: -webkit-linear-gradient(95.12deg, #143B74 -9.51%, #2B6BC9 104.28%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

}
.drop-container{
    position: absolute;

}
.resp-order{
  display: none;
}
@media(max-width:600px){
     .resp-order{
       display: block;
    }
   
}

</style>