<template>
<div class="drop-container" dir="ltr">

    <div class="dropdown" dir="rtl" >
        <div class="email"><span>{{email.replace('.com','')}}</span><span class="level">{{userLevels[state.userInfo.userAccountLevel]}}</span></div>
        <li class="link" v-for="(link,index) in links" :key="index">
            <a class="link" :href="'/Panel/'+link.name" @click.prevent="$router.push('/Panel/'+link.name)">{{link.title}}</a></li>
        <li><a @click.prevent="$logout()" class="exit">خروج از حساب کاربری</a></li>
    </div>
</div>
</template>

<script>
export default {
    name: 'ProfileDropDown',
    data() {
        return {
            email: this.state.userInfo.email || this.$cookies.get('username'),
            level: 2,
            links: [{
                    title: "داشبورد",
                    name: "Dashboard"
                },
                {
                    title: "احراز هویت",
                    name: "Authentication"
                },
                {
                    title: "تنظیمات",
                    name: "Settings"
                },
                {
                    title: "دعوت از دوستان",
                    name: "InviteFriends"
                },
            ],
            userLevels: {
                'BRONZE': 1,
                'SILVER': 2,
                'GOLD': 3,
                'PLATINUM': 4,
                'SUPERVISOR': 5,
            },
        }

    },

}
</script>

<style scoped>
.dropdown {
    /* position: absolute; */
    background: #ECF4FF;
    border-radius: 0px 0px 8px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 26px 30px 10px;
    row-gap: 18px;

}

.dropdown>div,
li {
    text-align: right;
    width: 100%;
}

.link {
    color: #4F4F4F;
    font-size: clamp(13px, 1.4vw, 16px);
    cursor: pointer;
}

.email {
    color: #333333;
    font-size: clamp(13px, 1.4vw, 16px);
    display: flex;
    align-items: center;
}

.exit {
    color: #CA2F4B;
    font-weight: bold;
    font-size: clamp(13px, 1.4vw, 16px);

}

.level::after {
    content: url('../../../assets/public/leefborder.svg');
    transform: rotatey(180deg);

}

.level::before {
    content: url('../../../assets/public/leefborder.svg');

}

.level {
    color: #F2994A;
    font-weight: bold;
    font-size: clamp(18px, 1.7vw, 20px);
    display: flex;
    align-items: flex-start;
    padding-right: 5px;
    /* margin:auto */
}

.link:hover {
    background: -webkit-linear-gradient(95.12deg, #143B74 -9.51%, #2B6BC9 104.28%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

}

.drop-container {
    position: absolute;
    left: 0px;
}
</style>
