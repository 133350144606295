<template>
<div  class="trade-section">
    <div class="page-title">معامله</div>
    <div  class="trade">
         
        <div  class="exchange-sec">
                <div class="crypto-container">
                  <div class="crypto-icon" >
                      <img class="icon" :src="require('../../../assets/coins/'+crypto.destCoinSymbol+'.png')">
                      <div>({{$coins[crypto.destCoinSymbol].code}})</div>
                  </div>
                  <div class="crypto-prices" >
                    <div class="crypto-price"><span>قیمت فروش</span><span class="red-color">{{$toLocal(crypto.sellPrice,0)}}&nbsp; تومان</span></div>
                    <div class="crypto-price"><span>قیمت خرید</span><span class="green-color">{{$toLocal(crypto.buyPrice,0)}}&nbsp; تومان</span></div>
                    <div class="order-min"><span>حداقل مبلغ سفارش</span><span >{{$toLocal(crypto.minAmountExchange,0)}}&nbsp; تومان</span></div>
                    <div class="order-min"><span>حداکثر مبلغ سفارش</span><span >{{$toLocal(crypto.maxAmountExchange,0)}}&nbsp; تومان</span></div>
                  </div>
            </div>
              <ToggleComp  @choose="(choice)=>{dealType=choice}" :items="[{label:'خرید',value:'BUY'},{label:'فروش',value:'SELL'}]"/>
              <LabelInput  type='number' @input="value => amount=value" :label="'مقدار('+$coins[crypto.destCoinSymbol].code+')'"/>
              <div class="depsoit" v-if="state.userInfo.authorized">  موجودی: 
                  <span class="depsoit-value">{{$toLocal(credit,0)}}</span>
                  &nbsp; 
                  {{(dealType=='BUY')?'تومان':$coins[crypto.destCoinSymbol].code}}
              </div>
             
              <div class="commission">کارمزد انتقال  &nbsp; {{crypto.exchangeFee}}</div>
             <SwitchComponent v-if="dealType=='BUY'" @choose="(choice)=>{wallet=choice}" :items="[{label:'انتقال به کیف پول باداکس',value:'BODEX'},{label:'انتقال به کیف پول دیگر',value:'OTHER'}]"/>   
             <div class="crypto-network" v-if="wallet=='OTHER' && dealType=='BUY'">
                  <div class="crypto-network-label">شبکه انتقال ارز</div>
                  <NetworkDropdown @select="network=>net=network.tokenType" :items="crypto.nets" :key='crypto.destCoinSymbol'/>
              </div>   
              <LabelInput v-if="wallet=='OTHER' && dealType=='BUY'" @input="value=>walletAddress=value"   :label="' آدرس کیف پول'"/>
              <p class="error">{{errorHandler}}</p>
              <div class="sum">
                  <div>
                      <span>مبلغ کل سفارش(تومان)</span> &nbsp;&nbsp;
                      <span>{{$toLocal(sum,0)}}</span>
                  </div>
              </div>
              <button v-if="state.userInfo.authorized" class=" btn" :class="{disabled:disabled,green:dealType=='BUY',red:dealType=='SELL'}"
               :disabled='disabled' @click="checkModal()">
                         ثبت سفارش {{dealType=='BUY'?'خرید':'فروش'}}
              </button>
              <button class="confirm-btn" @click="$router.push('SignUp')" v-else>ثبت نام کنید</button>
             
        </div>
      
    </div>
    <Buy @confirm="sendData()" v-if="modalType=='BUY'" @modalToggle="value=>modalType=value"  :data="data"/>
    <Sell @confirm="sendData()" v-if="modalType=='SELL'" @modalToggle="value=>modalType=value"  :data="data"/>
    <SuccessfulTrade  v-if="modalType=='success'" @modalToggle="value=>modalType=value"  :data="Object.assign({type:dealType},temptData)"/>

</div>
  
    
</template>

<script>
import NetworkDropdown from '../../tools/NetworkDropdown'
import SuccessfulTrade from '../../modals/SuccessfulTrade'
import Buy from '../../modals/Buy'
import Sell from '../../modals/Sell'
export default {
  name: 'TradeSection',
  components:{
     NetworkDropdown,
     SuccessfulTrade,
     Sell,
     Buy
  },
  props:['cryptos','coin','tomanBalance'],
  data(){
    return{
        wallet:"BODEX",
        cryptoNetwork:"TRC20",
        depsoit:'',
        dealType:"BUY",
        walletAddress:'',
        crypto:'',
        wallets:'',
        coinsDetails:'',
        net:'',
        amount:0,
        modalType:'',
        data:{},
        toman:true
    }
  },
  watch:{
      coin(){
          this.crypto=this.coin
      }
  },
  computed:{
      
        disabled() {
            return !this.$S2N(this.amount) ||
                (!this.walletAddress && this.wallet=='OTHER' && this.dealType=='BUY') ||
                (this.$S2N(this.dealType=='BUY'?this.sum:this.amount) > this.$S2N(this.credit)) ||
                (this.$S2N(this.amount) > this.$S2N(this.crypto.maxAmountExchange)) ||
                (this.$S2N(this.amount) < this.$S2N(this.crypto.minAmountExchange)) 
        },
        errorHandler() {
            if((this.$S2N(this.dealType=='BUY'?this.sum:this.amount)  > this.$S2N(this.credit)) && this.state.userInfo.authorized) 
                return 'موجودی کافی نمیباشد'
            else if((this.$S2N(this.amount) < this.$S2N(this.crypto.minAmountExchange))) 
                return 'مقدار معامله باید حداقل'+this.$coins[this.relatedCoin].code +' '+this.crypto.minAmountExchange+'باشد'
            else if((this.$S2N(this.amount) > this.$S2N(this.crypto.maxAmountExchange))) 
                return 'مقدار معامله باید حداکثر '+this.$coins[this.relatedCoin].code+' ' +this.crypto.maxAmountExchange+' باشد '
            else if(!this.amount)
                return 'مقدار درخواستی را وارد نمایید'    
            else if(!this.walletAddress && this.wallet=='OTHER' && this.dealType=='BUY')
                return 'آدرس کیف پول را وارد نمایید'  
            else 
                return ''
            
        },
        credit(){
            return (this.dealType=='BUY')?this.tomanBalance.toLocaleString():this.crypto.credit
        },
        sum(){
            return this.unitPrice*this.$S2N(this.amount)
        }, 
        unitPrice(){
            return this.$S2N((this.dealType=='BUY')?this.crypto.buyPrice:this.crypto.sellPrice)
        },
        sumExchangeFee(){
            return this.$S2N(this.crypto.exchangeFee)*this.$S2N(this.amount)

        }
    
    
  },
  methods:{
        // async getCoinsPrice() {
        //          await this.$axios.get('coins/exchange-price/usd',{
        //               params:{
        //                 includeWeeklyChange:false
        //               }
        //             }).then(response=>this.cryptos=response).then(this.getCoinsDetails())              
              

        //     },
   
        checkModal(){
            this.data.amount=this.amount
            this.data.marketType = this.crypto.destCoinSymbol + '_' + (this.toman ? 'TOMAN' : 'TETHER')
            this.data.destinationCoin = this.crypto.destCoinSymbol
            this.data.totalPrice=this.sum
            this.data.unitPrice=this.unitPrice
            this.data.orderType=(this.dealType=='BUY')?'EXCHANGE_BUY':'EXCHANGE_SELL'
            if(this.wallet=='OTHER' && this.dealType=='BUY')
                this.data.destinationWallet=this.walletAddress
            this.modalType = this.dealType
        },
        async sendData(){
             this.temptData = JSON.parse(JSON.stringify(this.data))
            this.temptData.amount = this.$S2N(this.temptData.amount)
            this.temptData.totalPrice = this.$S2N(this.temptData.totalPrice)
             this.temptData.unitPrice = this.$S2N(this.temptData.unitPrice)
            this.modalType = ''
            if (this.dealType=='BUY') {

                    this.state.loading = true
                    await this.$axios.post(
                        '/exchange/buy', this.temptData
                    )
                    this.modalType='success'


            } else {
                delete this.temptData.destinationWallet
                delete this.temptData.tokenType

                    this.state.loading = true
                    await this.$axios.post(
                        '/exchange/sell', this.temptData
                    )
                    this.modalType='success'

               
                this.state.loading = false
            }

        },
        async update(){
            let tempCoin
               await this.$axios.get('coins/exchange-price/usd',{
                      params:{
                        includeWeeklyChange:false
                      }
                     }).then(response=>tempCoin=response.find(coin=>coin.destCoinSymbol==crypto.destCoinSymbol)) 
              
                     if(tempCoin){
                         this.crypto.buyPrice=tempCoin.buyPrice
                         this.crypto.sellPrice=tempCoin.sellPrice

                     }
        
        }

        // async setNetwork(){
        //      const res = await this.$axios('/wallets/customer/wallet-address?walletType=EXCHANGE' +
        //       '&tokenType='+this.net.tokenType+'&relatedCoin='+this.crypto.destCoinSymbol ).catch(() => {
        //     })
        //     if(res){
        //        this.walletAddress= res.baseDTO.address
        //     }
        // }

           

            

  },
   mounted(){
    
    //   setInterval(this.update,1000)
      
  }
    
}
</script>
<style scoped>
.confirm-btn{
    margin-top: 10px;
}
.error{
    color: red;
    font-size: clamp(12px,1.3vw,13px);
}
.disabled{
    opacity: 0.6;
}
.trade-section{
    flex-grow: 2;
    flex-basis: 360px;
    background: rgb(222, 222, 235);
    padding: 10px;
    border-radius: 5px;
    flex-wrap: wrap;
}

.icon{
    height:45px;
    width:45px
}
.depsoit{
    margin-top: 8px;
    font-size:12px;
    color: var(--gray3);
}

.depsoit-value{
    color: var(--primary);
}
.sum-commission{
    font-size: clamp(10px,1.1vw,12px);
    font-weight: normal;
}
.sum-commission-value{
    color: var(--gray4);
    margin-right: 8px;
} 
.commission{
    color:var(--gray3);
    margin-top:4px;
    margin-bottom: 16px;
    font-size: clamp(10px,1.1vw,12px);

}
.trade > div{
    flex: 1;
    flex-basis: 300px;
}

.trade{
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
}
.sum{
    color: var(--gray2);
    font-size: 14px;
    font-weight: bold;
}
.btn{
    width: 100%;
    height: 40px;
    display: flex;
    margin-top:10px;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 4px;

}

.green{
    background: var(--green);
}

.red{
    background: var(--red);
}

.red-color{
    color: var(--red);
}

.green-color{
   color:  var(--green);
}
.sum{
    margin-top: 25px;
    padding: 12px 16px;
    background: #EDF1ED;
    border-radius: 6px;
    width: 100%;
}

.crypto-network{
    display: flex;
    margin: 13px 0px 13px 0px;
     
    align-items: center;
}


.crypto-network-label{
    flex-grow: 1;
    font-size: clamp(13px,1.3vw,14px);
    color: var(--gray2);


}

.crypto-container{
    display: flex;
    margin-top: 16px;
    flex-wrap:wrap;
    white-space: nowrap;
    /* align-items: center; */
    padding: 6px 16px;
    /* background: blue; */
    background: rgba(222, 238, 252, 0.3);
    border-radius: 8px;
    row-gap: 14px;
}

.crypto-icon{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 8px;
    color: var(--gray2);
    font-size: clamp(12px,1.3vw,14px);
    padding-left: 15px;
}

.crypto-price{
    display: flex;
    justify-content: space-between;
    font-size: clamp(14px,1.5vw,16px);
    column-gap: 6px;
}
.crypto-prices{
    /* width: 100%; */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.order-min{
    display: flex;
    justify-content: space-between;
    color: var(--gray3);
    font-size: clamp(12px,1.3vw,14px);
}
</style>