<template>
<div class="panel-page">
 <div class="panel">
   <Sidebar/>
   <div class="panel-body">
     <PanelNavbar/>
     <div class="panel-comp">

     
       <Authentication v-if="$route.name=='Authentication'"/>
       <InviteFriends v-if="$route.name=='InviteFriends'"/>
       <Settings v-if="$route.name=='Settings'"/>
       <Dashboard v-if="$route.name=='Dashboard'"/>
       <Notifications v-if="$route.name=='Notifications'"/>
       <WalletChart v-if="$route.name=='Wallet'"/>
       <Exchange v-if="$route.name=='Exchange'"/>
       <History v-if="$route.path.includes('History')"/>
       <P2P :key="$route.params.crypto" v-if="$route.name=='P2P'"/>
      <Finotech v-if="$route.name=='fino-verifying'"/>



     </div>

   </div>
    


 </div>
        <template v-if="state.modalOpen">
          <TomanCharge v-if="state.modalType=='TomanCharge'"/>
          <Depsoit v-if="state.modalType=='Depsoit'"/>
          <Sell v-if="state.modalType=='Sell'"/>
          <TomanWithdraw v-if="state.modalType=='TomanWithdraw'"/>
          <Withdraw v-if="state.modalType=='Withdraw'"/>
          <SuccessfulTrade v-if="state.modalType=='SuccessfulTrade'"/>
          <Buy v-if="state.modalType=='Buy'"/>
        </template>
</div>
    
</template>

<script>
import Finotech from '../auth/Finotech'
import PanelNavbar from '../navbars/PanelNavbar'
import Authentication from './Authentication/Authentication'
import InviteFriends from './InviteFriends'
import Settings from './Settings/Settings'
import Dashboard from './Dashboard'
import Notifications from './Notifications'
// import  WalletsTable from './Wallet/WalletsTable'
import History from './History/History'
import Exchange from './Exchange/Exchange'
import  WalletChart from './Wallet/Wallet'
import Sidebar from './Sidebar'
import P2P from './P2P/P2P'
import TomanCharge from '../modals/TomanCharge'

import Depsoit from '../modals/Depsoit'
import Withdraw from '../modals/Withdraw'
import Sell from '../modals/Sell'
import Buy from '../modals/Buy'
import SuccessfulTrade from '../modals/SuccessfulTrade'
import TomanWithdraw from '../modals/TomanWithdraw'
export default {
  name: 'Panel',
  components:{
      PanelNavbar,
      Sidebar,
      InviteFriends,
      Settings,
      Dashboard,
      Notifications,
      WalletChart,
      Authentication,
      Exchange,
      History,
      P2P,
      Finotech,
      //modals    
      TomanCharge,
      Depsoit,
      Sell,
      Withdraw,
      Buy,
      TomanWithdraw,
      SuccessfulTrade
  }
 
}
</script>
<style scoped>
.panel{
  display: flex;
  padding: clamp(4px,0.6vw,8px);
  flex: 1;
  

  width: 100%;

}

.panel-page{
  position: relative;
   display: flex;
   top: 0px;
   right: 0px;
   width: 100%;
  position: fixed;
 
   


}
.panel-body{
   padding-right:  clamp(5px,0.6vw,8px);
   flex-grow: 1;
   
}

.panel-comp{
    overflow: auto;
    overflow-y: scroll;
    height: 100vh;
    padding-bottom: 80px;
    overflow-x: hidden;
    padding-top: 18px;
}

 
::v-deep .page-title{
    color: #4F4F4F;
    font-weight: bold;
    white-space: nowrap;
    font-size: 16px;

}

::v-deep .section{
  padding-right: clamp(6px,2vw,24px);
}
</style>