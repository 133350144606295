<template>
<Modal @close="$emit('close')">
    <template #header>
        <div>شناسایی دوعاملی <span class="modal-title">گوگل</span></div>
    </template>
    <template #body>
        <div class="description">
            <div class="text">
                <p>برای فعالسازی شناسایی دوعاملی،ابتدا برنامه‌ی Google Authenticator را دانلود نموده و سپس QR کد زیر را توسط آن اسکن نمایید.</p>
                <p>ﭘﺲ از اﺳﮑﻦ ﺑﺎرﮐﺪ، ﻣﻮرد ﻣﺮﺑﻮط ﺑﻪ ﺑادکس در ﺑﺮﻧﺎﻣﻪ ﺷﻤﺎ اﯾﺠﺎد ﺧﻮاﻫﺪ ﺷﺪ. ﺑﺮای ﺗﺎﯿﯾﺪ ﺻﺤﺖ ﻓﺮآﯾﻨﺪ،ﮐﺪی ﮐﻪ در اپ ﺧﻮد ﻣﺸﺎﻫﺪه ﻣی کﻨﯿﺪ را در ﮐﺎدر زﯾﺮ وارد ﻧﻤﺎﯿﯾﺪ.</p>
            </div>
            <div>
             <img
                width="140px"
                    :src="'data:image/png;base64,'+qrCode"
                alt="">
            </div>

        </div>
        <VerifyCodeInput @change="e=>otp.code=e" @complete="submit()" :num="6"/>
        <button class="modal-confirm-btn">بستن</button>
    </template>
</Modal>
  
    
</template>

<script>
import Modal from './Modal'
export default {
  name: 'GoogleVerify',
  components:{
      Modal
  },
  props:['qrCode'],
  data(){
    return{
         otp:{
          code:''
      },
      
    }
  },
  methods:{
            async submit() {
                // const a = {
                //     'EMAIL': 'ایمیل',
                //     'SMS': 'پیامک',
                //     'GOOGLE_AUTHENTICATOR': 'Google Authenticator'
                // }
                if (this.otp.code.toString().length === 6) {
                        this.state.loading = true
                        const [res,] = await this.$http.post(`/users/change-otp?twoStepAuthorization=GOOGLE_AUTHENTICATOR&code=${this.otp.code}`)
                        if(res){
                            // this.reset()
                            this.$emit('close')
                            this.$emit('submit', 'شناسایی دو مرحله ای شما به ' + 'Google Authenticator' + ' تغییر یافت.')
                        }
                   
                }
                
            },
  }
}
</script>
<style scoped>
.modal-title{
    color: #143B74;
    margin-right: 12px;
}

.number{
font-weight: bold;
}


.description{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    column-gap: 16px;
    font-size: 14px;

}

.text{
    flex-basis: 200px;
    flex-grow: 1;
}
::v-deep .modal-card{
    max-width: 602px;
}
</style>