<template>
<div>
    <!-- <img   class="bg" src='../../../assets/public/HomeHeaderBg.png'/> -->

    <div class="header">
        <h1>بدکس</h1>
        <h4>خرید و فروش ارزهای دیجیتال</h4>
    </div>
    <div class="trade-card">

        <diV class="exchange">
            <div class="input-label">
                <span v-if="isBuy">دریافت میکنید </span>
                <span v-else>پرداخت میکنید</span>

                <HeaderCryptoFilter @input="value=>handleAmountChange(value)" :propValue='amount' v-if="!loading" :cryptos="cryptos" @selectCoin="coin=>{crypto=coin;setCrypto()}" />
            </div>
            <div>
                <ExchangeBox @toggle="isBuy=!isBuy" />

            </div>
            <div class="input-label">
                <span v-if="isBuy">پرداخت میکنید</span>
                <span v-else>دریافت میکنید</span>
                <div class="label-input">
                    <input v-model="tomanAmount" @input="e=>handlePriceChange(e.target.value)" />
                    <!-- @input="tomanAmount=$toLocal(tomanAmount,0)" -->
                    <label>تومان</label>
                </div>
            </div>
        </diV>

        <div class="depsoit" >
            <div><span class="depsoit-label" v-if="state.userInfo.authorized">موجودی:</span><span class="depsoit-value">{{$toLocal(credit,0)}}</span><span class="depsoit-measure">تومان</span></div>
            <div><span class="depsoit-label">حداقل مبلغ سفارش:</span><span class="depsoit-value">{{$toLocal(crypto.minAmountExchange,0)}}</span><span class="depsoit-measure">تومان</span></div>
            <div><span class="depsoit-label">حداکثر مبلغ سفارش:</span><span class="depsoit-value">{{$toLocal(crypto.maxAmountExchange,0)}}</span><span class="depsoit-measure">تومان</span> </div>
        </div>

        <div class="commission">
            <div>کارمزد شبکه&nbsp; =&nbsp;{{crypto.exchangeFee}}&nbsp;</div>
            <div>&nbsp;تومان1{{$coins[crypto.destCoinSymbol].code}}&nbsp;=&nbsp;{{$toLocal(unitPrice,0)}}</div>

        </div>
        <div class="footer">
            <!-- <div class="currency-net">
          <span>شبکه انتقال ارز</span>
          <ToggleComp choosed='TRC20' :items="[{label:'TRC20',value:'TRC20'},{label:'ERC20',value:'ERC20'}]"/>
        </div> -->
            <button @click="handleBtn()" :style="{background:isBuy?'#03C70B':'#CA2F4B'}">{{isBuy?'خرید':'فروش'}} فوری</button>
        </div>

    </div>
    <Buy @confirm="sendData()" v-if="modalType=='BUY'" @modalToggle="value=>modalType=value==''" :data="data" />
    <Sell @confirm="sendData()" v-if="modalType=='SELL'" @modalToggle="value=>modalType=value==''" :data="data" />
    <SuccessfulTrade  v-if="modalType=='success'" @modalToggle="value=>modalType=value"  :data="Object.assign({type:isBuy?'BUY':'SELL'},temptData)"/>

</div>
</template>

<script>
import Buy from '../../modals/Buy'
import Sell from '../../modals/Sell'
import HeaderCryptoFilter from './components/HeaderCryptoFilter'
import ExchangeBox from './components/ExchangeBox'
import SuccessfulTrade from '../../modals/SuccessfulTrade'

export default {
    name: 'PageHeader',
    components: {
        HeaderCryptoFilter,
        ExchangeBox,
        Buy,
        Sell,
        SuccessfulTrade
    },
    data() {
        return {
            crypto: {},
            isBuy: true,
            wallets: {},
            cryptos: {},
            coinsDetails: {},
            loading: true,
            amount: 0,
            modalType: '',
            data: {},
            temptData: {},
            tomanAmount: 0

        }
    },
    watch: {
        isBuy() {
            this.tomanAmount = this.$toLocal(this.$S2N(this.amount) * this.unitPrice, 0)

        },
        "crypto.destCoinSymbol":function(){
            this.tomanAmount = this.$toLocal(this.$S2N(this.amount) * this.unitPrice, 0)

        }

    },
    computed: {
        credit() {
            return (this.isBuy) ? this.tomanBalance.toLocaleString() : this.crypto.credit
        },

        tomanBalance() {

            if (this.wallets['TOMAN']) {
                const temp = this.wallets['TOMAN']
                return temp.credit
            }
            return 0
        },
        disabled() {
            return !this.$S2N(this.amount) ||
                (this.$S2N(this.amount) > this.$S2N(this.credit)) ||
                (this.$S2N(this.amount) > this.$S2N(this.crypto.maxAmountExchange)) ||
                (this.$S2N(this.amount) < this.$S2N(this.crypto.minAmountExchange))
        },
        errorHandler() {
            if ((this.$S2N(this.amount) > this.$S2N(this.credit)))
                return 'موجودی کافی نمیباشد'
            else if ((this.$S2N(this.amount) < this.$S2N(this.crypto.minAmountExchange)))
                return 'مقدار معامله باید حداقل' + this.$coins[this.relatedCoin].code + ' ' + this.crypto.minAmountExchange + 'باشد'
            else if ((this.$S2N(this.amount) > this.$S2N(this.crypto.maxAmountExchange)))
                return 'مقدار معامله باید حداکثر ' + this.$coins[this.relatedCoin].code + ' ' + this.crypto.maxAmountExchange + ' باشد '
            else if (!this.amount)
                return 'مقدار درخواستی را وارد نمایید'
            else if (!this.walletAddress && this.wallet == 'OTHER' && this.dealType == 'BUY')
                return 'آدرس کیف پول را وارد نمایید'
            else
                return ''

        },
        unitPrice() {
            return this.$S2N((this.isBuy) ? this.crypto.buyPrice : this.crypto.sellPrice)
        },
    },

    methods: {
        handleAmountChange(amount) {
            this.amount = this.$toLocal(this.$S2N(amount), this.$decimal[this.crypto.destCoinSymbol])
            this.tomanAmount = this.$toLocal(this.$S2N(this.amount) * this.unitPrice, 0)
        },
        handlePriceChange(totalPrice) {
            this.tomanAmount = this.$toLocal(totalPrice, 0)
            this.amount = this.$toLocal(this.$S2N(this.tomanAmount) / this.unitPrice, this.$decimal[this.crypto.destCoinSymbol])
        },
        handleBtn() {
            if (this.state.userInfo.authorized)
                this.checkModal()
            else
                this.$router.push('/login')

        },
        async getCoinsPrice() {
            await this.$axios.get('coins/exchange-price/usd', {
                params: {
                    includeWeeklyChange: false
                }
            }).then(response => this.cryptos = response)

        },
        async getWallets() {
            this.state.loading = true
            const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=EXCHANGE')
            if (res) {
                this.wallets = res
            }
        },
        async getCoinsDetails() {
            this.state.loading = true
            const res = await this.$axios.get('/coins')
            this.coinsDetails = res.content
            if (this.cryptos) {
                this.crypto = this.cryptos[0]
                if (this.crypto && this.wallets && this.coinsDetails)
                    this.setCrypto()
            }
        },

        setCrypto() {
            this.crypto.minAmountExchange = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).minAmountExchange
            this.crypto.maxAmountExchange = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).maxAmountExchange
            if (this.wallets[this.crypto.destCoinSymbol])
                this.crypto.credit = this.wallets[this.crypto.destCoinSymbol].credit
            this.crypto.nets = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).tokenTypeDetails
            this.net = this.crypto.nets[0].tokenType
            this.crypto.exchangeFee = this.coinsDetails.find(coin => coin.coin == this.crypto.destCoinSymbol).exchangeFee
            this.loading = false

        },
        checkModal() {
            this.data.amount = this.amount
            this.data.marketType = this.crypto.destCoinSymbol + '_' + 'TOMAN'
            this.data.destinationCoin = this.crypto.destCoinSymbol
            this.data.tokenType = this.net
            this.data.totalPrice = this.tomanAmount
            this.data.unitPrice = this.unitPrice
            this.data.orderType = (this.isBuy) ? 'EXCHANGE_BUY' : 'EXCHANGE_SELL'
            this.modalType = this.isBuy ? 'BUY' : 'SELL'
        },
        async sendData() {
            this.temptData = JSON.parse(JSON.stringify(this.data))
            this.temptData.amount = this.$S2N(this.temptData.amount)
            this.temptData.totalPrice = this.$S2N(this.temptData.totalPrice)
            this.temptData.unitPrice = this.$S2N(this.temptData.unitPrice)
            this.modalType = ''
            if (this.isBuy) {

                this.state.loading = true
                await this.$axios.post(
                    '/exchange/buy', this.temptData
                )
                this.modalType='success'

            } else {
                delete this.temptData.destinationWallet
                delete this.temptData.tokenType

                this.state.loading = true
                await this.$axios.post(
                    '/exchange/sell', this.temptData
                )
                this.modalType='success'
                
                this.state.loading = false
            }

        },
    },
    async mounted() {
        await this.getCoinsPrice()
        if (this.state.token)
            this.getWallets()
        await this.getCoinsDetails()
    }
}
</script>

<style scoped>
.trade-card {
    display: flex;
    padding: 24px;
    background: #FAFCFF;
    border-radius: 6px;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 712px;
}


.depsoit {
    margin-top: 8px;
    display: flex;
    width: 100%;
    font-size: 12px;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 4px;
}
.currency-net {
    display: flex;
    white-space: nowrap;
    column-gap: 17px;
    color: #4F4F4F;
    font-size: 12px;
    align-items: center;
}

.footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 18px;
    row-gap: 10px;

}

::v-deep .switch>div {
    height: 27px;
    font-size: 12px;
    width: 94px;

}

.exchange>div {
    display: flex;
    justify-content: center;
}

.exchange {
    display: flex;
    width: 100%;
    column-gap: 35px;
    /* row-gap: 10px; */
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 13px;
    color: #333333;
}

.bg {
    width: 100vw;

}

button {
    border-radius: 6px;
    height: 36px;
    padding: 0px 57px;
    color: white;
}



.depsoit-value {
    color: #143B74;
    font-size: clamp(13px, 1.3vw, 14px);
}

.depsoit-label {
    color: #4F4F4F;
}

.depsoit-measure {
    color: #828282;

}

.header {
    color: white;
}

.input-label {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    row-gap: 6px;
    color: #333333;
}

.label-input {
    width: 100%;
    flex-grow: 1;
    display: flex;
    text-align: right;
    flex: 1;
}

.label-input>input {
    background: #EDF1ED;
    height: 40px;
    width: 120px;
    flex-grow: 1;
    border-radius: 0px 6px 6px 0px;

}

.label-input>label {
    border-radius: 6px 0px 0px 6px;
    font-size: clamp(12px, 1.3vw, 13px);
    background: #EDF1ED;
    color: #828282;
    display: flex;
    padding: 0px 10px;
    align-items: center;
    white-space: nowrap;
}

.commission {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: clamp(12px, 1.3vw, 14px);
    column-gap: 10px;
    row-gap: 10px;
    color: #4F4F4F;
}

@media(max-width:590px) {
    button {
        width: 100%;
    }

    .currency-net {
        width: 100%;

    }
}

@media(max-width:650px) {
    .exchange>div {
        width: 100%;
    }
}
</style>
