<template>
<div class="modal-page" @click.self="$emit('close')">
  <!-- @click.self="" -->
     <div class="modal-card" >
        <div class="modal-header">
            <slot name="header"></slot>
            <img class="cross-icon" src="../../assets/public/cross.svg" @click="$emit('close')"/>
        </div>

        <div class="modal-body">
            <slot name="body"></slot>
        </div>
       
    </div>
</div>
</template>

<script>
export default {
  name: 'Modal',
 
  data(){
    return {
      choosed:"VISTA"
    }

  },
  // mounted(){
  //   document.addEventListener('click',(e)=>{
  //         if(e.target!=document.getElementsByClassName('modal'))
            
            
  //   })
  // }
  
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* 
.text{
  color: black;
} */
.modal-body{
  width: 100%;
  height: 100%;
  text-align: right;
}

.modal-page{
    position: fixed;
    display: flex;
    align-items: center;
    right: 0px;
    top: 0px;
    bottom:0;
    left:0;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 0px 15px;
    z-index: 100;
}
.modal-card{
    padding: 16px 24px;
    background: #FAFCFF;
    color: var(--gray2);
    border-radius: 8px;
    max-width: 479px;
    width: 100%;
  
}

.modal-body > div{
  width: 100%;
}


.cross-icon{
    cursor: pointer;
}

.modal-header{
    display: flex;
    width: 100%;
    font-size: clamp(14px,1.5vw,16px);
    padding-bottom: 10px;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
}


</style>