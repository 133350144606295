<template>
  <div class="navbar">
    <div class="link-container" >
    <diV class="orders-link" @click="ordersDrop=!ordersDrop" >
          سفارشات
          <img :class="{dropicon:true,dropopen:ordersDrop
          }" src='../../assets/public/flash-down.svg'/>
    </diV>
         <OrdersDropDown id="orders-drop" v-if="ordersDrop"/>
    </div>

      <div class="link-container" >
        <diV  @click="depsoitDrop=!depsoitDrop" class="depsoit-link">
           {{$toLocal(toman,0)}} تومان 
          <img :class="{dropicon:true,dropopen:depsoitDrop}" src='../../assets/public/flash-down.svg'/>
          <img  class="wallet-icon" src='../../assets/icons/wallet.svg'/>
        </diV> 
          <DepsoitDropDown id="depsoit-drop" v-if="depsoitDrop" :toman="toman" @modalToggle="value=>modalType=value"/>
      </div>
         <a href="/Panel/Notifications" class="bell-icon" @click.prevent="$router.push('/Panel/Notifications')">
         <img   src='../../assets/icons/bell.svg'/>
         <span class="message-count">{{unreadCount}}</span>
         </a>
    <div class="profile-link" >
         <span ><img @click="profileDrop=!profileDrop" class="profile-icon" src='../../assets/icons/user.svg'/></span> 
        <ProfileDropDown id="profile-drop" v-if="profileDrop"/>
    </div>

<TomanWithdraw @modalToggle="type=>modalType=type" v-if="modalType=='TomanWithdraw'" :cash="toman"/>
   <TomanCharge @modalToggle="type=>modalType=type" v-if="modalType=='TomanCharge'"/>
  </diV>
    
</template>

<script>
import DepsoitDropDown from './components/DepsoitDropDown'
import OrdersDropDown from './components/OrdersDropDown'
import ProfileDropDown from './components/ProfileDropDown'
import TomanWithdraw from '../modals/TomanWithdraw'
import TomanCharge from '../modals/TomanCharge'
export default {
  name: 'PanelNavbar',
  components:{
       DepsoitDropDown,
       OrdersDropDown,
       ProfileDropDown,
      TomanWithdraw,TomanCharge

  },
  data(){
    return{
        wallets:{},
        ordersDrop:false,
        depsoitDrop:false,
        profileDrop:false,
        unreadCount:0,
        modalType:''

    }
  },
  computed:{
    toman(){
      if(this.wallets['TOMAN'])
        return this.wallets['TOMAN'].credit
      return 0  
    }
  },
   created(){
     document.addEventListener('click',(e)=>{
      if(!e.target.closest(' .profile-icon '))
        this.profileDrop=false     
      if(!e.target.closest(' .orders-link '))
        this.ordersDrop=false    
      if(!e.target.closest(' .depsoit-link '))
        this.depsoitDrop=false
           
      
     })
  },
  methods:{
     async getUnreadMessages() {
                const res = await this.$axios.get('/users/notifications',
                    {
                        params: {
                          read:false
                        }
                    })
               if (res) {
                 this.unreadCount=this.res.content.length
               
            }
               
            },
         async getWallets() {

            this.state.loading = true
              
             const [res, ] = await this.$http.get('/wallets/customer-addresses?walletType=P2P')
            if (res) {
               
                this.wallets = res
            }
            this.loading = false



        },
  },
  mounted(){
    if(this.state.userInfo.authorized)
       this.getWallets()
  }
}
</script>
<style scoped>
.navbar{
   width: 100%;
   overflow: hidden;
   flex-grow: 1;
   background: linear-gradient( #123B77 -1.99%, #223459 31.28%, #00171F 105.75%);
   border-radius: 12px;
   display: flex;
    font-weight: bold;
   font-size: clamp(15px,1.6vw,18px);
   height: 50px;
   justify-content: flex-end;
   align-items: center;
  
}
.bell-icon{
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
  position: relative;
}
.message-count{
  position: absolute;
    border-radius: 50%;
    background: red;
    width: 10px;
    right: -3px;
    bottom: -1px;
    height: 13px;
    font-size: clamp(11px,1.2vw,13px);
     color: white;
     display: flex;
     justify-content: center;
     align-items: center;

}

.orders-link{
   display: flex;
   align-items: center;
   color: white;
   position: relative;
   column-gap: 12px;
   height: 100%;
   padding: 0px 40px ;
   cursor: pointer;
}
.depsoit-link{
   display: flex;
   align-items: center;
   column-gap: 11px;
   cursor: pointer;
   padding: 0px 12px;
   color:#E0E0E0; 
   height: 100%;  


   background: linear-gradient(235.27deg, rgba(0, 62, 134, 0.184) 0%, rgba(0, 126, 197, 0.184) 49.27%, rgba(166, 250, 255, 0.06256) 100%, rgba(0, 57, 116, 0.184) 100%);
box-shadow: inset 9.43333px -9.43333px 9.43333px rgba(90, 183, 189, 0.184), inset -9.43333px 9.43333px 9.43333px rgba(255, 255, 255, 0.184);
backdrop-filter: blur(36px);
}
.profile-icon{
    padding: 0px 12px 0px 12px;
    cursor: pointer;
}

.dropicon{
   width: 12px;
   height: auto;
}

.dropopen{
    transform: rotate(180deg);
}

.link-container{
    height: 100%;
    
}
.profile-link{
    height: 100%;
    z-index: 100;
}
.profile-link :last-child {
    left: 0px;
}
.profile-link > span{
    height: 100%; 
display: flex;
align-items: center;    
}

.drop-container{
     z-index: 10;
}

@media(max-width:600px){
    .link-container:first-child{
        display: none;
    }
   
}
@media(max-width:350px){
     .wallet-icon{
      display: none;
    }
   
}
</style>