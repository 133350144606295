<template>
<div class="auth-page">

  <AuthCard>
     <template #card-header>
         تایید آدرس ایمیل
     </template>
     <template #card-body>
         <p class="text">
              کد 6 رقمی ارسال شده به آدرس ایمیل ayli*****.comرا وارد نمایید 

         </p>
         <VerifyCodeInput/>

     </template> 
     <template #card-footer>
          <button class="authentication-confirm-btn" @click="$router.push('/Panel/Dashboard')">ثبت</button>


     </template>

  </AuthCard>
    </div>
</template>

<script>
import AuthCard from './components/AuthCard'
export default {
  name: 'OtpEmail',
  components:{
      AuthCard
  },
  data(){
    return{
      
    }
  }
}
</script>
<style scoped>
.text{
    font-size: 12px;
    color:#4F4F4F

}
</style>
